import { useSigma } from 'features/_shared/_providers/sigmaProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useClients } from './useClients';

type UseAddFolderButtonProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
};

export function useAddFolderButton({ open, setOpen }: UseAddFolderButtonProps) {
	const { customerApiClient } = useClients();
	const { folders, addFolder } = useSigma();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [name, setName] = useState<string>();

	const isSubmitDisabled = useMemo(
		() => (name?.trim()?.length ?? 0) <= 0 || isLoading,
		[isLoading, name],
	);

	const resetForm = useCallback(() => {
		setIsLoading(false);
		setName('');
		setError('');
	}, []);

	const onSubmit = useCallback(async () => {
		try {
			setIsLoading(true);

			if (name && name.length > 0) {
				if (folders.some((x) => x.name === name)) {
					setError('Folder with this name already exists.');
				} else {
					const response = await customerApiClient.sigmaEmbed.createFolder({
						name: name,
					});

					if (response.data) {
						addFolder(response.data);
						setOpen(false);
						resetForm();
					} else {
						setError('Unable to create folder.');
					}
				}
			}
		} catch (error: any) {
			setError(error?.message ?? error ?? 'Unknown error');
		} finally {
			setIsLoading(false);
		}
	}, [
		addFolder,
		customerApiClient.sigmaEmbed,
		folders,
		name,
		resetForm,
		setOpen,
	]);

	useEffect(() => {
		return () => resetForm();
	}, [resetForm]);

	return {
		isLoading,
		error,
		isSubmitDisabled,
		name,
		setName,
		onSubmit,
	};
}
