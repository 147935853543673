import { AccountIntegration } from 'modules/clients/customer-api/src/cash4/accountIntegrations';
import { Moment } from 'moment';
import { useCallback, useState } from 'react';
import { useApplicationConfiguration } from 'shared/hooks/useApplicationConfigurations';
import { useClients } from 'shared/hooks/useClients';
import { convertTime } from 'shared/utilities/dateUtilities';

export type UseSetAccountIntegrationScheduleProps = {
	isOpen: boolean;
	isLoading: boolean;
	error: string;

	enabled: boolean;
	setEnabled: (value: boolean) => void;
	triggerTime: Moment | null;
	setTriggerTime: (value: Moment | null) => void;
	triggerTimezone: string | null;
	setTriggerTimezone: (value: string | null) => void;
	timezones: string[];

	isDirty: boolean;
	isSubmitDisabled: boolean;
	setAccountIntegrationSchedule: () => Promise<void>;

	openModal: (
		connectionId: string,
		accountIntegration: AccountIntegration,
	) => void;
	closeModal: () => void;
};

export const useSetAccountIntegrationSchedule = (
	fetchConnections: () => Promise<void>,
): UseSetAccountIntegrationScheduleProps => {
	const { supportedTimezones } = useApplicationConfiguration();
	const { customerApiClient } = useClients();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const [connectionId, setConnectionId] = useState<string | null>(null);
	const [accountIntegration, setAccountIntegration] =
		useState<AccountIntegration | null>(null);

	const [enabled, setEnabled] = useState(true);
	const [schedule, setSchedule] = useState<{
		triggerTime: Moment | null;
		triggerTimezone: string;
	} | null>(null);

	const openModal = useCallback(
		(connectionId: string, accountIntegration: AccountIntegration) => {
			accountIntegration.schedule.isEnabled = true; // todo: remove
			setConnectionId(connectionId);
			setAccountIntegration(accountIntegration);
			setEnabled(accountIntegration.schedule.isEnabled);
			setSchedule({
				triggerTime: convertTime(accountIntegration.schedule.triggerTime)!,
				triggerTimezone: accountIntegration.schedule.triggerTimezone,
			});
			setIsOpen(true);
		},
		[],
	);

	const closeModal = useCallback(() => {
		setIsOpen(false);
		setError('');
		setConnectionId(null);
		setAccountIntegration(null);
		setSchedule(null);
	}, []);

	const setTriggerTime = useCallback(
		(value: Moment | null) => {
			setSchedule({
				...schedule!,
				triggerTime: value,
			});
		},
		[schedule],
	);

	const setTriggerTimezone = useCallback(
		(value: string | null) => {
			if (value === null) return;

			setSchedule({
				...schedule!,
				triggerTimezone: value,
			});
		},
		[schedule],
	);

	const isScheduleValid = useCallback(() => {
		return (
			schedule !== null &&
			schedule.triggerTime !== null &&
			schedule.triggerTime.isValid()
		);
	}, [schedule]);

	const isDirty = useCallback(() => {
		if (!isScheduleValid() || !connectionId || !accountIntegration)
			return false;
		const initialTriggerTime = convertTime(
			accountIntegration.schedule.triggerTime,
		)!;

		return (
			accountIntegration.schedule.isEnabled !== enabled ||
			!(
				schedule!.triggerTime?.minutes() === initialTriggerTime.minutes() &&
				schedule!.triggerTime?.hours() === initialTriggerTime.hours()
			) ||
			schedule!.triggerTimezone !== accountIntegration.schedule.triggerTimezone
		);
	}, [accountIntegration, connectionId, enabled, isScheduleValid, schedule]);

	const isSubmitDisabled = useCallback(() => {
		return (
			isLoading ||
			connectionId === null ||
			accountIntegration === null ||
			!isScheduleValid() ||
			!isDirty()
		);
	}, [accountIntegration, connectionId, isDirty, isLoading, isScheduleValid]);

	const setAccountIntegrationSchedule = useCallback(async () => {
		if (
			connectionId === null ||
			accountIntegration === null ||
			!isScheduleValid() ||
			!isDirty()
		)
			return;

		try {
			setIsLoading(true);
			setError('');

			var response =
				await customerApiClient.cash4.accountIntegrations.setSchedule({
					connectionId: connectionId,
					accountIntegrationId: accountIntegration.id,
					isEnabled: enabled,
					triggerTime: schedule!.triggerTime!.format('HH:mm:ss'),
					triggerTimezone: schedule!.triggerTimezone,
				});

			if (response.data.success) {
				closeModal();
				await fetchConnections();
			} else {
				setError('Unable to update account integration schedule.');
			}
		} catch (error: any) {
			setError('Unable to update account integration schedule.');
		} finally {
			setIsLoading(false);
		}
	}, [
		accountIntegration,
		closeModal,
		connectionId,
		customerApiClient.cash4.accountIntegrations,
		enabled,
		fetchConnections,
		isDirty,
		isScheduleValid,
		schedule,
	]);

	return {
		isOpen,
		isLoading,
		error,

		enabled: enabled,
		setEnabled: setEnabled,
		triggerTime: schedule?.triggerTime ?? null,
		setTriggerTime,
		triggerTimezone: schedule?.triggerTimezone ?? null,
		setTriggerTimezone,
		timezones: supportedTimezones,

		isDirty: isDirty(),
		isSubmitDisabled: isSubmitDisabled(),
		setAccountIntegrationSchedule,

		openModal,
		closeModal,
	};
};
