import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Menu,
	MenuItem,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { Cash4Account } from 'models/apiModels';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { stonlyIds } from '..';

interface ActionButtonProps {
	row: Cash4Account;
}

const ActionButton: FC<ActionButtonProps> = observer(({ row }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedDialog, setSelectedDialog] = useState<DialogTypeProps>(
		DialogType.UnlinkConfirmation,
	);
	const [openDialog, setOpenDialog] = useState(false);
	const queryClient = useQueryClient();
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const handleClickSelf = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseSelf = () => {
		setAnchorEl(null);
	};

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleCloseAll = () => {
		handleCloseSelf();
		handleCloseDialog();
	};

	const handleRefetch = () => {
		queryClient.refetchQueries(['accounts']);
	};

	const handleClickUnlink = () => {
		setSelectedDialog(DialogType.UnlinkConfirmation);
		const updatedDialog = {
			...selectedDialog,
			onPrimaryButtonClick: handleUnlink,
			onSecondaryButtonClick: handleCloseAll,
		};
		setSelectedDialog(updatedDialog);
		handleOpenDialog();
		handleRefetch();
	};

	const handleUnlink = () => {
		if (row.linkedE4Account?.entity4AccountId) {
			mutation.mutate({
				Cash4AccountId: row.id,
				Entity4AccountId: row.linkedE4Account?.entity4AccountId,
			});
		}
		handleCloseDialog();
		handleCloseSelf();
	};

	const unlinkAccounts = (data: {
		Cash4AccountId: string;
		Entity4AccountId: string;
	}) => {
		const { Cash4AccountId, Entity4AccountId } = data;
		return customerApiClient.cash4.accounts.unlinkAccounts(
			Cash4AccountId,
			Entity4AccountId,
		);
	};

	const mutation = useMutation(unlinkAccounts, {
		onSuccess: (data, variables) => {
			if (data && data.success) {
				handleRefetch();
				enqueueSnackbar('Successfully Unlinked Account', {
					variant: 'success',
				});
			}
			if (data && !data.success) {
				enqueueSnackbar('Failed to unlink account', {
					variant: 'error',
				});
			}
		},
	});

	return (
		<div>
			<IconButton
				aria-label="more"
				onClick={handleClickSelf}
				data-stonlyid={stonlyIds.moreOptions}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleCloseSelf}
			>
				{row.isLinked && (
					<MenuItem onClick={handleClickUnlink}>Remove Link</MenuItem>
				)}
			</Menu>
			<GenericDialog
				open={openDialog}
				onClose={handleCloseDialog}
				title={selectedDialog.title || ''}
				text={selectedDialog.text}
				primaryButtonText={selectedDialog.primaryButtonText}
				secondaryButtonText={selectedDialog.secondaryButtonText}
				onPrimaryButtonClick={selectedDialog.onPrimaryButtonClick}
				onSecondaryButtonClick={selectedDialog.onSecondaryButtonClick}
			/>
		</div>
	);
});

type DialogTypeProps = {
	title?: string;
	text?: string;
	primaryButtonText?: string;
	secondaryButtonText?: string;
	onPrimaryButtonClick?: () => void;
	onSecondaryButtonClick?: () => void;
};

export const DialogType = {
	UnlinkConfirmation: {
		title: 'Remove Entity4 Link?',
		text: 'Are you sure you want to remove this link? The Cash4 data will no longer be associated with an Entity4 Account.',
		primaryButtonText: 'Yes, Remove',
		secondaryButtonText: 'No',
	},
};

export default ActionButton;

interface GenericDialogProps {
	open: boolean;
	onClose: () => void;
	title?: string;
	text?: string;
	primaryButtonText?: string;
	secondaryButtonText?: string;
	onPrimaryButtonClick?: () => void;
	onSecondaryButtonClick?: () => void;
}

const GenericDialog: FC<GenericDialogProps> = observer(
	({
		open,
		onClose,
		title,
		text,
		primaryButtonText,
		secondaryButtonText,
		onPrimaryButtonClick,
		onSecondaryButtonClick,
	}) => {
		return (
			<Dialog
				open={open}
				onClose={onClose}
				data-stonlyid={stonlyIds.ignoreAccountModal}
			>
				{title && <DialogTitle>{title}</DialogTitle>}
				{text && <DialogContent>{text}</DialogContent>}
				<DialogActions>
					{secondaryButtonText && (
						<Button
							color="secondary"
							onClick={onSecondaryButtonClick}
							data-stonlyid={stonlyIds.cancelButtonUnlinkDialog}
						>
							{secondaryButtonText}
						</Button>
					)}
					{primaryButtonText && (
						<Button
							color="primary"
							onClick={onPrimaryButtonClick}
							data-stonlyid={stonlyIds.ignoreAccountModal}
						>
							{primaryButtonText}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	},
);
