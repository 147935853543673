import {
	Box,
	Button,
	Card,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { observer } from 'mobx-react-lite';
import { SigmaElement } from 'modules/clients/customer-api/src/sigmaEmbed';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSigmaDashboard } from '../_providers/dashboardProvider';
import { SigmaEmbed } from './sigmaEmbed';

export type WidgetPickerProps = {
	open: boolean;
	onClose: () => void;
	onSubmit: (visuals: SigmaElement[]) => void;
	loading?: boolean;
};

export const WidgetPicker: FC<WidgetPickerProps> = observer(
	({ open, onClose, onSubmit, loading }) => {
		const { visuals, dashboard, hardcodedComponents } = useSigmaDashboard();
		const [selectedVisuals, setSelectedVisuals] = useState<SigmaElement[]>([]);

		const filteredVisuals = useMemo(() => {
			const dashboardVisuals = (dashboard?.widgets ?? []).map(
				(x) => `${x.workbookId}-${x.elementId}`,
			);
			return (visuals ?? []).filter(
				(x) => !dashboardVisuals.includes(`${x.workbookId}-${x.elementId}`),
			);
		}, [dashboard?.widgets, visuals]);

		const reports = useMemo(() => {
			return Array.from(new Set(filteredVisuals.map((v) => v.workbookName)));
		}, [filteredVisuals]);

		const types = useMemo(() => {
			return Array.from(
				new Set(
					filteredVisuals
						.filter((v) => v.vizualizationType !== '')
						.map((v) => v.vizualizationType),
				),
			);
		}, [filteredVisuals]);

		const onSelectHandler = useCallback(
			(sigmaElement: SigmaElement) => {
				const visual = selectedVisuals.find(
					(v) => v.elementId === sigmaElement.elementId,
				);
				if (!visual) {
					setSelectedVisuals([...selectedVisuals, sigmaElement]);
				} else {
					setSelectedVisuals(
						selectedVisuals.filter(
							(v) => v.elementId !== sigmaElement.elementId,
						),
					);
				}
			},
			[selectedVisuals],
		);

		useEffect(() => {
			if (!open) {
				setSelectedVisuals([]);
			}
		}, [open]);

		return (
			<Dialog
				open={open}
				maxWidth="xl"
				sx={{
					'& .MuiDialog-paper': {
						width: 'calc(80% + 36px)',
						height: '80%',
					},
				}}
			>
				<Grid
					container
					sx={{ height: '100%', flexDirection: 'column', flexWrap: 'nowrap' }}
				>
					<Grid item xs="auto">
						<DialogTitle>
							<Typography variant="h3" component="div">
								Add Widgets
							</Typography>
						</DialogTitle>
					</Grid>

					<Grid item xs={true} sx={{ overflowY: 'auto' }}>
						<DialogContent sx={{ height: '100%' }}>
							<Grid container sx={{ height: '100%' }}>
								{loading ? (
									<Grid
										item
										xs="auto"
										sx={{
											width: '100%',
										}}
									>
										<CircularProgress />
									</Grid>
								) : (
									<Grid item xs={4} sx={{ paddingRight: 2 }}>
										<Box sx={{ minHeight: 320, height: '100%' }}>
											<SimpleTreeView>
												{reports.map((report, reportIndex) => {
													const reportTypes = types.filter((type) =>
														filteredVisuals.some(
															(v) =>
																v.workbookName === report &&
																v.vizualizationType === type,
														),
													);

													if (reportTypes.length === 0) return null;

													return (
														<TreeItem
															key={`report-${reportIndex}`}
															itemId={`report-${reportIndex}`}
															label={report}
														>
															{reportTypes.map((type, typeIndex) => {
																const visuals = filteredVisuals.filter(
																	(visual) =>
																		visual.workbookName === report &&
																		visual.vizualizationType === type,
																);

																if (visuals.length === 0) return null;

																return (
																	<TreeItem
																		key={`type-${typeIndex}-${report}`}
																		itemId={`type-${typeIndex}-${report}`}
																		label={type
																			.replace(/([A-Z]+)/g, ' $1')
																			.replace(/([A-Z][a-z])/g, ' $1')
																			.replace(/^\w/, (c) => c.toUpperCase())}
																	>
																		{visuals.map((visual) => (
																			<TreeItem
																				key={`visual-${visual.elementId} ${visual.name}`}
																				itemId={visual.elementId}
																				label={
																					<Box
																						sx={{
																							display: 'flex',
																							alignItems: 'center',
																						}}
																					>
																						<Checkbox
																							onClick={(event) => {
																								event.stopPropagation();
																								onSelectHandler(visual);
																							}}
																							checked={selectedVisuals.some(
																								(x) =>
																									x.elementId ===
																									visual.elementId,
																							)}
																							sx={{
																								padding: '0 8px 0 0',
																							}}
																						/>
																						<Typography>
																							{visual.name}
																						</Typography>
																					</Box>
																				}
																			/>
																		))}
																	</TreeItem>
																);
															})}
														</TreeItem>
													);
												})}
											</SimpleTreeView>
										</Box>
									</Grid>
								)}
								{selectedVisuals.length !== 0 && (
									<Grid item xs={8}>
										<Typography variant="h4" component="div">
											Preview of Selected Visuals ({selectedVisuals.length})
										</Typography>
										<Typography variant="body1" component="div">
											Click to remove a visual from selection
										</Typography>
										<Grid container spacing={2}>
											{selectedVisuals.map((visual, index) => (
												<Grid
													key={`sigma-visual-${index}-element-${visual.elementId}`}
													item
													xs={12}
													margin={2}
													sx={{
														position: 'relative',
														height: '10rem',
													}}
												>
													<Box
														onClick={() => onSelectHandler(visual)}
														sx={{
															position: 'absolute',
															cursor: 'pointer',
															zIndex: 3,
															height: '100%',
															width: '100%',
														}}
													/>
													{visual.workbookId !== 'hardcoded' ? (
														<SigmaEmbed sigmaVisual={visual} />
													) : (
														<Card key={visual.elementId} elevation={2} square>
															{
																hardcodedComponents[
																	visual.elementId as keyof typeof hardcodedComponents
																]
															}
														</Card>
													)}
												</Grid>
											))}
										</Grid>
									</Grid>
								)}
							</Grid>
						</DialogContent>
					</Grid>

					<Grid item xs="auto">
						<DialogActions>
							<Grid
								container
								item
								xs="auto"
								sx={{ gap: 2, flexWrap: 'nowrap' }}
							>
								<Grid item xs="auto">
									<Button color="secondary" onClick={() => onClose()}>
										Cancel
									</Button>
								</Grid>
								<Grid item xs="auto">
									<Button
										variant="contained"
										onClick={() => onSubmit(selectedVisuals)}
									>
										Add
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Grid>
				</Grid>
			</Dialog>
		);
	},
);
