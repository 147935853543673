/* eslint-disable mobx/missing-observer */
import { Divider, MenuItem, Tooltip } from '@mui/material';
import { FC, useMemo } from 'react';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { useUser } from 'shared/hooks/useUser';
import { stonlyData } from 'stonly/functions';

export type ProjectedItemAction = {
	action: ProjectedItemActionsEnum;
	isDisabled?: boolean;
	callback: () => void;
};

export enum ProjectedItemActionsEnum {
	view,
	edit,
	delete,
}

export type ProjectedItemActionsProps = {
	stonlyId: string;
	projectedItemActions: ProjectedItemAction[];
};

export const ProjectedItemActions: FC<ProjectedItemActionsProps> = ({
	stonlyId,
	projectedItemActions,
}) => {
	const { cash4 } = useUser();

	const viewAction = useMemo(
		() =>
			projectedItemActions.filter((action) => {
				return action.action === ProjectedItemActionsEnum.view;
			})[0] ?? undefined,
		[projectedItemActions],
	);
	const editAction = useMemo(
		() =>
			projectedItemActions.filter((action) => {
				return action.action === ProjectedItemActionsEnum.edit;
			})[0] ?? undefined,
		[projectedItemActions],
	);
	const deleteAction = useMemo(
		() =>
			projectedItemActions.filter((action) => {
				return action.action === ProjectedItemActionsEnum.delete;
			})[0] ?? undefined,
		[projectedItemActions],
	);

	const shouldDisplayMenu =
		viewAction ||
		(editAction && cash4.isAuthor) ||
		(deleteAction && cash4.isAuthor);

	if (shouldDisplayMenu) {
		return (
			<OverflowMenu
				iconButtonProps={{
					...stonlyData({
						id: stonlyId,
					}),
				}}
				id={`projectedTransactionId-more-menu`}
			>
				{viewAction && (
					<MenuItem
						disabled={viewAction.isDisabled === true}
						onClick={() => {
							viewAction.callback?.();
						}}
					>
						View
					</MenuItem>
				)}
				{cash4.isAuthor && editAction && (
					<MenuItem
						disabled={editAction.isDisabled === true}
						onClick={() => {
							editAction.callback?.();
						}}
					>
						Edit
					</MenuItem>
				)}

				{cash4.isAuthor && deleteAction && <Divider />}
				{cash4.isAuthor && deleteAction && (
					<Tooltip
						title={
							deleteAction.isDisabled === true
								? 'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.'
								: ''
						}
					>
						<span>
							<DeleteMenuItem
								disabled={deleteAction.isDisabled === true}
								onClick={() => {
									deleteAction.callback?.();
								}}
							/>
						</span>
					</Tooltip>
				)}
			</OverflowMenu>
		);
	} else {
		return <></>;
	}
};
