/* eslint-disable mobx/missing-observer */
import { DialogActions, Grid, Typography, useTheme } from '@mui/material';
import { T4Modal } from 'features/entity4/shared/components/atoms/t4Modal';
import { FC, useMemo } from 'react';
import { stonlyData } from 'stonly/functions';
import { DrawerCancelButton, DrawerSubmitButton } from './drawerButtons';

const stonlyIds = {
	cancelationModal: 'cancelation-modal',
	negativeAction: 'cancelation-modal-negative-action',
	positiveAction: 'cancelation-modal-positive-action',
};

export interface DrawerCancellationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: () => void;
	resourceType?: string;
	variant?: 'default' | 'create' | 'edit';
}

export const DrawerCancelationModal: FC<DrawerCancellationModalProps> = ({
	isOpen,
	onClose,
	onSubmit,
	resourceType,
	variant = 'default',
}) => {
	const theme = useTheme();

	const cancelationTitle = useMemo(() => {
		switch (variant) {
			case 'create':
				return resourceType
					? `Cancel ${resourceType} creation?`
					: 'Cancel creation?';
			case 'edit':
				return 'Discard edits?';
			case 'default':
			default:
				return 'Close?';
		}
	}, [variant, resourceType]);

	const cancelationDescription = useMemo(() => {
		switch (variant) {
			case 'create':
				return `Canceling ${
					resourceType ? resourceType + ' ' : ' '
				}creation will discard any data you have entered, and the ${
					resourceType ?? 'resource'
				} will not be created.`;
			case 'edit':
				return `Discarding will remove any changes to the ${
					resourceType ?? 'resource'
				}.`;
			case 'default':
			default:
				return 'Are you sure you want to close?';
		}
	}, [resourceType, variant]);

	return (
		<T4Modal
			id={stonlyIds.cancelationModal}
			{...stonlyData({ id: stonlyIds.cancelationModal })}
			open={isOpen}
			title={cancelationTitle}
			actions={
				<DialogActions sx={{ padding: '1rem' }}>
					<Grid container columnSpacing={2} sx={{ justifyContent: 'flex-end' }}>
						<Grid item xs="auto">
							<DrawerCancelButton
								stonlyId={stonlyIds.negativeAction}
								label="Return"
								onCancel={onClose}
							/>
						</Grid>

						<Grid item xs="auto">
							<DrawerSubmitButton
								stonlyId={stonlyIds.positiveAction}
								label={variant === 'default' ? 'Close' : 'Discard'}
								onSubmit={() => {
									onClose();
									onSubmit();
								}}
								sx={{
									backgroundColor:
										variant === 'create'
											? theme.palette.error.main
											: theme.palette.warning.main,
									':hover': {
										backgroundColor:
											variant === 'create'
												? theme.palette.error.dark
												: theme.palette.warning.dark,
									},
								}}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			}
		>
			<Typography variant="body1">{cancelationDescription}</Typography>
		</T4Modal>
	);
};
