import { DialogActions, Typography, Button } from '@mui/material'; // Added Button import
import { T4Modal } from 'features/entity4/shared/components/atoms/t4Modal';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type ResetDashboardModalProps = {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	loading: boolean;
};

export const ResetDashboardModal: FC<ResetDashboardModalProps> = observer(
	({ open, onClose, onSubmit, loading }) => {
		return (
			<T4Modal
				open={open}
				title="Reset Dashboard"
				actions={
					<DialogActions>
						<Button onClick={onClose} color="primary" disabled={loading}>
							Cancel
						</Button>
						<Button onClick={onSubmit} color="primary" disabled={loading}>
							Reset
						</Button>
					</DialogActions>
				}
			>
				<Typography>
					Are you sure you want to reset the dashboard? All widgets will reset
					to the default.
				</Typography>
			</T4Modal>
		);
	},
);
