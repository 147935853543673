/* eslint-disable mobx/missing-observer */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Cash4Account } from 'models/apiModels';
import { stonlyIds } from '..';

const ignoredColumns = (
	handleButtonClick: (account: Cash4Account) => void,
): GridColDef[] => [
	{
		field: 'LinkAccount',
		headerName: '',
		flex: 1,
		renderCell: (params) => {
			return (
				<Button
					variant="text"
					size="small"
					onClick={() => handleButtonClick(params.row as Cash4Account)}
					data-stonlyid={stonlyIds.linkAccountInlineGridButton}
				>
					Link Account
				</Button>
			);
		},
		filterable: false,
	},
	{
		field: 'IgnoredDateTime',
		headerName: 'Ignored Date/Time',
		type: 't4DateTime',
		flex: 1,
	},
	{
		field: 'IgnoredBy',
		headerName: 'Ignored By',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.ignoredByEmail,
	},
	{
		field: 'AccountIdentifier',
		headerName: 'Account Identifier',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.accountIdentifier,
	},
	{
		field: 'BankName',
		headerName: 'Bank Name',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.bankName,
	},
	{
		field: 'postDate',
		headerName: 'Last Statement Date',
		type: 't4Date',
		flex: 1,
	},
];

export { ignoredColumns };

export const ExpandIcon = () => (
	<ChevronRightIcon data-stonlyid={stonlyIds.expandButton} />
);
export const CollapseIcon = () => (
	<ExpandMoreIcon data-stonlyid={stonlyIds.collapseButton} />
);

export const columnsAccountEventsHistory: GridColDef[] = [
	{
		headerName: 'Imported Date',
		field: 'importedDateTime',
		width: 200,
		type: 't4DateTime',
	},
	{
		headerName: 'Statement Date',
		field: 'statementDate',
		flex: 1,
		type: 't4Date',
	},
	{
		headerName: 'Bank Code',
		field: 'bankCode',
		flex: 1,
	},
	{
		headerName: 'Connector Name',
		field: 'connectorName',
		flex: 1,
	},
	{
		headerName: 'Display Name',
		field: 'displayName',
		flex: 1,
	},
	{
		headerName: 'Status',
		field: 'status',
		flex: 1,
	},
	{
		headerName: 'Error Message',
		field: 'errorMessage',
		flex: 1,
	},
	{
		headerName: 'Count of Balance Details',
		field: 'balanceDetailsCount',
		type: 'number',
		flex: 1,
	},
	{
		headerName: 'Count of Transaction Details',
		field: 'transactionDetailsCount',
		type: 'number',
		flex: 1,
	},
];
