import { DashboardCustomize } from '@mui/icons-material';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import GroupsIcon from '@mui/icons-material/Groups';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import TuneIcon from '@mui/icons-material/Tune';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import {
	PageHeader,
	pageHeaderStonlyIds,
} from '../../../shared/components/pageHeader';
import { AdministrationCard } from '../_shared/_components/administrationCard';

export const AdministrationPage: FC = observer(() => {
	const theme = useTheme();
	const { isAdmin, cash4, payments4 } = useUser();
	const history = useHistory();

	const adminCards = useMemo(
		() => [
			{
				title: 'Global Settings',
				components: [
					<AdministrationCard
						cardId="legal-entity-groups"
						cardIcon={
							<DashboardCustomize
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						}
						cardTitle="Entity Groups"
						cardDescription="Create and manage entity groups that can be filtered and reported on based on entity relationships."
						cardButtonText="VIEW ENTITY GROUPS"
						cardLink={() => {
							if (isAdmin) {
								history.push(paths.administration.legalEntityGroups.href);
							}
						}}
						disabled={!isAdmin}
					/>,
					<AdministrationCard
						cardId="security-groups"
						cardIcon={
							<GroupsIcon
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						}
						cardTitle="Security Groups"
						cardDescription="Limit access to entities within the system by creating groups and associating users."
						cardButtonText="VIEW SECURITY GROUPS"
						cardLink={() => {
							if (isAdmin) {
								history.push(paths.administration.groups.href);
							}
						}}
						disabled={!isAdmin}
					/>,
					<AdministrationCard
						cardId="manage configurations"
						cardIcon={
							<TuneIcon
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						}
						cardTitle="Configurations"
						cardDescription="Set organizational configuration settings."
						cardButtonText="Manage Configurations"
						cardLink={() => {
							if (isAdmin) {
								history.push(paths.administration.securityGroupApprovals.href);
							}
						}}
						disabled={!isAdmin}
					/>,
					<AdministrationCard
						cardId="user-entitlement-report"
						cardIcon={
							<VerifiedUserIcon
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						}
						cardTitle="User Entitlement Report"
						cardDescription="View user entitlements for roles and security groups access."
						cardButtonText="View Report"
						cardLink={() => {
							if (isAdmin) {
								history.push(paths.administration.userEntitlements.href);
							}
						}}
						disabled={!isAdmin}
					/>,
				],
			},
			{
				title: 'Entity4 Settings',
				components: [
					<AdministrationCard
						cardId="audit-log"
						cardIcon={
							<ViewListOutlinedIcon
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						}
						cardTitle="Audit Log"
						cardDescription="Field level changes, along with user and date information, presented in an easy to read format that can be filtered and exported."
						cardButtonText="VIEW AUDIT LOG"
						cardLink={() => {
							if (isAdmin) {
								history.push(paths.administration.auditLog.href);
							}
						}}
						disabled={!isAdmin}
					/>,
					<AdministrationCard
						cardId="approvals"
						cardIcon={
							<RuleFolderIcon
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						}
						cardTitle="Approvals"
						cardDescription="Update organizational configurations for approvals and workflows."
						cardButtonText="MANAGE APPROVALS"
						cardLink={() => {
							if (isAdmin) {
								history.push(paths.administration.entity4Configurations.href);
							}
						}}
						disabled={!isAdmin}
					/>,
				],
			},
			{
				title: 'Cash4 Settings',
				components: [
					<AdministrationCard
						cardId="cash4-configurations"
						cardIcon={
							<TuneIcon
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						}
						cardTitle="Configurations"
						cardDescription="Set Cash4 module configuration settings."
						cardButtonText="Manage Configurations"
						cardLink={() => {
							if (isAdmin && cash4.isAuthor) {
								history.push(paths.administration.cash4Configuration.href);
							}
						}}
						disabled={!isAdmin || !cash4.isAuthor}
					/>,
				],
			},

			{
				title: 'Payments4 Settings',
				components: [
					<AdministrationCard
						cardId="payments-approvals-management"
						cardIcon={
							<AltRouteIcon
								sx={{
									fontSize: '64px',
									color: theme.palette.secondaryHeader?.main,
								}}
							/>
						}
						cardTitle="Payment Approvals Management"
						cardDescription="Create approval rules with tiered limits and approval levels to ensure efficient and controlled payment authorization."
						cardButtonText="Manage Payment Approvals"
						cardLink={() => {
							if (payments4.isAdmin) {
								history.push(
									paths.administration.payments4.paymentApprovalsManagement
										.href,
								);
							}
						}}
						disabled={!isAdmin || !payments4.isAdmin}
					/>,
				],
			},
		],
		[theme, history, isAdmin, cash4, payments4],
	);

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.administrationView}
					title="Administration"
				/>
			}
		>
			<Container>
				<Grid container sx={{ gap: 4, paddingBottom: '2rem' }}>
					{adminCards.map((x) => {
						const key = x.title.toLowerCase().replace(' ', '-');
						return (
							<Grid key={key} container item xs={12} spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h2">{x.title}</Typography>
								</Grid>
								{x.components.map((component, index) => (
									<Grid
										key={`${key}-${index}`}
										item
										xs={12}
										sm={12}
										md={6}
										lg={4}
									>
										{component}
									</Grid>
								))}
							</Grid>
						);
					})}
				</Grid>
			</Container>
		</T4View>
	);
});
