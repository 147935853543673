/* eslint-disable mobx/missing-observer */
import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip, useTheme } from '@mui/material';
import { DataGridProProps, gridClasses } from '@mui/x-data-grid-pro';
import { getColumnDefs } from 'features/cash4/shared/gridColDef/GridColDefFactory';
import { Cash4Account } from 'models/apiModels';
import { FC, useCallback } from 'react';
import { T4DataGrid } from 'shared/components/dataGrid/dataGrid';
import { brandColors } from 'shared/theme/brandColors';
import { formatDateSimpleUpperCase } from 'shared/utilities/dateUtilities';
import { CollapseIcon, ExpandIcon } from '../utilities';
import E4Logo from '../utilities/E4_logo';
import './AccountsList.css';
import ActionButton from './ActionButton';
import DetailPanelContent from './DetailContentPanel';

export type AccountsListProps = {
	accounts: Cash4Account[];
	disableVirtualization?: boolean;
	stonlyIds: { [key: string]: string };
};

export const AccountsList: FC<AccountsListProps> = ({
	accounts,
	disableVirtualization = false,
	stonlyIds,
}) => {
	const theme = useTheme();

	const getDetailPanelContent = useCallback<
		NonNullable<DataGridProProps['getDetailPanelContent']>
	>(
		({ row }) => <DetailPanelContent row={row} stonlyIds={stonlyIds} />,
		[stonlyIds],
	);

	const getDetailPanelHeight = useCallback(() => 470, []);

	return (
		<T4DataGrid
			rows={accounts ?? []}
			getDetailPanelHeight={getDetailPanelHeight}
			getDetailPanelContent={getDetailPanelContent}
			slots={{
				detailPanelExpandIcon: () => <ExpandIcon />,
				detailPanelCollapseIcon: () => <CollapseIcon />,
			}}
			getRowClassName={(params) => {
				return params.row.isLinked ? 'linked-row' : 'unlinked-row';
			}}
			disableRowSelectionOnClick
			sx={{
				[`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
					outline: 'transparent',
				},
				[`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
					{
						outline: 'none',
					},
			}}
			getRowId={(row) => row.id}
			disableVirtualization={disableVirtualization}
			hideFooter={false}
			density="compact"
			stonlyId={stonlyIds.accountsGrid}
			experimentalFeatures={{ columnGrouping: true }}
			columnGroupingModel={[
				{
					groupId: 'Entity4 Data',
					children: [
						{ field: 'displayName' },
						{ field: 'naturalAccountNumber' },
						{ field: 'priorDayStatementsAccountNumber' },
					],
				},
				{
					groupId: 'Cash4 Data',
					children: [
						{ field: 'accountIdentifier' },
						{ field: 'bankName' },
						{ field: 'lastStatementDate' },
					],
				},
			]}
			columns={getColumnDefs<Cash4Account>([
				{
					field: 'actions',
					headerName: '',
					width: 30,
					resizable: false,
					sortable: false,
					disableColumnMenu: true,
					filterable: false,
					renderCell: (params) => {
						return params.row.isLinked ? (
							<ActionButton row={params.row} />
						) : undefined;
					},
				},
				{
					field: 'e4Logo',
					headerName: '',
					width: 40,
					renderCell: (params) => {
						const isLinked = params.row.isLinked;
						return (
							<Tooltip title={isLinked ? 'Linked' : 'Not linked'}>
								<span
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<E4Logo
										fillColor={
											isLinked ? brandColors.equityGreen['500'] : '#b2b3b3'
										}
										size={32}
									/>
								</span>
							</Tooltip>
						);
					},
					resizable: false,
					sortable: false,
					disableColumnMenu: true,
					filterable: false,
				},
				{
					field: 'active',
					headerName: '',
					width: 40,
					renderCell: (params) => {
						const status = params.row.latestSyncEvent?.status;
						let color;
						switch (status) {
							case 'Completed':
								color = brandColors.equityGreen['500'];
								break;
							case 'Errored':
								color = brandColors.errorRed.A100;
								break;
							case 'Processing':
							case 'Queued':
							case 'Staged':
							case 'Triggered':
								color = theme.palette.warning.main;
								break;
							default:
								color = '#b2b3b3';
								break;
						}
						return (
							<Tooltip title={status}>
								<CircleIcon
									sx={{
										color: color,
										fontSize: '16px',
									}}
								/>
							</Tooltip>
						);
					},
					resizable: false,
					sortable: false,
					disableColumnMenu: true,
					filterable: false,
				},

				{
					field: 'displayName',
					headerName: 'Account Display Name',
					type: 'string',
					flex: 1,
					valueGetter: (params) => params.row.linkedE4Account?.displayName,
				},
				{
					field: 'naturalAccountNumber',
					headerName: 'Natural Account Number',
					type: 'string',
					flex: 1,
					valueGetter: (params) =>
						params.row.linkedE4Account?.naturalAccountNumber,
				},
				{
					field: 'priorDayStatementsAccountNumber',
					headerName: 'Statement Account Number',
					type: 'string',
					flex: 1,
					valueGetter: (params) =>
						params.row.linkedE4Account?.priorDayStatementAccountNumber,
				},

				{
					field: 'accountIdentifier',
					headerName: 'Account Identifier',
					type: 'string',
					flex: 1,
					valueGetter: (params) => params.row.accountIdentifier,
				},
				{
					field: 'bankName',
					headerName: 'Bank Name',
					type: 'string',
					flex: 1,
					valueGetter: (params) => params.row.bankName,
				},
				{
					field: 'lastStatementDate',
					headerName: 'Last Statement Date',
					type: 'string',
					flex: 1,
					valueGetter: (params) => {
						const postDate = params.row.latestSyncEvent?.postDate;
						return postDate ? formatDateSimpleUpperCase(postDate) : '';
					},
				},
			])}
		/>
	);
};
