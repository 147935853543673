import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';

import {
	GraphRendererBase,
	T4DiagramProps,
} from 'features/entity4/visualizations/_shared/_components/graphRendererBase';
import { RegionColors } from 'features/entity4/visualizations/accountMap/models/accountMapTypes';
import { getDiagram } from '../../_shared/_templates/diagram';
import { EntityStatusOptionColors } from '../models/orgChartTypes';

export class OrgChartRenderer extends GraphRendererBase {
	private _onDoubleClick?: (event: go.InputEvent) => void;

	/** @internal */
	constructor(props: T4DiagramProps) {
		super(props);
		this.initDiagram = this.initDiagram.bind(this);
		this.createNodeTemplate = this.createNodeTemplate.bind(this);
		this.createLegendNode = this.createLegendNode.bind(this);
		this._onDoubleClick = props.onDoubleClick;
	}

	/**
	 * Diagram initialization method, which is passed to the ReactDiagram component.
	 * This method is responsible for making the diagram and initializing the model, any templates,
	 * and maybe doing other initialization tasks like customizing tools.
	 * The model's data should not be set here, as the ReactDiagram component handles that.
	 */
	private initDiagram(): go.Diagram {
		const diagram = getDiagram({
			initialAutoScale: go.AutoScale.UniformToFill,
			//note: layereddigraphlayout is more technically correct for multiple parents, but
			// different trees show up under an umbrella of other trees like in the below discussion
			// this discussion just says: "try the tree layout and see if it works..."
			//https://forum.nwoods.com/t/node-layout-in-banded-layereddigraphlayout/15904/11
			layout: new go.TreeLayout({
				angle: 90,
				arrangement: go.TreeArrangement.Horizontal,
				layerSpacing: 35,
				nodeSpacing: 40,
				arrangementSpacing: new go.Size(100, 100),
				// For compaction, make the last parents place their children in a bus
				treeStyle: go.TreeStyle.LastParents,
				alternateAngle: 90,
				alternateLayerSpacing: 35,
				alternateAlignment: go.TreeAlignment.BottomRightBus,
				alternateNodeSpacing: 20,
			}),
		});

		// diagram.routers.add(new AvoidsLinksRouter());

		this.createLegendGroupTemplate(diagram);
		diagram.groupTemplate = this.createOrphanGroupTemplate(
			'Standalone Entities',
			undefined,
			this.props.isOrphanGroupVisible,
		);
		diagram.nodeTemplate = this.createNodeTemplate(
			true,
			this.props.isFieldVisible,
		);
		diagram.linkTemplate = this.createLinkTemplate();
		diagram.linkTemplateMap.add(
			'slink',
			go.GraphObject.make(
				go.Link,
				{
					isLayoutPositioned: false,
					isTreeLink: false,
					relinkableFrom: false,
					relinkableTo: false,
					routing: go.Routing.AvoidsNodes,
					isHighlighted: false,
					copyable: false,
					deletable: false,
				},
				go.GraphObject.make(
					go.Shape,
					{ stroke: '#00aaff' },
					new go.Binding('strokeWidth', 'isSelected', (h) =>
						h ? 5 : 2,
					).ofObject(),
				),
				go.GraphObject.make(go.Shape, {
					toArrow: 'OpenTriangle',
					stroke: '#00aaff',
					strokeWidth: 8,
				}),
			),
		);

		this.setDiagramEvents(diagram);
		return diagram;
	}

	public createLegendNode(diagram: go.Diagram) {
		let headerFont = 'Bold 28pt Roboto,Helvetica,Arial,sans-serif';
		let itemFont = '28pt Roboto,Helvetica,Arial,sans-serif';
		let headerMargin = new go.Margin(10, 0, 10, 0);
		let panelMargin = new go.Margin(0, 0, 20, 0);
		let lineSeparatorMargin = new go.Margin(10, 0, 10, 0);
		let spaceBetweenShapeAndText = new go.Margin(5, 0, 0, 10);

		diagram.nodeTemplateMap.add(
			'LegendNode',
			new go.Node(go.Node.Auto, {
				name: 'LegendNode',
				copyable: false,
				deletable: false,
			})
				.bind('location', 'loc')
				.add(
					go.GraphObject.make(
						go.Panel,
						'Vertical',
						{
							stretch: go.Stretch.Horizontal,
							//defaultSeparatorPadding: new go.Margin(0, 200, 0, 0),
						},
						new go.Shape('LineH', {
							stroke: 'grey',
							strokeWidth: 1,
							height: 1,
							alignment: go.Spot.Top,
							alignmentFocus: go.Spot.Top,
							stretch: go.Stretch.Horizontal,
						}),
						go.GraphObject.make(go.TextBlock, 'Shapes and Connectors', {
							font: headerFont,
							stroke: this._textColor,
							alignment: go.Spot.TopLeft,
							margin: headerMargin,
						}),
						go.GraphObject.make(
							go.Panel,
							'Vertical',
							{ alignment: go.Spot.TopLeft },
							go.GraphObject.make(
								go.Panel,
								'Horizontal',
								{
									alignment: go.Spot.TopLeft,
									margin: panelMargin,
								},
								go.GraphObject.make(go.Shape, 'Square', {
									width: 20,
									height: 20,
									fill: null,
								}),
								go.GraphObject.make(go.TextBlock, {
									font: itemFont,
									stroke: this._textColor,
									text: 'Parent',
									margin: spaceBetweenShapeAndText,
								}),
							),
							go.GraphObject.make(
								go.Panel,
								'Horizontal',
								{
									alignment: go.Spot.TopLeft,
									margin: panelMargin,
								},
								go.GraphObject.make(go.Shape, 'Square', {
									width: 20,
									height: 20,
								}),
								go.GraphObject.make(go.TextBlock, {
									font: itemFont,
									stroke: this._textColor,
									text: 'Child',
									margin: spaceBetweenShapeAndText,
								}),
							),
							go.GraphObject.make(
								go.Panel,
								'Horizontal',
								{
									alignment: go.Spot.TopLeft,
									margin: panelMargin,
								},
								go.GraphObject.make(go.Shape, 'LineH', {
									width: 35,
									height: 1,
									stroke: 'black',
									strokeWidth: 2,
								}),
								go.GraphObject.make(go.TextBlock, {
									font: itemFont,
									stroke: this._textColor,
									text: 'Primary Ownership',
									margin: spaceBetweenShapeAndText,
								}),
							),
							go.GraphObject.make(
								go.Panel,
								'Horizontal',
								{
									alignment: go.Spot.TopLeft,
									margin: panelMargin,
								},
								go.GraphObject.make(go.Shape, 'LineH', {
									width: 35,
									height: 1,
									stroke: '#00aaff',
									strokeWidth: 2,
								}),
								go.GraphObject.make(go.TextBlock, {
									font: itemFont,
									stroke: this._textColor,
									text: 'Secondary Ownership',
									margin: spaceBetweenShapeAndText,
								}),
								new go.Binding('visible', 'isSecondaryOwnershipVisible'),
							),
						),
						go.GraphObject.make(
							go.Shape,
							'LineH',
							{
								stretch: go.Stretch.Horizontal,
								height: 1,
								margin: lineSeparatorMargin,
								stroke: '#e2e2e3',
								strokeWidth: 2,
							},
							new go.Binding(
								'visible',
								'',
								(t) => t.isEntityStatusVisible || t.isEntityRegionVisible,
							),
						),
						go.GraphObject.make(
							go.TextBlock,
							'Entity Status',
							{
								font: headerFont,
								stroke: this._textColor,
								alignment: go.Spot.TopLeft,
								margin: headerMargin,
								name: 'LegendAccountPurposeHeader',
							},
							new go.Binding('visible', 'isEntityStatusVisible'),
						),
						go.GraphObject.make(
							go.Panel,
							'Vertical',
							{
								alignment: go.Spot.TopLeft,
								name: 'LegendAccountPurpose',
							},
							new go.Binding('visible', 'isEntityStatusVisible'),
							new go.Binding('itemArray', 'entityStatuses'),
							{
								itemTemplate: go.GraphObject.make(
									go.Panel,
									'Horizontal',
									{ alignment: go.Spot.Left, margin: panelMargin },
									go.GraphObject.make(
										go.Shape,
										{
											margin: new go.Margin(0, 8, 0, 8),
											alignment: go.Spot.Left,
											strokeWidth: 0,
											width: 24,
											height: 24,
										},
										new go.Binding(
											'fill',
											'',

											(val) => EntityStatusOptionColors.get(val) || null,
										),
									),
									go.GraphObject.make(
										go.TextBlock,
										{
											font: itemFont,
											stroke: this._textColor,
											margin: spaceBetweenShapeAndText,
										},
										new go.Binding('text', ''),
									),
								),
							},
						),
						go.GraphObject.make(
							go.TextBlock,
							'Entity Region',
							{
								font: headerFont,
								stroke: this._textColor,
								alignment: go.Spot.TopLeft,
								margin: headerMargin,
							},
							new go.Binding('visible', 'isEntityRegionVisible'),
						),
						go.GraphObject.make(
							go.Panel,
							'Vertical',
							{
								alignment: go.Spot.TopLeft,
							},
							new go.Binding('itemArray', 'entityRegions'),
							new go.Binding('visible', 'isEntityRegionVisible'),
							{
								itemTemplate: go.GraphObject.make(
									go.Panel,
									'Horizontal',
									{ alignment: go.Spot.Left, margin: panelMargin },
									go.GraphObject.make(
										go.Shape,
										{
											margin: new go.Margin(0, 8, 0, 8),
											alignment: go.Spot.Left,
											strokeWidth: 0,
											width: 24,
											height: 24,
										},
										new go.Binding(
											'fill',
											'',
											(val) => RegionColors.get(val) || null,
										),
									),
									go.GraphObject.make(
										go.TextBlock,
										{
											font: itemFont,
											stroke: this._textColor,
											margin: spaceBetweenShapeAndText,
										},
										new go.Binding('text', ''),
									),
								),
							},
						),
					),
				),
		);
	}

	public createNodeTemplate(
		addMouseEvents: boolean,
		isFieldVisible: (property: string) => boolean,
	): go.Part {
		const nodeWidth = 400;
		const flagWidth = 50;
		const entityNameWidth = nodeWidth - 130;

		const createSubHeader = () => {
			return go.GraphObject.make(
				go.Panel,
				'Horizontal',
				{
					margin: new go.Margin(8, 8, 8, 8),
					defaultAlignment: go.Spot.Left,
					width: nodeWidth,
					// height: 50,
					//stretch: go.GraphObject.Horizontal,
				},
				go.GraphObject.make(
					go.Picture, // flag image
					{
						name: 'flagv',
						imageStretch: go.ImageStretch.Fill,
						imageAlignment: go.Spot.Left,
						margin: new go.Margin(0, 8, 0, 0),
						height: 35,
						width: flagWidth,
					},
					new go.Binding('visible', 'isFlagVisible'),
					new go.Binding('source', '', (data) => {
						return (
							window.location.origin +
							'/svg/' +
							(data.country || 'not_found') +
							'.svg'
						);
					}),
				),
				go.GraphObject.make(
					go.TextBlock,
					{
						name: 'entityInfoURL',
						//margin: new go.Margin(8, 8, 4, 8),
						alignment: go.Spot.Left,
						alignmentFocus: go.Spot.Left,
						wrap: go.Wrap.DesiredSize,
						overflow: go.TextOverflow.Ellipsis,
						width: entityNameWidth,
						cursor: 'pointer',
						font: '16pt "Roboto","Helvetica","Arial",sans-serif',
						stroke: this._textColor,
						isUnderline: true,
						click: (_: any, obj: any) => {
							window.open(
								'entities/' + obj.part.data.entityId + '/information',
							);
						},
					},
					new go.Binding('text', 'anglicizedLegalName'),
				),
			);
		};

		const createSubHeader2 = null;

		const createMainTable = () => {
			let row = 0;

			return [
				go.GraphObject.make(go.Shape, 'LineH', {
					stroke: 'black',
					strokeWidth: 1,
					height: 1,
					stretch: go.Stretch.Horizontal,
				}),
				go.GraphObject.make(
					go.Panel,
					'Table',
					{
						margin: this._tableTopMargin,
						defaultSeparatorPadding: this._tableSeparatorPadding,
						stretch: go.Stretch.Horizontal,
					},

					this.createTableRow(
						row++,
						'Entity Status',
						isFieldVisible('entityStatus'),
						'entityStatus',
					),
					this.createTableRow(
						row++,
						'Entity Region',
						isFieldVisible('entityRegion'),
						'entityRegion',
					),
					this.createTableRow(
						row++,
						'Functional Currency',
						isFieldVisible('functionalCurrencyCode'),
						'functionalCurrencyCode',
					),
					this.createTableRow(
						row++,
						'Incorporated Date',
						isFieldVisible('incorporatedDate'),
						'incorporatedDate',
					),
					this.createTableRow(
						row++,
						'ERP Code',
						isFieldVisible('erpCode'),
						'erpCode',
					),
					this.createTableRow(
						row++,
						'ERP Platform',
						isFieldVisible('erpPlatform'),
						'erpPlatform',
					),
					this.createTableRow(
						row++,
						'Acquired Company',
						isFieldVisible('acquiredCompany'),
						'acquiredCompany',
						(data) => {
							if (!data.acquiredCompany) {
								return '-';
							} else if (data.acquiredCompany === 'True') {
								return 'Yes';
							} else if (data.acquiredCompany === 'False') {
								return 'No';
							} else return 'Unknown';
						},
					),
					this.createTableRow(
						row++,
						'Acquisition Date',
						isFieldVisible('acquisitionDate'),
						'acquisitionDate',
					),
					this.createTableRow(
						row++,
						'Dissolution Date',
						isFieldVisible('dissolutionDate'),
						'dissolutionDate',
					),
					this.createTableRow(
						row++,
						'LEI Identifier',
						isFieldVisible('leiIdentifier'),
						'leiIdentifier',
					),
					this.createTableRow(
						row++,
						'Home Registration',
						isFieldVisible('stateProvince'),
						'stateProvince',
					),
					this.createTableRow(
						row++,
						'Registration Number',
						isFieldVisible('registrationNumber'),
						'registrationNumber',
					),
					this.createTableRow(
						row++,
						'Tax ID Number',
						isFieldVisible('taxIdNumber'),
						'taxIdNumber',
					),
					this.createTableRow(
						row++,
						'Tax Id Country',
						isFieldVisible('taxIdCountry'),
						'taxIdCountry',
					),
					this.createTableRow(
						row++,
						'Form of Organization',
						isFieldVisible('formOfOrganization'),
						'formOfOrganization',
					),
				),
			];
		};

		return this.createNodeTemplateBase(
			addMouseEvents,
			'entityCode',
			createSubHeader,
			createSubHeader2,
			createMainTable,
			'TreeExpanderButton',
			'entities',
			{
				nodeWidth: nodeWidth,
				onDoubleClick: this._onDoubleClick,
			},
		);
	}

	public render() {
		return (
			<ReactDiagram
				ref={this.diagramRef}
				divClassName="orgchart-gojs-diagram"
				style={this.diagramStyle}
				initDiagram={this.initDiagram}
				nodeDataArray={this.props.nodeDataArray}
				linkDataArray={this.props.linkDataArray}
				modelData={this.props.modelData}
			/>
		);
	}
}
