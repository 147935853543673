import { Grid } from '@mui/material';
import go from 'gojs';
import { ReactOverview } from 'gojs-react';
import { observer } from 'mobx-react-lite';
import {
	Relationship,
	VisualizationPreferenceNodeData,
	VisualizationPreferenceOption,
	VisualizationPreferences,
} from 'modules/clients/customer-api/src/api/visualizations';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { T4View } from 'shared/components/t4View';
import { ORG_CHART_ERROR_MESSAGING } from 'shared/constants/cannotDisplayMessaging';
import { orgChartPrefix } from 'stonly/pagePrefixes';
import { VisualizationsFooter } from '../_shared/_components/visualizationFooter';
import { useVisualizationContext } from '../_shared/_providers/visualizationProvider';
import { RegionColors } from '../accountMap/models/accountMapTypes';
import { useOrgChart } from './_providers/orgChartProvider';
import {
	OrgChartHeaderComponent,
	DisplayAuxiliaryNodesKey as StandaloneNodesKey,
} from './components/orgChartHeader';
import { OrgChartRenderer } from './components/orgChartRenderer';
import {
	CardOptionsDisplayKey,
	CardOptionUserDefinedColorKey,
	DisplayCompactKey,
	DisplayFlagKey,
	DisplayLegendKey,
	DisplayOverviewMapKey,
	EntityStatusOptionColors,
	InformationOptionsDisplayColumnOne,
	InformationOptionsDisplayColumnTwo,
	DisplaySecondaryOwnershipKey as SecondaryLinksKey,
} from './models/orgChartTypes';

type RadioOption = 'entityRegion' | 'entityStatus' | 'singleColor';

export const OrgChartPage: FC = observer(() => {
	const {
		isLoading,
		error,
		asOfDate,
		legalEntities,
		optionPreferences,
		nodePreferences,
		updatePreferences,
	} = useOrgChart();
	const { setZoom } = useVisualizationContext();

	const [diagram, setDiagram] = useState<go.Diagram>();
	const [showOverviewMap, setShowOverviewMap] = useState<boolean>(true);

	const getFilteredRelationships = useCallback(
		(relationships?: Relationship[]) =>
			relationships?.filter((x) => {
				let isValid = false;

				if (!x.from && !x.to) {
					isValid = true;
				} else if (x.from && !x.to) {
					isValid = asOfDate.isAfter(moment(x.from));
				} else if (!x.from && x.to) {
					isValid = asOfDate.isBefore(moment(x.to));
				} else if (x.from && x.to) {
					isValid = asOfDate.isBetween(moment(x.from), moment(x.to));
				}

				return isValid;
			}) ?? [],
		[asOfDate],
	);

	const primaryNodes = useMemo(() => {
		const referenceNodes = legalEntities
			.flatMap((x) =>
				getFilteredRelationships(x.primaryOwnerOf).concat(
					getFilteredRelationships(x.primaryMemberOf),
				),
			)
			.map((x) => x.objectId);
		return legalEntities.filter(
			(x) =>
				getFilteredRelationships(x.primaryOwnerOf).length > 0 ||
				getFilteredRelationships(x.primaryMemberOf).length > 0 ||
				referenceNodes.includes(x.id),
		);
	}, [getFilteredRelationships, legalEntities]);

	const getColorDisplayPreference = useCallback(
		(
			currentVisualizationPreferences: VisualizationPreferences | undefined = {
				id: 'something',
				options: optionPreferences,
			},
		) => {
			const currentColorByPreference =
				currentVisualizationPreferences?.options?.find(
					(x) => x.optionId === 'cardOptionsColor',
				)?.value ?? 'entityRegion';

			let colorByPreference: RadioOption = 'entityRegion';
			switch (currentColorByPreference) {
				case 'singleColor':
					colorByPreference = 'singleColor';
					break;
				case 'entityStatus':
					colorByPreference = 'entityStatus';
					break;
				case 'entityRegion':
					colorByPreference = 'entityRegion';
					break;
			}

			return colorByPreference;
		},
		[optionPreferences],
	);

	const getNodeColor = useCallback(
		(data: any) => {
			const colorByPreference = getColorDisplayPreference();

			const userDefinedColor =
				optionPreferences.find((x) => x.optionId === 'userDefinedColor')
					?.value ?? 'white';

			let color: string = 'white';
			if (colorByPreference === 'singleColor' && userDefinedColor) {
				color = userDefinedColor;
			} else {
				switch (colorByPreference) {
					case 'entityRegion':
						const entityRegionColor = RegionColors.get(data['entityRegion']);
						if (entityRegionColor) {
							color = entityRegionColor;
						}
						break;

					case 'entityStatus':
						const entityStatusColor = EntityStatusOptionColors.get(
							data['entityStatus'],
						);
						if (entityStatusColor) {
							color = entityStatusColor;
						}
						break;
				}
			}

			return color;
		},
		[getColorDisplayPreference, optionPreferences],
	);

	// todo: fix org chart selection before enabling this
	// const getNodeGroup = useCallback(
	// 	(node: any) => {
	// 		return primaryNodes.some((x) => x.id === node.data.id)
	// 			? undefined
	// 			: 'Standalone';
	// 	},
	// 	[primaryNodes],
	// );

	const getNodeVisibility = useCallback(
		(node: any) => {
			return nodePreferences.find((x) => x.key === node.id)?.visible ?? true;
		},
		[nodePreferences],
	);

	//#region Option Preferences

	const isFlagVisible = useMemo(
		() =>
			!(
				optionPreferences.find((op) => op.optionId === 'displayFlag')?.hide ??
				false
			),
		[optionPreferences],
	);

	const isStandaloneEntitiesVisible = useMemo(
		() =>
			!(
				optionPreferences.find((op) => op.optionId === StandaloneNodesKey)
					?.hide ?? false
			),
		[optionPreferences],
	);

	const isEntityStatusVisible = useMemo(
		() =>
			optionPreferences.find((op) => op.optionId === 'cardOptionsColor')
				?.value === 'entityStatus',
		[optionPreferences],
	);

	const isEntityRegionVisible = useMemo(
		() =>
			optionPreferences.find((op) => op.optionId === 'cardOptionsColor')
				?.value === 'entityRegion',
		[optionPreferences],
	);

	const isSecondaryOwnershipVisible = useMemo(
		() =>
			!(
				optionPreferences.find((op) => op.optionId === SecondaryLinksKey)
					?.hide ?? false
			),
		[optionPreferences],
	);

	const applyStandaloneGroupPreference = useCallback(
		(
			currentDiagram: go.Diagram,
			currentModel: go.Model,
			isStandaloneGroupVisible: boolean,
		) => {
			const standaloneEntitiesGroup = currentDiagram
				.findNodesByExample({ text: 'Standalone Entities' })
				.first();
			if (standaloneEntitiesGroup) {
				currentModel.set(
					standaloneEntitiesGroup,
					'visible',
					isStandaloneGroupVisible,
				);
			}
		},
		[],
	);

	const applySecondaryLinksPreference = useCallback(
		(
			currentDiagram: go.Diagram,
			currentModel: go.Model,
			isSecondaryLinksVisible: boolean,
		) => {
			currentDiagram.findLinksByExample({ category: 'slink' }).each((link) => {
				currentModel.set(link, 'visible', isSecondaryLinksVisible);
			});
		},
		[],
	);

	const applyLegendPreference = useCallback(
		(
			currentDiagram: go.Diagram,
			currentModel: go.Model,
			isLegendVisible: boolean,
		) => {
			const legendGroup = currentDiagram
				.findNodesByExample({ category: 'Legend' })
				.first();
			if (legendGroup) {
				currentModel.set(legendGroup, 'visible', isLegendVisible);
			}
		},
		[],
	);

	const applyCompactPreference = useCallback(
		(currentDiagram: go.Diagram, isCompactView: boolean) => {
			if (isCompactView) {
				currentDiagram.layout = new go.TreeLayout({
					angle: 90,
					alignment: go.TreeAlignment.Bus,
					arrangement: go.TreeArrangement.Horizontal,
				});
			} else {
				currentDiagram.layout = new go.TreeLayout({
					angle: 90,
					arrangement: go.TreeArrangement.Horizontal,
				});
			}
		},
		[],
	);

	const applyOverviewPreference = useCallback((isOverviewVisible: boolean) => {
		setShowOverviewMap(isOverviewVisible);
	}, []);

	const applyColorPreferences = useCallback(
		(
			currentDiagram: go.Diagram,
			currentModel: go.Model,
			colorByPreference: string | undefined,
			singleColorPreference: string | undefined,
		) => {
			switch (colorByPreference) {
				case 'entityStatus':
					colorByPreference = 'entityStatus';
					break;
				case 'entityRegion':
					colorByPreference = 'entityRegion';
					break;

				default:
					colorByPreference = 'singleColor';
					break;
			}

			// update legend color state
			currentDiagram.nodes
				.filter((x) => x.category === 'LegendNode')
				.each((node) => {
					['isEntityStatusVisible', 'isEntityRegionVisible'].forEach(
						(property) => {
							let isVisible = false;

							switch (colorByPreference) {
								case 'entityStatus':
									if (property === 'isEntityStatusVisible') {
										isVisible = true;
									}
									break;

								case 'entityRegion':
									if (property === 'isEntityRegionVisible') {
										isVisible = true;
									}
									break;
							}

							currentModel.set(node.data, property, isVisible);
						},
					);
				});

			// update node colors
			const getColor = (data: any) => {
				switch (colorByPreference) {
					case 'entityRegion':
						return RegionColors.get(data['entityRegion']) || 'white';

					case 'entityStatus':
						return (
							EntityStatusOptionColors.get(data['entityStatus']) || 'white'
						);

					case 'singleColor':
						return singleColorPreference || 'white';

					default:
						return 'white';
				}
			};
			currentDiagram.nodes
				.filter((node) => node.data.currentColor !== undefined)
				.each((node) => {
					currentModel.set(node.data, 'currentColor', getColor(node.data));
				});
		},
		[],
	);

	const applyFlagsPreference = useCallback(
		(
			currentDiagram: go.Diagram,
			currentModel: go.Model,
			isFlagVisible: boolean,
		) => {
			currentDiagram.nodes.each((node) => {
				currentModel.set(node.data, 'isFlagVisible', isFlagVisible);
			});
		},
		[],
	);

	const applyInformationOptionPreferences = useCallback(
		(
			currentPreferences: VisualizationPreferenceOption[],
			currentDiagram: go.Diagram,
			currentModel: go.Model,
		) => {
			const informationOptions = [
				...InformationOptionsDisplayColumnOne.flatMap((x) => x.items),
				...InformationOptionsDisplayColumnTwo.flatMap((x) => x.items),
			];

			const informationPreferences = currentPreferences.filter((x) =>
				informationOptions.includes(x.optionId),
			);
			currentDiagram.nodes.each((n) => {
				informationPreferences?.forEach((p) => {
					const rowPanel = n.findObject(p.optionId + 'p');
					if (rowPanel !== null) {
						currentModel.set(rowPanel, 'visible', !p.hide);
					}
				});
			});
		},
		[],
	);

	const applyNodeDataState = useCallback(
		(
			_currentDiagram: go.Diagram,
			_currentModel: go.Model,
			_currentNodeDataState: VisualizationPreferenceNodeData[],
		) => {
			return;

			// todo: fix org chart selection before enabling this
			// if (currentNodeDataState.length !== 0) {
			// 	currentDiagram.nodes.each((node) => {
			// 		const nodeState = currentNodeDataState.find(
			// 			(x) => x.key === node.data.key,
			// 		);
			// 		if (nodeState) {
			// 			if (nodeState.x && nodeState.y) {
			// 				currentModel.set(
			// 					node.data,
			// 					'loc',
			// 					new go.Point(nodeState.x, nodeState.y),
			// 				);
			// 			}

			// 			let isNodeVisible = nodeState.visible;
			// 			if (
			// 				!isStandaloneEntitiesVisible &&
			// 				node.data.group === 'Standalone'
			// 			) {
			// 				isNodeVisible = false;
			// 			}
			// 			currentModel.set(node.data, 'isVisible', isNodeVisible);
			// 		}
			// 		if (node.data.entityId) {
			// 			currentModel.set(node.data, 'group', getNodeGroup(node));
			// 		}
			// 	});
			// 	const standaloneGroupNode = currentDiagram.nodes
			// 		.filter((x) => x.data.key === 'Standalone')
			// 		.first();
			// 	if (standaloneGroupNode) {
			// 		currentModel.set(
			// 			standaloneGroupNode,
			// 			'visible',
			// 			currentDiagram.nodes.filter(
			// 				(x) => x.data.group === 'Standalone' && x.visible,
			// 			).count > 0,
			// 		);
			// 	}
			// }
		},
		[],
	);

	const loadPreferences = useCallback(
		(
			currentOptionPreferences: VisualizationPreferenceOption[],
			currentDiagram: go.Diagram,
		) => {
			const getOption = (optionId: string) =>
				currentOptionPreferences.find((option) => option.optionId === optionId);
			const isOptionVisible = (optionId: string) =>
				!(getOption(optionId)?.hide ?? false);

			const isOverviewVisible = isOptionVisible(DisplayOverviewMapKey);
			const isCompactView = isOptionVisible(DisplayCompactKey);
			const isFlagVisible = isOptionVisible(DisplayFlagKey);
			const isStandaloneEntitiesVisible = isOptionVisible(StandaloneNodesKey);
			const isSecondaryOwnershipVisible = isOptionVisible(SecondaryLinksKey);
			const isLegendVisible = isOptionVisible(DisplayLegendKey);
			const colorByPreference = getOption(CardOptionsDisplayKey)?.value;
			const singleColorPreference = getOption(CardOptionUserDefinedColorKey)
				?.value;

			currentDiagram.commit((d) => {
				d.model.commit((m) => {
					applyStandaloneGroupPreference(d, m, isStandaloneEntitiesVisible);
					applySecondaryLinksPreference(d, m, isSecondaryOwnershipVisible);
					applyLegendPreference(d, m, isLegendVisible);
					applyCompactPreference(d, isCompactView);
					applyOverviewPreference(isOverviewVisible);

					applyColorPreferences(d, m, colorByPreference, singleColorPreference);
					applyFlagsPreference(d, m, isFlagVisible);

					applyInformationOptionPreferences(
						currentOptionPreferences ?? [],
						d,
						m,
					);
				});
			});
		},
		[
			applyColorPreferences,
			applyCompactPreference,
			applyFlagsPreference,
			applyInformationOptionPreferences,
			applyLegendPreference,
			applyOverviewPreference,
			applySecondaryLinksPreference,
			applyStandaloneGroupPreference,
		],
	);

	const loadNodeLocationPreferences = useCallback(
		(
			currentPreferences: VisualizationPreferenceNodeData[],
			currentDiagram: go.Diagram,
		) => {
			currentDiagram.commit((d) => {
				d.model.commit((m) => {
					applyNodeDataState(d, m, currentPreferences ?? []);
				});
			});
		},
		[applyNodeDataState],
	);

	//#endregion

	//#region Node Preferences

	// const updateNodeData = useCallback(
	// 	(currentDiagram: go.Diagram) => {
	// 		const nextNodePreferences: VisualizationPreferenceNodeData[] = [];
	// 		currentDiagram.nodes
	// 			.filter(
	// 				(node) =>
	// 					!Number.isNaN(node.location.x) && !Number.isNaN(node.location.y),
	// 			)
	// 			.each((node) => {
	// 				let isNodeVisible =
	// 					(isStandaloneEntitiesVisible && node.data.group === 'Standalone') ||
	// 					node.visible;

	// 				nextNodePreferences.push({
	// 					id: node.data.key,
	// 					key: node.data.key,
	// 					x: node.location.x,
	// 					y: node.location.y,
	// 					visible: isNodeVisible,
	// 				});
	// 			});

	// 		updatePreferences({
	// 			nodes: nextNodePreferences,
	// 		});
	// 	},
	// 	[isStandaloneEntitiesVisible, updatePreferences],
	// );

	//#endregion

	//#region Diagram Data

	const nodes = useMemo<go.ObjectData[]>(() => {
		const nodes: go.ObjectData[] = [];

		nodes.push(
			...legalEntities.map((legalEntity) => {
				const {
					incorporationDate,
					primaryOwnerOf,
					secondaryMemberOf,
					primaryMemberOf,
					secondaryOwnerOf,
					homeRegistration,
					federalTaxId,
					...rest
				} = legalEntity;

				return {
					key: rest.id,
					isFlagVisible: isFlagVisible,
					currentColor: getNodeColor(legalEntity),
					group: primaryNodes.map((x) => x.id).includes(legalEntity.id)
						? undefined
						: 'Standalone',
					isVisible: getNodeVisibility(legalEntity),
					entityId: rest.id,

					...rest,
					incorporatedDate: incorporationDate,
					registrationNumber: homeRegistration?.number,
					stateProvince: homeRegistration?.stateProvince,
					taxIdNumber: federalTaxId?.number,
					taxIdCountry: federalTaxId?.country,
				};
			}),
		);

		const standaloneKey = 'Standalone';
		nodes.push({
			key: standaloneKey,
			text: 'Standalone Entities',
			isGroup: true,
			visible: isStandaloneEntitiesVisible,
		});

		const entityRegions = legalEntities.map((x) => x.entityRegion);
		const distinctEntityRegions = [...new Set(entityRegions)].filter(Boolean);
		const entityStatuses = nodes.map((node) => node.entityStatus);
		const distinctEntityStatuses = [...new Set(entityStatuses)].filter(Boolean);

		const legendKey = 'Legend';
		nodes.push({
			key: legendKey,
			category: 'Legend',
			isGroup: true,
		});

		const legendNodeKey = 'LegendNode';
		nodes.push({
			key: legendNodeKey,
			category: 'LegendNode',
			group: 'Legend',
			entityRegions: distinctEntityRegions,
			entityStatuses: distinctEntityStatuses,
			isSecondaryOwnershipVisible: isSecondaryOwnershipVisible,
			isEntityRegionVisible: isEntityRegionVisible,
			isEntityStatusVisible: isEntityStatusVisible,
		});

		return nodes;
	}, [
		getNodeColor,
		getNodeVisibility,
		isEntityRegionVisible,
		isEntityStatusVisible,
		isFlagVisible,
		isSecondaryOwnershipVisible,
		isStandaloneEntitiesVisible,
		legalEntities,
		primaryNodes,
	]);

	const links = useMemo<go.ObjectData[]>(() => {
		const links: go.ObjectData[] = [];

		links.push(
			...legalEntities.flatMap((legalEntity) =>
				getFilteredRelationships(legalEntity.primaryOwnerOf)
					.concat(getFilteredRelationships(legalEntity.primaryMemberOf))
					.map((relationship) => ({
						key: `${legalEntity.id}-${relationship.objectId}`,
						from: legalEntity.id,
						to: relationship.objectId,
					})),
			),
		);

		links.push(
			...legalEntities.flatMap((legalEntity) =>
				getFilteredRelationships(legalEntity.secondaryOwnerOf)
					.concat(getFilteredRelationships(legalEntity.secondaryMemberOf))
					.map((relationship) => ({
						key: `${legalEntity.id}-${relationship.objectId}`,
						category: 'slink',
						from: legalEntity.id,
						to: relationship.objectId,
						visible: isSecondaryOwnershipVisible,
					})),
			),
		);

		return links;
	}, [getFilteredRelationships, isSecondaryOwnershipVisible, legalEntities]);

	//#endregion

	// const doubleClicked = useCallback(
	// 	(event: go.InputEvent) => {
	// 		updateNodeData(event.diagram);
	// 	},
	// 	[updateNodeData],
	// );

	// const selectionMoved = useCallback(
	// 	(event: go.DiagramEvent) => {
	// 		updateNodeData(event.diagram);
	// 	},
	// 	[updateNodeData],
	// );

	// const handleAnimationFinished = useCallback(
	// 	(event: go.DiagramEvent) => {
	// 		loadNodeLocationPreferences(nodePreferences, event.diagram);
	// 	},
	// 	[loadNodeLocationPreferences, nodePreferences],
	// );

	useEffect(() => {
		if (diagram) {
			loadPreferences(optionPreferences, diagram);
		}
	}, [diagram, loadPreferences, optionPreferences]);

	useEffect(() => {
		if (diagram && nodePreferences) {
			loadNodeLocationPreferences(nodePreferences, diagram);
		}
	}, [diagram, loadNodeLocationPreferences, nodePreferences]);

	if (error) {
		return (
			<CannotDisplay
				headingText={ORG_CHART_ERROR_MESSAGING.HEADING}
				bodyText={ORG_CHART_ERROR_MESSAGING.BODY}
				imageSrc={ORG_CHART_ERROR_MESSAGING.IMAGE}
				buttonHref={ORG_CHART_ERROR_MESSAGING.BUTTON_HREF}
				buttonText={ORG_CHART_ERROR_MESSAGING.BUTTON_TEXT}
			/>
		);
	}

	return (
		<T4View loading={isLoading} disablePadding>
			<Grid
				container
				flexDirection="column"
				sx={{ height: '100%', width: '100%', overflow: 'hidden' }}
			>
				<Grid item xs="auto">
					<OrgChartHeaderComponent diagram={diagram} />
				</Grid>
				<Grid
					item
					xs={true}
					sx={{
						position: 'relative',
						boxSizing: 'unset',

						canvas: {
							outline: 'none',
						},
						'.orgchart-gojs-diagram': {
							width: '100%',
							height: '100%',
						},
					}}
				>
					{showOverviewMap && (
						<ReactOverview
							initOverview={() =>
								new go.Overview(undefined, {
									contentAlignment: go.Spot.Center,
								})
							}
							divClassName="overview"
							style={{
								backgroundColor: '#eee',
								height: '150px',
								width: '250px',
								position: 'absolute',
								top: '2rem',
								left: '2rem',
								border: 'solid',
								borderWidth: '1px',
								borderColor: 'black',
								zIndex: 10,
								outline: 'none',
							}}
							observedDiagram={diagram ?? null}
						/>
					)}
					<OrgChartRenderer
						nodeDataArray={nodes}
						linkDataArray={links}
						modelData={{
							canRelink: false,
						}}
						onModelChange={() => {}}
						initialLayoutCompleted={(event) => {
							setDiagram(event.diagram);
							loadPreferences(optionPreferences, event.diagram);
						}}
						backgroundSingleClicked={() => {}}
						viewPortBoundsChanged={(event) => setZoom(event.diagram.scale)}
						isFieldVisible={(property) => {
							return !(
								optionPreferences.find((op) => op.optionId === property)
									?.hide ?? false
							);
						}}
						isOrphanGroupVisible={isStandaloneEntitiesVisible}
						// diagramListeners={{
						// 	selectionMoved: (event) => selectionMoved(event),
						// 	animationFinished: (event) => handleAnimationFinished(event),
						// }}
						// onDoubleClick={doubleClicked}
					/>
				</Grid>
				<Grid
					item
					xs="auto"
					sx={{
						paddingBottom: '1rem',
						justifyContent: 'center',
						position: 'absolute',
						bottom: '1rem',
						zIndex: 5,
					}}
				>
					<VisualizationsFooter
						stonlyId={orgChartPrefix}
						diagram={diagram}
						onResetView={() => {
							if (diagram) {
								updatePreferences({
									nodes: [],
								});
								loadPreferences(optionPreferences, diagram);
							}
						}}
					/>
				</Grid>
			</Grid>
		</T4View>
	);
});
