import LinkIcon from '@mui/icons-material/Link';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, CircularProgress, Grid, Tab } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { stonlyData } from '../../../../stonly/functions';
import { connectionsListPagePrefix } from '../../../../stonly/pagePrefixes';
import { BankConnection } from './components/bankConnection';
import { CreateConnectionModal } from './components/createConnectionModal';
import { EditConnectionModal } from './components/editConnectionModal';
import { SetAccountIntegrationScheduleModal } from './components/setAccountIntegrationScheduleModal';
import { useConnectionsList } from './hooks/useConnectionList';
import { useSetAccountIntegrationSchedule } from './hooks/useSetAccountIntegrationSchedule';

export type BankTab = {
	code: string;
	name?: string;
};

export const ConnectionsListPage: FC = observer(() => {
	const {
		isCreateModalOpen,
		isEditModalOpen,
		connectionIndex,
		bankTabs,
		banks,
		activeTab,
		error,
		loading,
		tabChange,
		load,
		connect,
		onCreateModalOpen,
		onCreateModalClose,
		onEditModalOpen,
		onEditModalClose,
	} = useConnectionsList();

	const setAccountIntegrationSchedule = useSetAccountIntegrationSchedule(load);

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<CircularProgress sx={{ marginTop: '3rem' }} />
		</Box>
	) : (
		<Grid
			container
			sx={{
				gap: 1,
				height: '100%',
				flexDirection: 'column',
				flexWrap: 'nowrap',
			}}
		>
			<Grid container item xs="auto" sx={{ justifyContent: 'flex-end' }}>
				<Button
					startIcon={<LinkIcon />}
					type="button"
					variant="outlined"
					color="primary"
					onClick={() => onCreateModalOpen()}
					{...stonlyData({
						id: `${connectionsListPagePrefix}-create-connection-button`,
					})}
				>
					Create Connection
				</Button>
			</Grid>
			<Grid item xs={'auto'}>
				<TabContext value={activeTab}>
					<Grid container sx={{ gap: 1 }}>
						<Grid item xs={12}>
							<TabList
								{...stonlyData({
									id: `${connectionsListPagePrefix}-bank-tab-wrapper`,
								})}
								onChange={(_, tab) => tabChange(tab)}
							>
								{bankTabs.map((bankTab) => (
									<Tab
										key={bankTab.code}
										color="primary"
										label={bankTab.name ?? bankTab.code}
										value={bankTab.code}
										{...stonlyData({
											id: `${connectionsListPagePrefix}-bank-tab-${bankTab.code.toLowerCase()}`,
										})}
									/>
								))}
							</TabList>
						</Grid>
						<Grid item xs={12}>
							{banks.map(([bankCode, bank]) => (
								<Grid key={bankCode} item>
									<TabPanel id={bankCode} value={bankCode} sx={{ padding: 0 }}>
										<Grid container sx={{ rowGap: 3, paddingBottom: '2rem' }}>
											{bank.connections.map((connection) => (
												<Grid key={connection.id} item xs={12}>
													<BankConnection
														loading={loading}
														errorMessage={error}
														connection={connection}
														refetch={load}
														connect={connect}
														openSetAccountIntegrationScheduleModal={
															setAccountIntegrationSchedule.openModal
														}
														onClickView={(connectionIndex) =>
															onEditModalOpen(connectionIndex)
														}
													/>
												</Grid>
											))}
										</Grid>
									</TabPanel>
								</Grid>
							))}
						</Grid>
					</Grid>
				</TabContext>
			</Grid>
			<SetAccountIntegrationScheduleModal
				setAccountIntegrationSchedule={setAccountIntegrationSchedule}
			/>
			<CreateConnectionModal
				isOpen={isCreateModalOpen}
				onClose={() => onCreateModalClose()}
				fetchConnections={() => load()}
				setActiveBankTab={tabChange}
			/>
			<EditConnectionModal
				isOpen={isEditModalOpen}
				connectionIndex={connectionIndex}
				onClose={() => onEditModalClose()}
				fetchConnections={() => load()}
			/>
		</Grid>
	);
});
