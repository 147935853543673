// NOTE: The value for the keys added below must match a camelCase version of the key in LaunchDarkly
export enum FeatureFlag {
	cash4CategorizationMvp = 'cash4CategorizationMvp',
	orgChartV2 = 'orgChartV2',
	accountMap = 'entity4AccountMap',
	t4DarkMode = 't4DarkMode',
	payments4Module = 'payments4Module',
	entity4StaffPii = 'entity4StaffPii',
	entity4AccountMapV2 = 'entity4AccountMapV2',
	sigmaStudio = 'sigmaStudio',
	investmentTransactions = 'showCash4InvestmentTransactions',
	projectedTransactions = 'showCash4ProjectedTransactions',
	sigmaDashboard = 'sigmaDashboard',
}
