import { AutoGraph } from '@mui/icons-material';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import {
	SigmaDashboard,
	SigmaElement,
	SigmaWidget,
	SigmaWidgetReq,
} from 'modules/clients/customer-api/src/sigmaEmbed';
import { useSnackbar } from 'notistack';
import {
	FC,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Layout } from 'react-grid-layout';
import { ORG_CHART_ERROR_MESSAGING } from 'shared/constants/cannotDisplayMessaging';
import { useClients } from 'shared/hooks/useClients';

//#region Constants

export const SigmaEntity4DashboardCode = 'entity4';

//#endregion

//#region Context

export type SigmaDashboardContextProps = {
	initializingDash: boolean;
	initializingWidgetPicker: boolean;
	initialize: () => void;
	visuals: SigmaElement[];
	dashboard: SigmaDashboard | undefined;
	saveWidgets: (layout: Layout[]) => Promise<void>;
	orgDefault: boolean;
	setOrgDefault?: (value: boolean) => void;
	hardcodedComponents: Record<string, JSX.Element>;
	resetDashboard?: () => void;
};

const SigmaDashboardContext = createContext<SigmaDashboardContextProps>({
	initializingDash: true,
	initializingWidgetPicker: true,
	initialize: () => {},
	visuals: [],
	dashboard: undefined,
	saveWidgets: async () => {},
	orgDefault: false,
	setOrgDefault: () => {},
	hardcodedComponents: {},
	resetDashboard: () => {},
});

//#endregion

//#region Provider

export type SigmaDashboardProviderProps = {
	code: string;
	children: ReactNode;
};

export const SigmaDashboardProvider: FC<SigmaDashboardProviderProps> = observer(
	({ code, children }) => {
		const { customerApiClient } = useClients();
		const { enqueueSnackbar } = useSnackbar();

		const [_, setDashboards] = useState<SigmaDashboard[]>([]);
		const [dashboard, setDashboard] = useState<SigmaDashboard>();
		const [initializingDash, setInitializingDash] = useState(true);
		const [initializingWidgetPicker, setInitializingWidgetPicker] =
			useState(true);
		const [visuals, setVisuals] = useState<SigmaElement[]>([]);
		const [orgDefault, setOrgDefault] = useState<boolean>(false);

		const hardcodedComponents: Record<string, JSX.Element> = {
			'analytics-studio': (
				<Grid
					container
					sx={{
						height: '100%',
						justifyContent: 'center',
						alignContent: 'center',
					}}
					onClick={() => {
						window.location.href = '/entity4/analytics-studio-beta';
					}}
				>
					<Grid
						item
						xs={1}
						sx={{ alignContent: 'center', justifyContent: 'center' }}
					>
						<AutoGraph fontSize="large" />
					</Grid>
					<Grid item xs={10}>
						<Stack>
							<Typography>Analytics Studio</Typography>
							<Typography variant="h2">Explore your data, your way.</Typography>
						</Stack>
					</Grid>
				</Grid>
			),
			'entity-org-chart': (
				<Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
					<Grid item xs={12} container direction="column">
						<Typography>Entity Org Chart</Typography>
						<Grid item xs={12} container justifyContent="center">
							<Box
								component="img"
								sx={{
									height: '50%',
									objectFit: 'contain',
									maxWidth: '70%',
									maxHeight: '50%',
								}}
								alt="Entity Org Chart"
								src={ORG_CHART_ERROR_MESSAGING.IMAGE}
							/>
						</Grid>
					</Grid>

					<Grid item xs={12} container justifyContent="center">
						<Button
							variant="contained"
							color="primary"
							href={'entity4/org-chart'}
						>
							View Org Chart
						</Button>
					</Grid>
				</Grid>
			),
			'account-map': (
				<Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
					<Grid item xs={12} container direction="column">
						<Typography>Account Map</Typography>
						<img
							src={ORG_CHART_ERROR_MESSAGING.IMAGE}
							style={{
								height: '50%',
								objectFit: 'contain',
								maxWidth: '100%',
								maxHeight: '60%',
							}}
						/>
					</Grid>

					<Grid item xs={12} container justifyContent="center">
						<Button
							variant="contained"
							color="primary"
							href={'entity4/account-map'}
						>
							View Account Map
						</Button>
					</Grid>
				</Grid>
			),
		};

		const hardcodedComponentsIds = useMemo(
			() => [
				['analytics-studio', 'entity4', 'Analytics Studio Navigation Button'],
				['entity-org-chart', 'entity4', 'Entity Org Chart Navigation Button'],
				['account-map', 'entity4', 'Account Map Navigation Button'],
			],
			[],
		);

		const saveWidgets = useCallback(
			async (layout: Layout[]) => {
				try {
					const widgets: SigmaWidgetReq[] = layout
						.filter((item) =>
							visuals.find((x) => `${x.workbookId}-${x.elementId}` === item.i),
						)
						.map((item) => {
							const visual = visuals.find(
								(visual) =>
									`${visual.workbookId}-${visual.elementId}` === item.i,
							)!;

							return {
								workbookId: visual.workbookId,
								elementId: visual.elementId,
								workbookUrlId: visual.workbookUrlId,
								vizualizationType: visual.vizualizationType,
								width: item.w,
								height: item.h,
								x: item.x,
								y: item.y,
							};
						});
					if (dashboard && !dashboard.isDefault) {
						await customerApiClient.sigmaEmbed.updateDashboard({
							id: dashboard.id,
							req: {
								code: code,
								isOrgDefault: orgDefault,
								widgets: widgets,
								delete: false,
							},
						});
					} else {
						const createdDashboardResponse =
							await customerApiClient.sigmaEmbed.createDashboard({
								code: code,
								widgets: widgets,
								isOrgDefault: orgDefault,
								delete: false,
							});

						if (createdDashboardResponse.data?.data) {
							setDashboard(createdDashboardResponse.data.data);
							setDashboards((prev) => [
								...prev,
								createdDashboardResponse.data.data,
							]);
						}
					}
				} catch {
					enqueueSnackbar('Failed to save widgets.', {
						variant: 'error',
					});
				}
			},
			[
				code,
				customerApiClient.sigmaEmbed,
				dashboard,
				enqueueSnackbar,
				visuals,
				orgDefault,
			],
		);

		const initialize = useCallback(async () => {
			if (initializingDash) {
				try {
					customerApiClient.sigmaEmbed
						.getDashboards({
							code: code,
						})
						.then((response) => {
							var filteredResponse = response.data.data.filter(
								(x) => !x.deletedDateUtc,
							);
							if (filteredResponse.length > 0) {
								setDashboards(filteredResponse);
								setDashboard(filteredResponse[0]);
								filteredResponse[0].widgets.forEach((widget) => {
									let visual: SigmaElement = {
										elementId: widget.elementId,
										workbookId: widget.workbookId,
										name: '',
										type: '',
										vizualizationType: widget.vizualizationType,
										report: '',
										page: '',
										workbookName: '',
										workbookPageName: '',
										workbookUrlId: widget.workbookUrlId,
										workbookPageId: '',
										workbookFolderId: '',
										workbookPermission: 'view',
										embedId: '',
									};
									visuals.push(visual);
								});
								setInitializingDash(false);
							} else {
								const response = customerApiClient.sigmaEmbed
									.visualEmbeds({
										solution: code.substring(0, code.indexOf('4')),
									})
									.then((response) => {
										if (response.data && response.data.length > 0) {
											let visuals = response.data;
											hardcodedComponentsIds.forEach((id, index) => {
												if (id[1] === code) {
													visuals.push({
														elementId: id[0],
														workbookId: 'hardcoded',
														name: id[2],
														type: 'Navigation Button',
														vizualizationType: 'Button',
														report: 'Entity4 Navigation',
														page: 'Visualizations',
														workbookName: 'Navigation',
														workbookPageName: 'Additional',
														workbookUrlId: '',
														workbookPageId: '',
														workbookFolderId: '',
														workbookPermission: 'view',
														embedId: '',
													});
												}
											});

											setVisuals(
												visuals.filter((x) => !x.name.includes('hardcoded')),
											);

											const defaultWidgets: SigmaWidget[] = [];
											let rows = 0;

											visuals.forEach((visual) => {
												const rowInfo = visual.workbookPageName
													.split(',')
													.map(Number);
												if (rowInfo[0] > rows) {
													rows = rowInfo[0];
												}
											});
											let heightsPrev = 0;
											for (let row = 1; row <= rows; row++) {
												const visualsInRow = visuals.filter(
													(visual) =>
														visual.workbookPageName
															.split(',')
															.map(Number)[0] === row,
												);

												let width = Math.floor(24 / visualsInRow.length);
												let height = 0;
												visualsInRow.forEach((visual, index) => {
													const rowInfo = visual.workbookPageName
														.split(',')
														.map(Number);
													height = rowInfo[1];
													if (visual.name.includes('hardcoded')) {
														defaultWidgets.push({
															id: visual.name.split(',')[1].trim(),
															workbookId: 'hardcoded',
															elementId: visual.name.split(',')[1].trim(),
															workbookUrlId: 'hardcoded',
															vizualizationType: 'Button',
															width: width,
															height: height,
															x: index * width,
															y: heightsPrev,
														});
													} else {
														defaultWidgets.push({
															id: visual.elementId,
															workbookId: visual.workbookId,
															elementId: visual.elementId,
															workbookUrlId: visual.workbookUrlId,
															vizualizationType: visual.vizualizationType,
															width: width,
															height: height,
															x: index * width,
															y: heightsPrev,
														});
													}
												});

												heightsPrev += height;
											}
											setDashboard({
												code: code,
												widgets: defaultWidgets,
												isDefault: true,
												isOrgDefault: false,
												createdBy: '',
												createdOn: new Date(),
												updatedBy: '',
												updatedOn: new Date(),
												id: '',
												deletedDateUtc: undefined,
											});
											setInitializingDash(false);
										}
									});
							}
						});
					const response = await customerApiClient.sigmaEmbed.visualEmbeds({
						solution:
							code.substring(0, code.indexOf('4')) === 'cash'
								? 'none'
								: code.substring(0, code.indexOf('4')),
					});
					if (response.data && response.data.length > 0) {
						const visuals = response.data;
						hardcodedComponentsIds.forEach((id, index) => {
							if (id[1] === code) {
								visuals.push({
									elementId: id[0],
									workbookId: 'hardcoded',
									name: id[2],
									type: 'Navigation Button',
									vizualizationType: 'Button',
									report: 'Entity4 Navigation',
									page: 'Visualizations',
									workbookName: 'Entity4 Navigation',
									workbookPageName: 'Visualizations',
									workbookUrlId: '',
									workbookPageId: '',
									workbookFolderId: '',
									workbookPermission: 'explore',
									embedId: '',
								});
							}
						});
						setVisuals(visuals.filter((x) => !x.name.includes('hardcoded')));
						setInitializingWidgetPicker(false);
					}
				} catch {
					enqueueSnackbar({
						message: 'Failed to initialize Sigma.',
						variant: 'error',
					});
				} finally {
					setInitializingDash(false);
					setInitializingWidgetPicker(false);
				}
			}
		}, [
			code,
			customerApiClient.sigmaEmbed,
			enqueueSnackbar,
			initializingDash,
			visuals,
			hardcodedComponentsIds,
		]);

		useEffect(() => {
			initialize();
		}, [initialize]);

		const resetDashboard = useCallback(() => {
			setInitializingDash(true);
			if (dashboard) {
				customerApiClient.sigmaEmbed
					.updateDashboard({
						id: dashboard.id,
						req: {
							code: code,
							isOrgDefault: orgDefault,
							widgets: dashboard.widgets,
							delete: true,
						},
					})
					.then(() => {
						setDashboard(undefined);
						const response = customerApiClient.sigmaEmbed
							.visualEmbeds({
								solution: code.substring(0, code.indexOf('4')),
							})
							.then((response) => {
								if (response.data && response.data.length > 0) {
									let visuals = response.data;
									hardcodedComponentsIds.forEach((id, index) => {
										if (id[1] === code) {
											visuals.push({
												elementId: id[0],
												workbookId: 'hardcoded',
												name: id[2],
												type: 'Navigation Button',
												vizualizationType: 'Button',
												report: 'Entity4 Navigation',
												page: 'Visualizations',
												workbookName: 'Navigation',
												workbookPageName: 'Additional',
												workbookUrlId: '',
												workbookPageId: '',
												workbookFolderId: '',
												workbookPermission: 'view',
												embedId: '',
											});
										}
									});
									const defaultWidgets: SigmaWidget[] = [];
									let rows = 0;

									visuals.forEach((visual) => {
										const rowInfo = visual.workbookPageName
											.split(',')
											.map(Number);
										if (rowInfo[0] > rows) {
											rows = rowInfo[0];
										}
									});
									let heightsPrev = 0;
									for (let row = 1; row <= rows; row++) {
										const visualsInRow = visuals.filter(
											(visual) =>
												visual.workbookPageName.split(',').map(Number)[0] ===
												row,
										);

										let width = Math.floor(24 / visualsInRow.length);
										let height = 0;
										visualsInRow.forEach((visual, index) => {
											const rowInfo = visual.workbookPageName
												.split(',')
												.map(Number);
											height = rowInfo[1];
											if (visual.name.includes('hardcoded')) {
												defaultWidgets.push({
													id: visual.name.split(',')[1].trim(),
													workbookId: 'hardcoded',
													elementId: visual.name.split(',')[1].trim(),
													workbookUrlId: 'hardcoded',
													vizualizationType: 'Button',
													width: width,
													height: height,
													x: index * width,
													y: heightsPrev,
												});
											} else {
												defaultWidgets.push({
													id: visual.elementId,
													workbookId: visual.workbookId,
													elementId: visual.elementId,
													workbookUrlId: visual.workbookUrlId,
													vizualizationType: visual.vizualizationType,
													width: width,
													height: height,
													x: index * width,
													y: heightsPrev,
												});
											}
										});

										heightsPrev += height;
									}
									setDashboard({
										code: code,
										widgets: defaultWidgets,
										isDefault: true,
										isOrgDefault: false,
										createdBy: '',
										createdOn: new Date(),
										updatedBy: '',
										updatedOn: new Date(),
										id: '',
									});
									setDashboards([]);
									setInitializingDash(false);
								}
							});
					});
			}
		}, [code, customerApiClient.sigmaEmbed, visuals, hardcodedComponentsIds]);

		return (
			<SigmaDashboardContext.Provider
				value={{
					initializingDash,
					initializingWidgetPicker,
					initialize,
					visuals,
					dashboard,
					saveWidgets,
					orgDefault,
					setOrgDefault,
					hardcodedComponents,
					resetDashboard,
				}}
			>
				{children}
			</SigmaDashboardContext.Provider>
		);
	},
);

//#endregion

//#region Hook

export type UseSigmaDashboardProps = SigmaDashboardContextProps;

export function useSigmaDashboard(): UseSigmaDashboardProps {
	return useContext(SigmaDashboardContext);
}

//#endregion
