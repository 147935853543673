import {
	VisualizationPreferenceNodeData,
	VisualizationPreferenceOption,
	VisualizationPreferences,
	VisualizationPreferencesReq,
	VisualizationPreferenceView,
} from 'modules/clients/customer-api/src/api/visualizations';
import {} from 'modules/clients/customer-api/src/userPreference';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { AsOfDateKey } from '../../orgChart/models/orgChartTypes';

export type UseUserVisualizationPreferenceProps = {
	isLoading: boolean;
	error: string | undefined;
	/** @deprecated Please use optionPreferences, nodePreferences  */
	visualizationPreferences: VisualizationPreferences | undefined;
	optionPreferences: VisualizationPreferenceOption[];
	nodePreferences: VisualizationPreferenceNodeData[];
	views: VisualizationPreferenceView[];
	updatePreferences: (state: {
		options?: VisualizationPreferenceOption[];
		nodes?: VisualizationPreferenceNodeData[];
	}) => Promise<void>;
	createView: (name: string) => Promise<void>;
	selectView: (viewId: string) => Promise<void>;
	overwriteView: (viewId: string) => Promise<void>;
	deleteView: (viewId: string) => Promise<void>;
};

export function useUserVisualizationPreference(
	visualizationCode: string,
	initialState: VisualizationPreferencesReq,
): UseUserVisualizationPreferenceProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string>();
	const [visualizationPreferences, setVisualizationPreferences] =
		useState<VisualizationPreferences>();
	const [optionPreferences, setOptionPreferences] = useState<
		VisualizationPreferenceOption[]
	>([]);
	const [nodePreferences, setNodePreferences] = useState<
		VisualizationPreferenceNodeData[]
	>([]);
	const [views, setViews] = useState<VisualizationPreferenceView[]>([]);

	const setPreferences = useCallback<
		(state: {
			options?: VisualizationPreferenceOption[];
			nodes?: VisualizationPreferenceNodeData[];
		}) => Promise<void>
	>(
		async ({ options, nodes }) => {
			setOptionPreferences(options ?? []);
			setNodePreferences(nodes ?? []);
			try {
				const response =
					await customerApiClient.api.visualizations.udpateVisualizationPreferences(
						{
							visualizationCode: visualizationCode,
							req: {
								options: options ?? [],
								nodes: nodes ?? [],
							},
						},
					);

				if (response?.data?.data) {
					setVisualizationPreferences(response.data.data);
				}
			} catch {
				enqueueSnackbar('Failed to set visualization preferences.', {
					variant: 'error',
				});
			}
		},
		[customerApiClient.api.visualizations, enqueueSnackbar, visualizationCode],
	);

	const updatePreferences = useCallback<
		UseUserVisualizationPreferenceProps['updatePreferences']
	>(
		async ({ options, nodes }) => {
			if (visualizationPreferences) {
				let nextOptions = [...optionPreferences];
				if (options !== undefined && options !== null) {
					nextOptions =
						nextOptions.filter(
							(x) => !options.some((y) => y.optionId === x.optionId),
						) ?? [];
					options.forEach((x) => nextOptions.push(x));
					setOptionPreferences(nextOptions);
				}

				let nextNodes = [...nodePreferences];
				if (nodes !== undefined && nodes !== null) {
					nextNodes = nodes;
					setNodePreferences(nextNodes);
				}

				try {
					const response =
						await customerApiClient.api.visualizations.udpateVisualizationPreferences(
							{
								visualizationCode: visualizationCode,
								req: {
									options: nextOptions,
									nodes: nextNodes,
								},
							},
						);

					if (response?.data?.data) {
						setVisualizationPreferences(response.data.data);
					}
				} catch {
					enqueueSnackbar('Failed to update visualization preferences.', {
						variant: 'error',
					});
				}
			}
		},
		[
			customerApiClient.api.visualizations,
			enqueueSnackbar,
			nodePreferences,
			optionPreferences,
			visualizationCode,
			visualizationPreferences,
		],
	);

	const createView = useCallback(
		async (name: string) => {
			if (visualizationPreferences) {
				try {
					const response =
						await customerApiClient.api.visualizations.updateVisualizationPreferenceView(
							{
								visualizationCode: visualizationCode,
								id: visualizationPreferences.id,
								req: {
									name: name,
									options: optionPreferences,
									nodes: nodePreferences,
								},
							},
						);

					if (response && response.data && response.data.data) {
						setVisualizationPreferences(response.data.data);
						setViews(response.data.data.views ?? []);
					}
				} catch {
					const errorMessage = 'Failed to create view.';
					setError(errorMessage);
					enqueueSnackbar(errorMessage, {
						variant: 'error',
					});
				}
			}
		},
		[
			customerApiClient.api.visualizations,
			enqueueSnackbar,
			nodePreferences,
			optionPreferences,
			visualizationCode,
			visualizationPreferences,
		],
	);

	const selectView = useCallback(
		async (viewId: string) => {
			const selectedView = views.find((x) => x.id === viewId);
			if (selectedView) {
				setPreferences({
					options: selectedView.options,
					nodes: selectedView.nodes,
				});
			}
		},
		[setPreferences, views],
	);

	const overwriteView = useCallback(
		async (viewId: string) => {
			if (visualizationPreferences) {
				try {
					const selectedView = views.find((x) => x.id === viewId);
					if (selectedView) {
						const response =
							await customerApiClient.api.visualizations.updateVisualizationPreferenceView(
								{
									visualizationCode: visualizationCode,
									id: visualizationPreferences.id,
									req: {
										name: selectedView.name ?? '',
										options: optionPreferences,
										nodes: nodePreferences,
									},
								},
							);

						if (response?.data?.data) {
							setVisualizationPreferences(response.data.data);
							setViews(response.data.data.views ?? []);
						}
					}
				} catch {
					const errorMessage = 'Failed to create view.';
					setError(errorMessage);
					enqueueSnackbar(errorMessage, {
						variant: 'error',
					});
				}
			}
		},
		[
			customerApiClient.api.visualizations,
			enqueueSnackbar,
			nodePreferences,
			optionPreferences,
			views,
			visualizationCode,
			visualizationPreferences,
		],
	);

	const deleteView = useCallback(
		async (viewId: string) => {
			if (visualizationPreferences) {
				try {
					const selectedView = views.find((x) => x.id === viewId);
					if (selectedView) {
						const response =
							await customerApiClient.api.visualizations.deleteVisualizationPreferenceView(
								{
									visualizationCode: visualizationCode,
									id: visualizationPreferences.id,
									viewId: viewId,
								},
							);

						if (response?.data?.data) {
							setVisualizationPreferences(response.data.data);
							setViews(response.data.data.views ?? []);
						}
					}
				} catch {
					const errorMessage = 'Failed to delete view.';
					setError(errorMessage);
					enqueueSnackbar(errorMessage, {
						variant: 'error',
					});
				}
			}
		},
		[
			customerApiClient.api.visualizations,
			enqueueSnackbar,
			views,
			visualizationCode,
			visualizationPreferences,
		],
	);

	const initialize = useCallback(async () => {
		try {
			const response =
				await customerApiClient.api.visualizations.visualizationPreferences({
					visualizationCode: visualizationCode,
				});

			let nextVisualizationPreferenceOptions =
				response?.data?.data?.options ?? [];

			// todo: remove this once node state is fixed
			nextVisualizationPreferenceOptions =
				nextVisualizationPreferenceOptions.map((x) => {
					if (x.optionId === AsOfDateKey) {
						return {
							...x,
							value: new Date().toISOString(),
						};
					}
					return x;
				});

			// todo: remove this once node state is fixed
			if (
				!nextVisualizationPreferenceOptions.some(
					(x) => x.optionId === AsOfDateKey,
				)
			) {
				nextVisualizationPreferenceOptions.push({
					id: AsOfDateKey,
					optionId: AsOfDateKey,
					hide: false,
					value: new Date().toISOString(),
				});
			}

			initialState.options?.forEach((option, index) => {
				if (
					!nextVisualizationPreferenceOptions.find(
						(x) => x.optionId === option.optionId,
					)
				) {
					nextVisualizationPreferenceOptions.push({
						...option,
						id: index.toString(),
					});
				}
			});
			if (response?.data?.data) {
				setVisualizationPreferences({
					...response.data.data,
					options: nextVisualizationPreferenceOptions,
				});
				setNodePreferences(response.data.data.nodes ?? []);
				setOptionPreferences(nextVisualizationPreferenceOptions);
				setViews(response.data.data.views ?? []);
			} else {
				setVisualizationPreferences({
					id: 'none',
					options: nextVisualizationPreferenceOptions,
					nodes: [],
					views: [],
				});
				setNodePreferences([]);
				setOptionPreferences(nextVisualizationPreferenceOptions);
				setViews([]);
			}
		} catch {
			const errorMessage = 'Failed to load user visualization preferences.';
			setError(errorMessage);
			enqueueSnackbar(errorMessage, {
				variant: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	}, [
		customerApiClient.api.visualizations,
		enqueueSnackbar,
		initialState.options,
		visualizationCode,
	]);

	useEffect(() => {
		initialize();
	}, [initialize]);

	return {
		isLoading: isLoading,
		error: error,
		visualizationPreferences: visualizationPreferences,
		optionPreferences: optionPreferences,
		nodePreferences: nodePreferences,
		views: views,
		updatePreferences: updatePreferences,
		createView: createView,
		selectView: selectView,
		overwriteView: overwriteView,
		deleteView: deleteView,
	};
}
