import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
	Autocomplete,
	AutocompleteProps,
	AutocompleteValue,
	BaseTextFieldProps,
	CircularProgress,
	InputBaseProps,
	TextFieldProps,
} from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import { T4TextFieldV2 } from '../atoms/t4TextField';

export interface T4MultipleAutocompleteProps<TOption>
	extends Pick<
		AutocompleteProps<TOption, true, false, false>,
		| 'id'
		| 'value'
		| 'options'
		| 'onChange'
		| 'onBlur'
		| 'onFocus'
		| 'isOptionEqualToValue'
		| 'getOptionLabel'
		| 'renderOption'
		| 'groupBy'
		| 'renderTags'
		| 'disableClearable'
		| 'loading'
		| 'disabled'
		| 'readOnly'
		| 'ref'
	> {
	/**
	 * Textfield label.
	 */
	label?: string;

	/**
	 * @see {@link InputBaseProps.startAdornment}
	 */
	startAdornment?: InputBaseProps['startAdornment'];

	/**
	 * @see {@link BaseTextFieldProps.required}
	 */
	required?: BaseTextFieldProps['required'];

	textFieldProps?: Partial<TextFieldProps>;
}

// eslint-disable-next-line mobx/missing-observer
function T4MultipleAutocompleteInner<TOption>(
	{
		id,
		label,
		options,
		required,
		startAdornment,
		textFieldProps = {},
		...props
	}: T4MultipleAutocompleteProps<TOption>,
	ref?: ForwardedRef<unknown>,
) {
	return (
		<Autocomplete<TOption, true>
			{...props}
			ref={ref}
			multiple
			options={options}
			id={`t4-autocomplete${id ? `-${id}` : ''}`}
			data-testid="t4-autocomplete"
			renderInput={(params) => (
				<T4TextFieldV2
					{...params}
					{...textFieldProps}
					label={label}
					sx={{
						...textFieldProps?.sx,
						'& .MuiOutlinedInput-root.MuiInputBase-root': {
							paddingY: '0px',
							paddingRight: '39px',
							paddingLeft: '14px',
							'& .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon': {
								paddingRight: '65px',
							},
						},
						'& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':
							{
								padding: '0px',
								paddingY: '8.5px',
								paddingRight: '14px',
							},
					}}
					required={required}
					InputProps={{
						...textFieldProps?.InputProps,
						...params.InputProps,
						readOnly: props.readOnly,
						startAdornment: (
							<>
								{startAdornment}
								{params.InputProps.startAdornment}
							</>
						),
						endAdornment: (
							<>
								{props.loading ? (
									<CircularProgress color="inherit" size={20} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
			disableCloseOnSelect
			autoComplete
			autoHighlight
			fullWidth
			size="small"
			popupIcon={props.readOnly ? null : undefined}
		/>
	);
}
export const T4MultipleAutocomplete = forwardRef(
	T4MultipleAutocompleteInner,
) as <TOption>(
	props: T4MultipleAutocompleteProps<TOption>,
	ref?: ForwardedRef<unknown>,
) => ReturnType<typeof T4MultipleAutocompleteInner>;

export interface T4AutocompleteProps<
	TOption,
	TMultiple extends boolean = false,
	TDisableClearable extends boolean = boolean,
	TFreeSolo extends boolean = false,
> extends Omit<
		AutocompleteProps<TOption, TMultiple, TDisableClearable, TFreeSolo>,
		'onSelect' | 'renderInput'
	> {
	/**
	 * Called when an option is selected.
	 * @deprecated Use `onChange` instead.
	 */
	onSelect?: (
		value: AutocompleteValue<TOption, TMultiple, TDisableClearable, TFreeSolo>,
	) => void;

	// TEXT FIELD PROPS
	/**
	 * @see {@link BaseTextFieldProps.label}
	 */
	label?: BaseTextFieldProps['label'];

	/**
	 * @see {@link BaseTextFieldProps.required}
	 */
	required?: BaseTextFieldProps['required'];

	/**
	 * @see {@link BaseTextFieldProps.error}
	 */
	error?: BaseTextFieldProps['error'];

	/**
	 * @see {@link BaseTextFieldProps.placeHolder}
	 */
	placeholder?: BaseTextFieldProps['placeholder'];

	/**
	 * @see {@link BaseTextFieldProps.helperText}
	 */
	helperText?: BaseTextFieldProps['helperText'];

	/**
	 * @see {@link InputBaseProps.startAdornment}
	 */
	startAdornment?: InputBaseProps['startAdornment'];

	textFieldProps?: Partial<TextFieldProps>;
}

// eslint-disable-next-line mobx/missing-observer
function T4AutocompleteInner<
	TOption,
	TMultiple extends boolean = false,
	TDisableClearable extends boolean = boolean,
	TFreeSolo extends boolean = false,
>(
	{
		id,
		options,
		onSelect,
		onChange,
		label,
		required,
		error,
		placeholder,
		helperText,
		startAdornment,
		readOnly,
		fullWidth = true,
		size = 'small',
		filterSelectedOptions = true,
		blurOnSelect = true,
		autoHighlight = true,
		textFieldProps = {},
		...props
	}: T4AutocompleteProps<TOption, TMultiple, TDisableClearable, TFreeSolo>,
	ref?: ForwardedRef<any>,
) {
	return (
		<Autocomplete<TOption, TMultiple, TDisableClearable, TFreeSolo>
			{...props}
			options={options}
			id={`t4-autocomplete${id ? `-${id}` : ''}`}
			data-testid="t4-autocomplete"
			autoComplete
			readOnly={readOnly}
			renderInput={(params) => (
				<T4TextFieldV2
					inputRef={ref}
					{...params}
					{...textFieldProps}
					label={label}
					required={required}
					InputProps={{
						...textFieldProps.InputProps,
						...params.InputProps,
						readOnly: readOnly,
						startAdornment: (
							<>
								{startAdornment}
								{params.InputProps.startAdornment}
							</>
						),
						endAdornment: (
							<>
								{props.loading ? (
									<CircularProgress color="inherit" size={20} />
								) : null}
								{params?.InputProps?.endAdornment &&
									params.InputProps.endAdornment}
								{textFieldProps?.InputProps?.endAdornment &&
									textFieldProps.InputProps.endAdornment}
							</>
						),
						sx: {
							paddingRight: '14px !important',

							...(params?.InputProps as any)?.sx,
							...textFieldProps?.InputProps?.sx,
						},
					}}
					inputProps={{
						...params?.inputProps,
						style: {
							...params?.inputProps?.style,
							padding: '8.5px 16px',
							paddingLeft: '0px',
						},
					}}
					placeholder={placeholder}
					helperText={helperText}
					error={error}
				/>
			)}
			onChange={
				onChange
					? onChange
					: (_, value) => {
							onSelect?.(value);
					  }
			}
			autoHighlight={autoHighlight}
			fullWidth={fullWidth}
			size={size}
			popupIcon={readOnly ? null : <ArrowDropDownIcon />}
			sx={{
				...props.sx,
				'&.MuiAutocomplete-root .MuiInputBase-root': {
					paddingY: '0rem',
					paddingLeft: '0.75rem',
				},
				'& .MuiAutocomplete-endAdornment': {
					position: 'static',
					paddingRight: '8px',
					transform: 'none',
				},
				'& .MuiAutocomplete-clearIndicator': {
					position: 'static',
				},
			}}
		/>
	);
}
export const T4Autocomplete = forwardRef(T4AutocompleteInner) as <
	TOption,
	TMultiple extends boolean = false,
	TDisableClearable extends boolean = boolean,
	TFreeSolo extends boolean = false,
>(
	props: T4AutocompleteProps<TOption, TMultiple, TDisableClearable, TFreeSolo>,
	ref?: ForwardedRef<any>,
) => ReturnType<typeof T4AutocompleteInner>;
