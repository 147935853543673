import {
	useT4Query,
	UseT4QueryProps,
} from 'features/cash4/_shared/_utilities/useT4Query';
import { MoneyMarket } from 'modules/clients/customer-api/src/api/administration';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseMoneyMarketsQueryProps = UseT4QueryProps<MoneyMarket[]> & {
	data: MoneyMarket[];
};

export function useMoneyMarketsQuery(): UseMoneyMarketsQueryProps {
	const { customerApiClient } = useClients();

	const getMoneyMarkets = useCallback(async () => {
		const response = await customerApiClient.api.administration.moneyMarkets();

		return response.data?.data;
	}, [customerApiClient.api.administration]);
	const queryContext = useT4Query<MoneyMarket[]>(getMoneyMarkets, {
		errorMessage: 'Unable to load money markets.',
	});

	return {
		...queryContext,
		data: queryContext.data ?? [],
	};
}
