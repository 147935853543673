import { DeleteOutlined } from '@mui/icons-material';
import {
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuItemProps,
} from '@mui/material';
import { FC, forwardRef } from 'react';

export type DeleteMenuItemProps = Omit<MenuItemProps, 'color'> & {
	text?: string;
};

export const DeleteMenuItem: FC<DeleteMenuItemProps> = forwardRef(
	({ text, ...rest }, ref) => (
		<MenuItem {...rest} ref={ref} color="error">
			<ListItemIcon sx={(theme) => ({ color: theme.palette.error.main })}>
				<DeleteOutlined />
			</ListItemIcon>
			<ListItemText
				sx={(theme) => ({
					'& .MuiTypography-root': { color: theme.palette.error.main },
				})}
			>
				{text ?? 'Delete'}
			</ListItemText>
		</MenuItem>
	),
);
