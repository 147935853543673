/* eslint-disable mobx/missing-observer */
import * as go from 'gojs';

import {
	GraphRendererBase,
	T4DiagramProps,
} from 'features/entity4/visualizations/_shared/_components/graphRendererBase';
import { getSvgIconForAccountType } from '../_utilities/getSvgIcon';
import {
	AccountPurposeColors,
	AccountStatusColors,
	RegionColors,
} from '../models/accountMapTypes';

export class AccountMapRendererBase extends GraphRendererBase {
	/** @internal */
	constructor(props: T4DiagramProps) {
		super(props);
		this.createNodeTemplate = this.createNodeTemplate.bind(this);
		this.createLegendNode = this.createLegendNode.bind(this);
	}

	public createNodeTemplate(
		addMouseEvents: boolean,
		treeExpanderBuilder: string | null,
		isFieldVisible: (property: string) => boolean,
	): go.Part {
		const createSubHeader = () => {
			return (
				new go.Panel('Table', {
					margin: this._tableTopMargin,
					defaultSeparatorPadding: this._tableSeparatorPadding,
					stretch: go.Stretch.Horizontal,
				})
					//.addRowDefinition(0, { height: 8 })
					.add(
						go.GraphObject.make(
							go.TextBlock,
							{
								name: 'accountCodeURL',
								alignment: go.Spot.Left,
								row: 0,
								column: 0,
								columnSpan: 2,
								width: 300,
								wrap: go.Wrap.Fit,
								isUnderline: true,
								cursor: 'pointer',
								stroke: this._textColor,
								click: (_: any, obj: any) => {
									if (obj.part.data.isSubaccount) {
										window.open(
											'accounts/' +
												obj.part.data.parentEntityId +
												'/subaccounts/' +
												obj.part.data.entityId,
										);
									} else
										window.open(
											'accounts/' + obj.part.data.entityId + '/information',
										);
								},
							},
							new go.Binding('text', 'accountCode'),
						),
						go.GraphObject.make(
							go.TextBlock,
							{
								alignment: go.Spot.Left,
								stretch: go.Stretch.Horizontal,
								row: 1,
								column: 0,
								stroke: this._textColor,
								width: 300,
								wrap: go.Wrap.Fit,
							},
							new go.Binding('text', '', (data) =>
								data.purpose ? data.purpose : '-',
							),
						),
						go.GraphObject.make(
							//https://gojs.net/latest/samples/icons.html
							go.Picture,
							{
								alignment: go.Spot.TopRight,
								width: 16,
								height: 16,
								column: 2,
								row: 1,
							},
							new go.Binding('source', 'accountType', getSvgIconForAccountType),
							{
								toolTip: go.GraphObject.make(
									'ToolTip',
									go.GraphObject.make(
										go.TextBlock,
										{ margin: 4 },
										new go.Binding('text', 'accountType'),
									),
								),
							},
							new go.Binding('visible', '', (data) => {
								return data.isAccountTypeVisible ?? false;
							}),
						),
						go.GraphObject.make(
							//https://gojs.net/latest/samples/icons.html
							go.Picture,
							{
								alignment: go.Spot.TopRight,
								column: 3,
								row: 1,
								width: 16,
								height: 16,
								source: getSvgIconForAccountType('public'),
							},
							new go.Binding('visible', '', (data) => {
								return data.isForeignAccount && data.isAccountTypeVisible;
							}),
							{
								toolTip: go.GraphObject.make(
									'ToolTip',
									go.GraphObject.make(
										go.TextBlock,
										{ margin: 4 },
										'Foreign Account',
									),
								),
							},
						),
					)
			);
		};

		const createSubHeader2 = () => {
			const subHeader2TitleFont = '14px Roboto, Medium';
			const subHeader2TitleColor = '#6F7172';
			const subHeader2ItemFont = '14px Roboto, Regular';
			const subHeader2ItemColor = '#404142';

			return new go.Panel('Table', {
				margin: this._tableTopMargin,
				defaultSeparatorPadding: this._tableSeparatorPadding,
				stretch: go.Stretch.Horizontal,
				background: 'white',
			}).add(
				new go.TextBlock({
					text: 'Counterparty',
					column: 0,
					font: subHeader2TitleFont,
					stroke: subHeader2TitleColor,
				}),
				new go.TextBlock({
					row: 1,
					column: 0,
					font: subHeader2ItemFont,
					stroke: subHeader2ItemColor,
				}).bind(
					new go.Binding('text', '', function (data) {
						return data.counterparty || '-';
					}),
				),
				new go.TextBlock('Acct Currency', {
					column: 1,
					font: subHeader2TitleFont,
					stroke: subHeader2TitleColor,
				}),
				new go.TextBlock({
					row: 1,
					column: 1,
					font: subHeader2ItemFont,
					stroke: subHeader2ItemColor,
				}).bind(
					new go.Binding('text', '', function (data) {
						return data.accountCurrencyCode || '-';
					}),
				),
				new go.TextBlock('Acct Number', {
					column: 2,
					font: subHeader2TitleFont,
					stroke: subHeader2TitleColor,
				}),
				new go.TextBlock({
					row: 1,
					column: 2,
					font: subHeader2ItemFont,
					stroke: subHeader2ItemColor,
				}).bind(
					new go.Binding('text', '', function (data) {
						if (data.naturalAccountNumber === 'x') {
							//just one 'x' means no account number
							return '-';
						}
						return data.naturalAccountNumber || '-';
					}),
				),
			);
		};

		const createMainTable = () => {
			let row = 0;

			return [
				new go.Panel('Table', {
					margin: this._tableTopMargin,
					defaultSeparatorPadding: this._tableSeparatorPadding,
					alignment: go.Spot.Left,
				}).add(
					this.createTableRow(
						row++,
						'Account Status',
						isFieldVisible('accountStatus'),
						'accountStatus',
					),
					this.createTableRow(
						row++,
						'Open Date',
						isFieldVisible('openDate'),
						'openDate',
					),
					this.createTableRow(
						row++,
						'Close Date',
						isFieldVisible('closeDate'),
						'closeDate',
					),
					this.createTableRow(
						row++,
						'Account Type',
						isFieldVisible('accountType'),
						'accountType',
					),
					this.createTableRow(
						row++,
						'Account Purpose',
						isFieldVisible('purpose'),
						'purpose',
					),
					this.createTableRow(
						row++,
						'Counterparty Branch',
						isFieldVisible('counterpartyBranch'),
						'counterpartyBranch',
					),
					this.createTableRow(
						row++,
						'Entity Region',
						isFieldVisible('entityRegion'),
						'entityRegion',
					),
					this.createTableRow(
						row++,
						'ERP Code',
						isFieldVisible('entityErpCode'),
						'entityErpCode',
					),
					this.createTableRow(
						row++,
						'GL Number',
						isFieldVisible('generalLedgerAccountNumber'),
						'generalLedgerAccountNumber',
					),
				),
			];
		};

		return this.createNodeTemplateBase(
			addMouseEvents,
			'entityName',
			createSubHeader,
			createSubHeader2,
			createMainTable,
			treeExpanderBuilder,
			'accounts',
		);
	}

	public createLegendNode(diagram: go.Diagram) {
		let headerFont = 'Bold 28pt Roboto,Helvetica,Arial,sans-serif';
		let itemFont = '28pt Roboto,Helvetica,Arial,sans-serif';
		let headerMargin = new go.Margin(8, 0, 8, 8);
		let panelMargin = new go.Margin(0, 0, 16, 0);
		let spaceBetweenShapeAndText = new go.Margin(8, 8, 0, 8);
		let marginAroundTextItems = new go.Margin(0, 8, 0, 8);

		diagram.nodeTemplateMap.add(
			'LegendNode',
			go.GraphObject.make(
				go.Node,
				'Auto',
				{
					copyable: false,
					deletable: false,
					name: 'LegendNode',
				},
				go.GraphObject.make(
					go.Panel,
					'Vertical',
					{
						stretch: go.GraphObject.Horizontal,
						//defaultSeparatorPadding: new go.Margin(0, 200, 0, 0),
					},
					go.GraphObject.make(go.Shape, 'LineH', {
						stretch: go.Stretch.Horizontal,
						height: 1,
						margin: this._lineSeparatorMargin,
						stroke: '#e2e2e3',
						strokeWidth: 2,
					}),
					go.GraphObject.make(
						go.TextBlock,
						'Connectors',
						{
							font: headerFont,
							alignment: go.Spot.TopLeft,
							margin: headerMargin,
							stroke: this._textColor,
						},
						new go.Binding('visible', 'hideConnectors', function (data) {
							return !(data ?? false);
						}),
					),
					go.GraphObject.make(
						go.Panel,
						'Vertical',
						{ alignment: go.Spot.TopLeft },
						go.GraphObject.make(
							go.Panel,
							'Horizontal',
							{
								alignment: go.Spot.TopLeft,
								margin: panelMargin,
							},
							new go.Binding('visible', '', function (data) {
								return data.hasAutomaticLink && !data.hideConnectors;
							}),
							go.GraphObject.make(go.Shape, 'LineH', {
								width: 40,
								height: 1,
								stroke: 'black',
								strokeWidth: this._connectorStrokeWidth,
							}),
							go.GraphObject.make(go.TextBlock, {
								font: itemFont,
								text: 'Automatic Funding',
								stroke: this._textColor,
								margin: spaceBetweenShapeAndText,
							}),
						),
						go.GraphObject.make(
							go.Panel,
							'Horizontal',
							{
								alignment: go.Spot.TopLeft,
								margin: panelMargin,
							},
							new go.Binding('visible', '', function (data) {
								return data.hasManualLink && !data.hideConnectors;
							}),
							go.GraphObject.make(go.Shape, 'LineH', {
								width: 40,
								height: 1,
								stroke: 'black',
								strokeWidth: this._connectorStrokeWidth,
								strokeDashArray: [4, 2],
							}),
							go.GraphObject.make(go.TextBlock, {
								font: itemFont,
								text: 'Manual Funding',
								stroke: this._textColor,
								margin: spaceBetweenShapeAndText,
							}),
						),
						go.GraphObject.make(
							go.Panel,
							'Horizontal',
							{
								alignment: go.Spot.TopLeft,
								margin: panelMargin,
							},
							new go.Binding('visible', '', function (data) {
								return data.hasOneWayLink && !data.hideConnectors;
							}),
							go.GraphObject.make(go.Shape, 'LineH', {
								width: 35,
								height: 1,
								stroke: 'black',
								strokeWidth: this._connectorStrokeWidth,
							}),
							go.GraphObject.make(go.Shape, { fromArrow: 'Standard' }),
							go.GraphObject.make(go.TextBlock, {
								font: itemFont,
								text: 'One-Way Funding',
								stroke: this._textColor,
								margin: spaceBetweenShapeAndText,
							}),
						),
						go.GraphObject.make(
							go.Panel,
							'Horizontal',
							{
								alignment: go.Spot.TopLeft,
								margin: panelMargin,
							},
							new go.Binding('visible', '', function (data) {
								return data.hasTwoWayLink && !data.hideConnectors;
							}),
							go.GraphObject.make(go.Shape, { toArrow: 'Backward' }),
							go.GraphObject.make(go.Shape, 'LineH', {
								width: 30,
								height: 1,
								stroke: 'black',
								strokeWidth: this._connectorStrokeWidth,
							}),
							go.GraphObject.make(go.Shape, { fromArrow: 'Standard' }),
							go.GraphObject.make(go.TextBlock, {
								font: itemFont,
								text: 'Two-Way Funding',
								stroke: this._textColor,
								margin: spaceBetweenShapeAndText,
							}),
						),
						go.GraphObject.make(
							go.Panel,
							'Horizontal',
							{ alignment: go.Spot.TopLeft },
							new go.Binding('visible', '', function (data) {
								return data.hasSubaccount && !data.hideConnectors;
							}),
							go.GraphObject.make(go.Shape, 'LineH', {
								width: 50,
								height: 1,
								stroke: '#F6802C',
								strokeWidth: this._connectorStrokeWidth,
								strokeDashArray: [5, this._connectorStrokeWidth],
							}),
							go.GraphObject.make(go.TextBlock, {
								font: itemFont,
								text: 'Subaccount',
								stroke: this._textColor,
								margin: spaceBetweenShapeAndText,
							}),
						),
					),
					go.GraphObject.make(
						go.Shape,
						'LineH',
						{
							stretch: go.Stretch.Horizontal,
							height: 1,
							margin: this._lineSeparatorMargin,
							stroke: '#e2e2e3',
							strokeWidth: 2,
						},
						new go.Binding('visible', 'hideConnectors', function (data) {
							return !(data ?? false);
						}),
					),
					go.GraphObject.make(
						go.TextBlock,
						'Account Type',
						{
							font: headerFont,
							alignment: go.Spot.TopLeft,
							margin: headerMargin,
							stroke: this._textColor,
						},
						new go.Binding('visible', '', (data) => {
							return data.isAccountTypeVisible ?? false;
						}),
					),
					go.GraphObject.make(
						go.Panel,
						'Vertical',
						{ alignment: go.Spot.TopLeft },
						new go.Binding('visible', '', (data) => {
							return data.isAccountTypeVisible ?? false;
						}),
						new go.Binding('itemArray', 'accountTypes'),
						{
							itemTemplate: go.GraphObject.make(
								go.Panel,
								'Horizontal',
								{ alignment: go.Spot.Left, margin: panelMargin },
								go.GraphObject.make(
									go.Picture,
									{
										margin: marginAroundTextItems,
										alignment: go.Spot.Left,
										width: 24,
										height: 24,
									},
									new go.Binding('source', '', (val) =>
										getSvgIconForAccountType(val),
									),
								),
								go.GraphObject.make(
									go.TextBlock,
									{
										font: itemFont,
										margin: spaceBetweenShapeAndText,
										stroke: this._textColor,
									},
									new go.Binding('text', ''),
								),
							),
						},
					),
					go.GraphObject.make(
						go.Shape,
						'LineH',
						{
							stretch: go.Stretch.Horizontal,
							height: 1,
							stroke: '#e2e2e3',
							margin: this._lineSeparatorMargin,
							strokeWidth: 2,
						},
						new go.Binding('visible', '', (data) => {
							return data.isAccountTypeVisible ?? false;
						}),
					),
					go.GraphObject.make(
						go.TextBlock,
						'Entity Region',
						{
							font: headerFont,
							stroke: this._textColor,
							alignment: go.Spot.TopLeft,
							margin: headerMargin,
							name: 'LegendEntityRegionHeader',
						},
						new go.Binding('visible', '', (data) => {
							return data.isEntityRegionVisible ?? false;
						}),
					),
					go.GraphObject.make(
						go.Panel,
						'Vertical',
						{
							alignment: go.Spot.TopLeft,
							name: 'LegendEntityRegion',
						},
						new go.Binding('itemArray', 'entityRegions'),
						new go.Binding('visible', '', (data) => {
							return data.isEntityRegionVisible ?? false;
						}),
						{
							itemTemplate: go.GraphObject.make(
								go.Panel,
								'Horizontal',
								{ alignment: go.Spot.Left, margin: panelMargin },
								go.GraphObject.make(
									go.Shape,
									{
										margin: marginAroundTextItems,
										alignment: go.Spot.Left,
										strokeWidth: 0,
										width: 24,
										height: 24,
									},
									new go.Binding('fill', '', (val) => {
										return RegionColors.get(val) || null;
									}),
								),
								go.GraphObject.make(
									go.TextBlock,
									{
										font: itemFont,
										margin: spaceBetweenShapeAndText,
										stroke: this._textColor,
									},
									new go.Binding('text', ''),
								),
							),
						},
					),
					go.GraphObject.make(
						go.Shape,
						'LineH',
						{
							stretch: go.Stretch.Horizontal,
							height: 1,
							stroke: '#e2e2e3',
							margin: this._lineSeparatorMargin,
							strokeWidth: 2,
						},
						new go.Binding('visible', '', (data) => {
							return data.isEntityRegionVisible ?? false;
						}),
					),
					go.GraphObject.make(
						go.TextBlock,
						'Account Status',
						{
							font: headerFont,
							stroke: this._textColor,
							alignment: go.Spot.TopLeft,
							margin: headerMargin,
							name: 'LegendAccountStatusHeader',
						},
						new go.Binding('visible', '', (data) => {
							return data.isAccountStatusVisible ?? false;
						}),
					),
					go.GraphObject.make(
						go.Panel,
						'Vertical',
						{
							alignment: go.Spot.TopLeft,
							name: 'LegendAccountStatus',
						},
						new go.Binding('visible', '', (data) => {
							return data.isAccountStatusVisible ?? false;
						}),
						new go.Binding('itemArray', 'accountStatuses'),
						{
							itemTemplate: go.GraphObject.make(
								go.Panel,
								'Horizontal',
								{ alignment: go.Spot.Left, margin: panelMargin },
								go.GraphObject.make(
									go.Shape,
									{
										margin: marginAroundTextItems,
										alignment: go.Spot.Left,
										strokeWidth: 0,
										width: 24,
										height: 24,
									},
									new go.Binding('fill', '', (val) => {
										return AccountStatusColors.get(val) || null;
									}),
								),
								go.GraphObject.make(
									go.TextBlock,
									{
										font: itemFont,
										stroke: this._textColor,
										margin: spaceBetweenShapeAndText,
									},
									new go.Binding('text', ''),
								),
							),
						},
					),
					go.GraphObject.make(
						go.Shape,
						'LineH',
						{
							stretch: go.Stretch.Horizontal,
							height: 1,
							stroke: '#e2e2e3',
							margin: this._lineSeparatorMargin,
							strokeWidth: 2,
						},
						new go.Binding('visible', '', (data) => {
							return data.isAccountStatusVisible ?? false;
						}),
					),
					go.GraphObject.make(
						go.TextBlock,
						'Account Purpose',
						{
							font: headerFont,
							stroke: this._textColor,
							alignment: go.Spot.TopLeft,
							margin: headerMargin,
							name: 'LegendAccountPurposeHeader',
						},
						new go.Binding('visible', '', (data) => {
							return data.isAccountPurposeVisible ?? false;
						}),
					),
					go.GraphObject.make(
						go.Panel,
						'Vertical',
						{
							alignment: go.Spot.TopLeft,
							name: 'LegendAccountPurpose',
						},
						new go.Binding('visible', '', (data) => {
							return data.isAccountPurposeVisible ?? false;
						}),
						new go.Binding('itemArray', 'accountPurposes'),
						{
							itemTemplate: go.GraphObject.make(
								go.Panel,
								'Horizontal',
								{ alignment: go.Spot.Left, margin: panelMargin },
								go.GraphObject.make(
									go.Shape,
									{
										margin: marginAroundTextItems,
										alignment: go.Spot.Left,
										strokeWidth: 0,
										width: 24,
										height: 24,
									},
									new go.Binding('fill', '', (val) =>
										AccountPurposeColors.get(val),
									),
								),
								go.GraphObject.make(
									go.TextBlock,
									{
										font: itemFont,
										stroke: this._textColor,
										margin: spaceBetweenShapeAndText,
									},
									new go.Binding('text', ''),
								),
							),
						},
					),
					go.GraphObject.make(
						go.Shape,
						'LineH',
						{
							stretch: go.Stretch.Horizontal,
							height: 1,
							stroke: '#e2e2e3',
							margin: this._lineSeparatorMargin,
							strokeWidth: 2,
						},
						new go.Binding('visible', '', (data) => {
							return data.isAccountPurposeVisible ?? false;
						}),
					),
					go.GraphObject.make(
						go.TextBlock,
						'Additional Visuals',
						{
							font: headerFont,
							stroke: this._textColor,
							alignment: go.Spot.TopLeft,
							margin: headerMargin,
						},
						new go.Binding('visible', '', (data) => {
							return data.hasForeignAccount && data.isAccountTypeVisible;
						}),
					),
					go.GraphObject.make(
						go.Panel,
						'Vertical',
						{ alignment: go.Spot.TopLeft },
						go.GraphObject.make(
							go.Panel,
							'Horizontal',
							{ alignment: go.Spot.Left, margin: panelMargin },
							new go.Binding('visible', '', (data) => {
								return data.hasForeignAccount && data.isAccountTypeVisible;
							}),
							go.GraphObject.make(
								go.Picture,
								{
									margin: marginAroundTextItems,
									alignment: go.Spot.Left,
								},
								new go.Binding('source', '', (val) =>
									getSvgIconForAccountType('public'),
								),
							),
							go.GraphObject.make(go.TextBlock, {
								font: itemFont,
								stroke: this._textColor,
								margin: spaceBetweenShapeAndText,
								text: 'Foreign Account',
							}),
						),
					),
				),
			),
		);
	}
}
