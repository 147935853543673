import { Transaction } from 'modules/clients/customer-api/src/transactions';
import { formatDateSimpleUpperCase } from 'shared/utilities/dateUtilities';
import { formatCurrency } from 'utilities/currencyUtils';
import { Balance } from '../../balances/balancesViewModel';
import { TransactionListItem } from '../models';

export const formattedExchangeRate = (number: number | null | undefined) => {
	if (number === null) return null;
	if (number === undefined) return null;
	return number.toFixed(8);
};

export const indentSubsequentLines = (
	indent: string,
	content: string | undefined,
): string => {
	if (!content) return '';
	return content?.replace(/^(?!\s*$)/gm, (match, index) => {
		return `${index > 0 ? indent : ''}${match}`;
	});
};

export const replaceEmpty = (content: string | null): string => {
	if (!content || content.trim() === '') {
		return '-';
	} else {
		return content;
	}
};

export const getTransactionCopyContentValue = (
	transaction: TransactionListItem,
	reportingCurrency: string | null,
) => {
	return (
		`Transaction Record\n\n` +
		`Account Name:               ${replaceEmpty(transaction.e4AccountName)}\n` +
		`Counterparty Code:          ${replaceEmpty(transaction.bankCode)}\n\n` +
		`Account:                    ${replaceEmpty(
			transaction.e4AccountNumber,
		)}\n` +
		`Cash4 Account:              ${replaceEmpty(
			transaction.c4AccountNumber,
		)}\n` +
		`Counterparty:               ${replaceEmpty(transaction.bankName)}\n` +
		`Transaction Date:           ${replaceEmpty(
			formatDateSimpleUpperCase(transaction.date),
		)}\n\n` +
		`Transaction Code:           ${replaceEmpty(
			transaction.transactionCode,
		)}\n` +
		`Customer Reference:         ${replaceEmpty(
			transaction.customerReference,
		)}\n` +
		`Bank Reference:             ${replaceEmpty(transaction.bankReference)}\n` +
		`Check Number:               ${replaceEmpty(transaction.checkNumber)}\n` +
		`Transaction Detail:         ${replaceEmpty(
			indentSubsequentLines('                      ', transaction.detail),
		)}\n\n` +
		`Amount:                     ${
			transaction.number.value !== undefined
				? `${formatCurrency(transaction.number.value, {
						currency: transaction.currency!,
				  })} ${transaction.currency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:       ${
			transaction.number.reportingValue !== undefined
				? `${formatCurrency(transaction.number.reportingValue, {
						currency: reportingCurrency!,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		`Foreign Exchange (FX) Rate: ${
			transaction.number.reportingCurrencyRate !== undefined
				? `${formattedExchangeRate(transaction.number.reportingCurrencyRate)}`
				: `-`
		}\n` +
		`Foreign Exchange (FX) Date: ${
			transaction.number.reportingCurrencyEffectiveDate !== undefined
				? `${formatDateSimpleUpperCase(
						transaction.number.reportingCurrencyEffectiveDate,
				  )}`
				: `-`
		}\n\n` +
		`Notes:\n${replaceEmpty(transaction.noteContent)}`
	);
};

export const getBalanceCopyContentValue = (
	balance: Balance,
	reportingCurrency: string,
) => {
	if (!balance) {
		return '';
	}

	return (
		`Balance Record\n\n` +
		`Account Code:                 ${replaceEmpty(balance.e4AccountName)}\n` +
		`Counterparty Code:            ${replaceEmpty(balance.bankCode)}\n\n` +
		`Account:                      ${replaceEmpty(balance.e4AccountNumber)}\n` +
		`Cash4 Account:                ${replaceEmpty(balance.c4AccountNumber)}\n` +
		`Counterparty:                 ${replaceEmpty(balance.bankName)}\n` +
		`Balance Date:                 ${replaceEmpty(
			formatDateSimpleUpperCase(balance.statementDate),
		)}\n` +
		'\n' +
		`Opening Ledger Balance:       ${
			balance.openingLedgerBalance.value !== undefined
				? `${formatCurrency(balance.openingLedgerBalance.value, {
						currency: balance.openingLedgerBalance.valueCurrency!,
				  })} ${balance.openingLedgerBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.openingLedgerBalance.reportingValue !== undefined
				? `${formatCurrency(balance.openingLedgerBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Opening Available Balance:    ${
			balance.openingAvailableBalance.value !== undefined
				? `${formatCurrency(balance.openingAvailableBalance.value, {
						currency: balance.openingAvailableBalance.valueCurrency!,
				  })} ${balance.openingAvailableBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.openingAvailableBalance.reportingValue !== undefined
				? `${formatCurrency(balance.openingAvailableBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Closing Ledger Balance:       ${
			balance.closingLedgerBalance.value !== undefined
				? `${formatCurrency(balance.closingLedgerBalance.value, {
						currency: balance.closingLedgerBalance.valueCurrency!,
				  })} ${balance.closingLedgerBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.closingLedgerBalance.reportingValue !== undefined
				? `${formatCurrency(balance.closingLedgerBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Closing Available Balance:    ${
			balance.closingAvailableBalance.value !== undefined
				? `${formatCurrency(balance.closingAvailableBalance.value, {
						currency: balance.closingAvailableBalance.valueCurrency!,
				  })} ${balance.closingAvailableBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.closingAvailableBalance.reportingValue !== undefined
				? `${formatCurrency(balance.closingAvailableBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Foreign Exchange (FX) Rate:   ${
			balance.foreignExchangeRate
				? `${formattedExchangeRate(balance.foreignExchangeRate)}`
				: `-`
		}\n` +
		`Foreign Exchange (FX) Date:   ${
			balance.foreignExchangeDate
				? `${formatDateSimpleUpperCase(balance.foreignExchangeDate)}`
				: `-`
		}\n` +
		'\n' +
		`Notes:\n${replaceEmpty(balance.note)}`
	);
};

export const getTransactionListItem = (
	transaction: Transaction,
): TransactionListItem => ({
	id: transaction.id,
	date: transaction.date,
	c4AccountNumber: transaction.c4AccountNumber,
	e4AccountNumber: transaction.e4AccountNumber,
	e4AccountName: transaction.e4AccountName,
	e4AccountId: transaction.e4AccountId,
	transactionCode: transaction.transactionCode,
	bankReference: transaction.bankReference,
	customerReference: transaction.customerReference,
	checkNumber: transaction.checkNumber,
	detail: transaction.detail,
	cfc: transaction.cfc,
	cfcName: transaction.cfcName,
	cft: transaction.cft,
	cftName: transaction.cftName,
	cfst: transaction.cfst,
	cfstName: transaction.cfstName,
	glNumber: transaction.glNumber,
	number: {
		value: transaction.number,
		reportingValue: transaction.number_ReportingCurrency,
		reportingCurrencyRate: transaction.reportingCurrency_Rate,
		reportingCurrencyEffectiveDate: transaction.reportingCurrency_EffectiveDate,
	},
	noteContent: transaction.noteContent,
	currency: transaction.currency,
	bankName: transaction.bankName,
	bankCode: transaction.bankCode,
	importedDate: transaction.transactionImportedDate,
	transactionRuleId: transaction.transactionRuleId,
	transactionRuleName: transaction.transactionRuleName,
	fiTransactionId: transaction.fI_TransactionId,
	reconciliationRecordId: transaction.reconciliationRecordId,
	reconciliationStatus: transaction.reconciliationStatus,
	isForecastModelExcluded: transaction.isForecastModelExcluded,
});

export const stonlyIds = {
	// Reported Transactions
	transactionsDrawer: 'cash4-transactions-drawer',
	transactionsRowContextMenu: 'cash4-transactions-row-context-menu',
	transactionsRowContextMenuView: 'cash4-transactions-row-context-menu-view',
	transactionsRowContextMenuDelete:
		'cash4-transactions-row-context-menu-delete',
	transactionsDeleteConfirmationModal:
		'cash4-transactions-delete-confirmation-modal',
	transactionsDeleteConfirmationCancelButton:
		'cash4-transactions-delete-confirmation-cancel',
	transactionsDeleteConfirmationDeleteButton:
		'cash4-transactions-delete-confirmation-delete',

	// Projected Transactions
	projectedTransactionsGrid: 'cash4-projected-transactions-grid',
	projectedTransactionsDrawer: 'cash4-projected-transactions-drawer',
	projectedTransactionsRowContextMenu:
		'cash4-projected-transactions-row-context-menu',
	projectedTransactionsRowContextMenuView:
		'cash4-projected-transactions-row-context-menu-view',
	projectedTransactionsRowContextMenuDelete:
		'cash4-projected-transactions-row-context-menu-delete',
	projectedTransactionsDeleteConfirmationModal:
		'cash4-projected-transactions-delete-confirmation-modal',
	projectedTransactionsDeleteConfirmationCancelButton:
		'cash4-projected-transactions-delete-confirmation-cancel',
	projectedTransactionsDeleteConfirmationDeleteButton:
		'cash4-projected-transactions-delete-confirmation-delete',
	projectedTransactionsCreateButton:
		'cash4-transactions-create-projected-item-button',
};
