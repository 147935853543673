import { Grid, Typography } from '@mui/material';
import { useAdminViewMode } from 'features/administration/_shared/_hooks/useAdminViewMode';
import { AdminViewModes } from 'features/administration/providers/contexts/adminViewModeContext';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { T4FieldAdornment } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { T4Section } from 'shared/components/t4Section';
import { T4View } from 'shared/components/t4View';
import { useGroupDetails } from './useGroupDetails';

export const GroupDetails: FC = observer(() => {
	const { viewMode } = useAdminViewMode();
	const {
		isLoading,
		loadingError,
		name,
		description,
		setName,
		setDescription,
		getFieldAdornment,
		getFieldErrors,
		resetFieldAdornments,
		load,
		update,
	} = useGroupDetails();

	useEffect(() => {
		load();
	}, [load]);

	useEffect(() => {
		if (viewMode === AdminViewModes.default) resetFieldAdornments();
	}, [viewMode, resetFieldAdornments]);

	const readOnly = viewMode === AdminViewModes.default;

	return (
		<T4View loading={isLoading} disablePadding>
			<T4Section error={loadingError}>
				<Grid container sx={{ rowGap: 1.5 }}>
					<Grid item xs={12}>
						<Typography variant="h3">Security Group Details</Typography>
					</Grid>
					<Grid item xs={12}>
						<T4TextFieldV2
							id="group-name"
							label="Name"
							required
							error={getFieldErrors('name').length > 0}
							value={name}
							onChange={setName}
							onBlur={async () => await update('name')}
							startAdornment={
								<T4FieldAdornment t4AdornmentType={getFieldAdornment('name')} />
							}
							InputProps={{
								readOnly: readOnly,
							}}
						/>
						<T4AlertStack errors={getFieldErrors('name')} />
					</Grid>
					<Grid item xs={12}>
						<T4TextFieldV2
							id="group-description"
							label="Description"
							multiline
							maxRows={3}
							minRows={3}
							error={getFieldErrors('description').length > 0}
							value={description ?? ''}
							onChange={setDescription}
							onBlur={async () => await update('description')}
							startAdornment={
								<T4FieldAdornment
									t4AdornmentType={getFieldAdornment('description')}
								/>
							}
							InputProps={{
								readOnly: readOnly,
							}}
						/>
						<T4AlertStack errors={getFieldErrors('description')} />
					</Grid>
				</Grid>
			</T4Section>
		</T4View>
	);
});
