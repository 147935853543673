import {
	GetProjectedItemsRequest,
	ProjectedItem,
} from 'modules/clients/customer-api/src/api/cash4';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useT4Parameter } from '../_shared/_utilities/useT4Parameter';
import { useT4Query, UseT4QueryProps } from '../_shared/_utilities/useT4Query';

export type UseProjectedItemsQueryProps = Pick<
	UseT4QueryProps<ProjectedItem[]>,
	'loading' | 'refetch'
> & {
	data: ProjectedItem[];
};

export function useProjectedItemsQuery(
	request?: GetProjectedItemsRequest,
): UseProjectedItemsQueryProps {
	const { customerApiClient } = useClients();
	const { property: requestActual } = useT4Parameter(request);

	const query = useCallback(async () => {
		const response = await customerApiClient.api.cash4.projected(requestActual);

		return response?.data?.data || [];
	}, [customerApiClient.api.cash4, requestActual]);

	const { loading, data, refetch } = useT4Query(query);

	return {
		loading: loading,
		data: data ?? [],
		refetch: refetch,
	};
}
