import { Alert, useTheme } from '@mui/material';
import {
	DataGridPro,
	DataGridProProps,
	GridCsvExportOptions,
	GridExportDisplayOptions,
	GridOverlay,
	GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { TableCustomViewPreferences } from 'modules/clients/customer-api/src/userPreference';
import { ReactNode } from 'react';
import { stonlyData } from 'stonly/functions';
import { T4Toolbar } from './t4Toolbar';

export type T4DataGridProps<TData extends GridValidRowModel> = Omit<
	DataGridProProps<TData>,
	'onStateChange'
> & {
	/**
	 * Stonly Id which allows for stonly walkthroughs
	 */
	stonlyId: string;

	/**
	 * Error message to display in no-rows overlay
	 */
	// TODO: right now this is not being used...we need to figure out what the error overlay looks like
	errorMessage?: string;

	/**
	 * Empty message to display in no-rows overlay
	 */
	emptyMessage?: string;

	/**
	 * To show or hide the grid toolbar
	 */
	showToolbar?: boolean;

	/**
	 * Function to handle when table density changes
	 */
	onDensityChange?: (state: any) => void;

	csvOptions?: GridCsvExportOptions & GridExportDisplayOptions;

	customViewButton?: ReactNode;

	selectedCustomView?: TableCustomViewPreferences;
};

export const T4DataGrid = observer(function T4DataGrid<
	TData extends GridValidRowModel,
>({
	stonlyId,
	errorMessage,
	emptyMessage,
	onDensityChange,
	density = 'compact',
	filterDebounceMs = 500,
	disableVirtualization = false,
	showToolbar = false,
	hideFooter = true,
	csvOptions,
	customViewButton,
	slots,
	...rest
}: T4DataGridProps<TData>) {
	const theme = useTheme();

	return (
		<DataGridPro<TData>
			{...rest}
			hideFooter={hideFooter}
			filterDebounceMs={filterDebounceMs}
			density={density}
			disableVirtualization={disableVirtualization}
			onStateChange={(state) => {
				if (onDensityChange) {
					onDensityChange(state);
				}
			}}
			slotProps={{
				...rest.slotProps,
				toolbar: {
					...rest.slotProps?.toolbar,
					showQuickFilter: true,
					customViewButton: customViewButton,
					quickFilterProps: {
						...rest.slotProps?.toolbar?.quickFilterProps,
						variant: 'outlined',
						size: 'small',
					},
					csvOptions: csvOptions,
					stonlyId: stonlyId,
				},

				columnsPanel: {
					...rest.slotProps?.columnsPanel,
					getTogglableColumns: () =>
						rest.columns
							.filter((col) => col.hideable !== false)
							.map((col) => col.field),
				},
			}}
			slots={{
				toolbar: showToolbar ? T4Toolbar : undefined,
				noRowsOverlay:
					slots?.noRowsOverlay === undefined
						? () => (
								<GridOverlay>
									<Alert
										id="data-grid-empty"
										data-testid="data-grid-empty"
										severity="warning"
										variant="outlined"
										{...(stonlyData({
											id: `${stonlyId ? stonlyId + '-' : ''}no-rows-alert`,
										}) as any)}
									>
										{emptyMessage ?? 'No rows'}
									</Alert>
								</GridOverlay>
						  )
						: slots.noRowsOverlay,
				...slots,
			}}
			sx={{
				...rest.sx,
				'& .MuiDataGrid-pinnedColumnHeaders': {
					boxShadow: 'none',
				},
				'& .MuiDataGrid-pinnedColumns': {
					boxShadow: 'none',
				},
				'& .MuiDataGrid-cell.disabled': {
					color: theme.palette.grey[400],
				},
			}}
			data-stonlyid={`${stonlyId}-table`}
		/>
	);
});
