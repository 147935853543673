import { useTheme } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { FC, JSXElementConstructor } from 'react';
import { IconMessage } from '../iconMessage';

type NoRowsOverlayProps = {
	icon: JSXElementConstructor<any>;
	heading?: JSX.Element;
	body?: JSX.Element;
};

export const NoRowsOverlay: FC<NoRowsOverlayProps> = observer(
	({ icon, heading, body }) => {
		const theme = useTheme();
		const IconComponent = icon;

		return (
			<div
				style={{
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<IconMessage
					icon={
						<div
							style={{
								backgroundColor: theme.palette.cornflower[50],
								borderRadius: '50%',
								width: '5.5rem',
								height: '5.5rem',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: 'auto',
							}}
						>
							<IconComponent
								sx={{
									color: theme.palette.cornflower[500],
									height: '3rem',
									width: '3rem',
								}}
							/>
						</div>
					}
					heading={heading}
					body={body}
				/>
			</div>
		);
	},
);
