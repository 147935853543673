import {
	Button,
	Card,
	Grid,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { stonlyData } from 'stonly/functions';

export const testIds = {
	card: 'admin-card',
	tooltip: 'admin-card-tooltip',
	button: 'admin-card-button',
};

export interface groupProps {
	cardId: string;
	cardIcon: JSX.Element;
	cardTitle: string;
	cardDescription?: string | undefined;
	cardButtonText: string;
	cardLink: () => void;
	disabled?: boolean;
}
export const AdministrationCard: FC<groupProps> = observer(
	({
		cardId,
		cardIcon,
		cardTitle,
		cardDescription,
		cardButtonText,
		cardLink,
		disabled,
	}) => {
		const theme = useTheme();

		return (
			<Tooltip
				arrow
				title="The page you're trying to reach has restricted access. Please refer to your system administrator for assistance."
				disableFocusListener={!disabled}
				disableHoverListener={!disabled}
				disableTouchListener={!disabled}
				PopperProps={{
					'data-testid': testIds.tooltip,
					...({} as any),
				}}
			>
				<Card
					data-testid={testIds.card}
					{...stonlyData({
						id: `administration-card-${cardId}`,
					})}
					sx={{
						display: 'flex',
						height: '100%',
						minHeight: '280px',
						width: '100%',
						backgroundColor: '#F5F5F5',
					}}
				>
					<Grid
						container
						sx={{
							height: '100%',
							width: '100%',
							padding: '24px',
							flexWrap: 'nowrap',
							justifyContent: 'space-between',
							alignItems: 'center',
							flexDirection: 'column',
						}}
					>
						{cardIcon}
						<Grid
							item
							sx={{
								justifyContent: 'flex-start',
								alignItems: 'center',

								textAlign: 'center',
							}}
						>
							<Typography variant="subtitle1"> {cardTitle}</Typography>
						</Grid>
						<Grid
							item
							sx={{
								display: 'flex',
								direction: 'column',
								textAlign: 'center',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
							}}
						>
							<Typography variant="body2"> {cardDescription}</Typography>
						</Grid>
						<Grid
							item
							align-items="flex-end"
							sx={{ backgroundColor: theme.palette.common.white }}
						>
							<Button
								data-testid={testIds.button}
								{...stonlyData({
									id: `administration-card-button-${cardId}`,
								})}
								onClick={cardLink}
								variant="outlined"
								color="primary"
								disabled={disabled}
							>
								{cardButtonText}
							</Button>
						</Grid>
					</Grid>
				</Card>
			</Tooltip>
		);
	},
);
