import { DialogActions, Grid, Typography } from '@mui/material';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4Modal } from 'features/entity4/shared/components/atoms/t4Modal';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { stonlyData } from 'stonly/functions';

export type C4ProjectedEditModalProps = {
	isOpen: boolean;
	onCancel: () => void;
	onConfirm: () => void;
};

export const C4ProjectedEditModal: FC<C4ProjectedEditModalProps> = observer(
	({ isOpen, onCancel, onConfirm }) => (
		<T4Modal
			{...stonlyData({ id: 'projected-item-edit-confirm' })}
			open={isOpen}
			title={'Edit record warning'}
			actions={
				<DialogActions sx={{ padding: '1rem' }}>
					<Grid container columnSpacing={2} sx={{ justifyContent: 'flex-end' }}>
						<Grid item xs="auto">
							<DrawerCancelButton
								stonlyId={'cancel-action'}
								label="Cancel"
								onCancel={onCancel}
							/>
						</Grid>

						<Grid item xs="auto">
							<T4Button
								color="warning"
								variant="contained"
								onClick={() => {
									onConfirm();
								}}
							>
								Edit Record
							</T4Button>
						</Grid>
					</Grid>
				</DialogActions>
			}
		>
			<Typography variant="body1">
				{'Adjusting record details may affect the associated reconciliation.'}
			</Typography>
		</T4Modal>
	),
);
