import { AxiosInstance, AxiosResponse } from 'axios';
import {
	Read,
	T4DataResponse2,
	T4Response,
	Write,
} from 'modules/clients/types';

//#region Api Models

export type Cash4Configurations = {
	id: string;
	isCustomerRateSet: boolean;
	fxRateSetId: string;
	reportingCurrencyCode: string;
	createdOn: string;
	createdBy: string;
	lastUpdatedOn: string;
	lastUpdatedBy: string;
	enabledMoneyMarkets?: MoneyMarket[];
	t4FxRateSets: FxRateSet[];
	customerFxRateSets: FxRateSet[];
};

export type FxRateSet = {
	id: string;
	name: string;
	source: string;
	baseCurrencies?: string[];
	rates?: FxRate[];
};

export type FxRate = {
	id: string;
	baseCurrency: string;
	quoteCurrency: string;
	rate: number;
	type: string;
	effectiveDate: string;
	createdOn: string;
	lastUpdatedOn: string;
};

export type MoneyMarket = {
	id: string;
	name: string;
	url: string;
};

//#endregion

//#region Input Models

export type UpdateCash4ConfigurationsEnabledMoneyMarketsRequest = {
	id: string;
	enabledMoneyMarkets: string[];
};

//#endregion

//#region Request Models

export type UpdateCash4ConfigurationsReqeust = {
	isCustomerRateSet: boolean;
	fxRateSetId: string;
	reportingCurrencyCode: string;
};

//#endregion

//#region Response Models

export type Cash4ConfigurationsResponse = T4Response<Cash4Configurations>;

export type UpdateCash4ConfigurationsResponse = T4Response<
	Cash4Configurations,
	UpdateCash4ConfigurationsReqeust
>;

//#endregion

export type AdministrationEndpoints = {
	moneyMarkets: () => Promise<AxiosResponse<T4DataResponse2<MoneyMarket[]>>>;
	cash4Configurations: Read<Cash4ConfigurationsResponse>;
	updateCash4Configurations: Write<
		UpdateCash4ConfigurationsResponse,
		UpdateCash4ConfigurationsReqeust
	>;
	updateEnabledMoneyMarkets: (
		input: UpdateCash4ConfigurationsEnabledMoneyMarketsRequest,
	) => Promise<AxiosResponse<T4DataResponse2<string>, string[]>>;
};

export function administration(
	axiosInstance: AxiosInstance,
): AdministrationEndpoints {
	return {
		moneyMarkets: async () =>
			await axiosInstance.get<T4DataResponse2<MoneyMarket[]>>(
				'api/administration/moneymarkets',
			),
		cash4Configurations: async (_, config) =>
			await axiosInstance.get('api/administration/cash4/configurations', {
				...config,
			}),
		updateCash4Configurations: async (data, config) =>
			await axiosInstance.post(
				'api/administration/cash4/configurations',
				data,
				{
					...config,
				},
			),
		updateEnabledMoneyMarkets: async (input) =>
			await axiosInstance.post<
				T4DataResponse2<string>,
				AxiosResponse<T4DataResponse2<string>, string[]>,
				string[]
			>(
				`api/administration/cash4/configurations/${input.id}/enabledmoneymarkets`,
				input.enabledMoneyMarkets,
			),
	};
}
