import { T4DiagramProps } from 'features/entity4/visualizations/_shared/_components/graphRendererBase';
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import { AccountMapRendererBase } from './accountMapRendererBase';
import { ArrangingLayout } from './arrangingLayout';
import { EntityViewLayout } from './entityViewLayout';

export class AccountMapEntityViewRenderer extends AccountMapRendererBase {
	/** @internal */
	constructor(props: T4DiagramProps) {
		super(props);
		this.initDiagram = this.initDiagram.bind(this);
	}

	/**
	 * Diagram initialization method, which is passed to the ReactDiagram component.
	 * This method is responsible for making the diagram and initializing the model, any templates,
	 * and maybe doing other initialization tasks like customizing tools.
	 * The model's data should not be set here, as the ReactDiagram component handles that.
	 */
	private initDiagram(): go.Diagram {
		go.GraphObject.defineBuilder(
			'EntityViewTreeExpanderButton',
			(_): go.Panel => {
				const button = go.GraphObject.build('Button') as go.Panel;
				button.attach({
					// set these values for the isTreeExpanded binding conversion
					_treeExpandedFigure: 'MinusLine',
					_treeCollapsedFigure: 'PlusLine',
					// assume initially not visible because there are no links coming out
					visible: false,
				});

				button.add(
					// Group to hold both the shape and the text
					go.GraphObject.make(
						go.Panel,
						'Horizontal',
						go.GraphObject.make(
							go.TextBlock,
							{
								font: '4pt sans-serif',
								stroke: this._textColor,
								margin: new go.Margin(0, 1, -2, 0),
							},
							new go.Binding(
								'text',
								'',
								(n) => n.data.countChildren || '',
							).ofObject(),
						),

						// Shape for plus/minus icon
						new go.Shape('MinusLine', {
							name: 'ButtonIcon',
							stroke: '#0a0a0a',
							strokeWidth: 1,
							desiredSize: new go.Size(4, 4),
						}) // bind the Shape.figure to the Node.isTreeExpanded value using this converter:
							.bind(
								new go.Binding('figure', 'isTreeExpanded', function (exp) {
									return exp ? 'MinusLine' : 'PlusLine';
								}).ofObject(),
							),
					),
				);

				// bind the button visibility to whether it's not a leaf node
				button.bindObject(
					'visible',
					'isTreeLeaf',
					(leaf: boolean): boolean => !leaf,
				);

				// tree expand/collapse behavior
				button.click = (e: go.InputEvent, btn: go.GraphObject): void => {
					//intentionally suppress collapse/expand as it isn't done today.
					let node = btn.part;
					if (node instanceof go.Adornment) node = node.adornedPart;
					if (!(node instanceof go.Node)) return;
					const diagram = node.diagram;
					if (diagram === null) return;
					const cmd = diagram.commandHandler;
					if (node.isTreeExpanded) {
						if (!cmd.canCollapseTree(node)) return;
					} else {
						if (!cmd.canExpandTree(node)) return;
					}
					e.handled = true;
					if (node.isTreeExpanded) {
						cmd.collapseTree(node);
					} else {
						cmd.expandTree(node);
					}
				};

				return button;
			},
		);

		const diagram = go.GraphObject.make(go.Diagram, {
			initialAutoScale: go.AutoScale.Uniform,
			initialPosition: new go.Point(0, 0),
			layout: new ArrangingLayout({
				arrangingLayout: null, // so primaryLayout applies to all connected graphs
				primaryLayout: new EntityViewLayout({
					arrangement: go.TreeArrangement.Horizontal,
					treeStyle: go.TreeStyle.LastParents,
					angle: 90,
					alternateAngle: 90,
					alternateAlignment: go.TreeAlignment.Bus,
					alternateNodeSpacing: 50,
					arrangementSpacing: new go.Size(200, 50),
				}),
				spacing: new go.Size(400, 50),
				side: go.Spot.RightSide,
				sideLayout: new go.GridLayout({
					arrangement: go.GridArrangement.LeftToRight,
					wrappingColumn: 2,
					spacing: new go.Size(50, 50),
				}),
				filter: (p: go.Part) => {
					let node = p as go.Node;
					if (node.linksConnected?.count === 0) return false;
					return node.linksConnected?.any(
						(link) => !link.category?.startsWith('RootToRoot'),
					);
				},
			}),
		});
		diagram.undoManager.isEnabled = true;
		diagram.model = this.createDiagramModel();
		diagram.nodeTemplate = this.createNodeTemplate(
			true,
			null,
			this.props.isFieldVisible,
		);
		diagram.linkTemplate = this.createLinkTemplate();
		diagram.groupTemplate = this.createOrphanGroupTemplate(
			'Standalone Accounts',
			1,
			this.props.isOrphanGroupVisible,
		);
		this.createLegendGroupTemplate(diagram);

		let entityOrgNodeTemplate = this.createBasicNodeTemplate(true, 350);
		entityOrgNodeTemplate.add(
			new go.Panel('Vertical').add(
				new go.Panel('Vertical', {
					alignment: go.Spot.Left,
					stretch: go.Stretch.Horizontal,
					background: '#F4F4F4',
				}).add(
					new go.Panel('Vertical', {
						alignment: go.Spot.Left,
						stretch: go.Stretch.Horizontal,
						background: 'rgba(0,0,0,.08)',
						name: 'entityURL',
						cursor: 'pointer',
						click: (_, obj) => {
							window.open(
								'entities/' + obj.part?.data.entityId + '/information',
							);
						},
						mouseEnter: (_, obj) => {
							(obj.part?.findObject('entityURL') as go.TextBlock).isUnderline =
								true;
						},
						mouseLeave: (_, obj) => {
							(obj.part?.findObject('entityURL') as go.TextBlock).isUnderline =
								false;
						},
					}).add(
						new go.TextBlock('', {
							margin: new go.Margin(8, 8, 8, 8),
							alignment: go.Spot.Left,
							width: 350,
							wrap: go.Wrap.None,
							overflow: go.TextOverflow.Ellipsis,
						}).bind(
							new go.Binding('text', '', (data) => data.entityErpCode || '-'),
						),
					),
				),

				new go.Panel('Table', {
					//						defaultSeparatorPadding: 5,
					stretch: go.Stretch.Horizontal,
					background: 'rgba(0,0,0,.08)',
				}).add(
					new go.Picture({
						// flag image
						name: 'flagv',
						margin: new go.Margin(8, 8, 0, 8),
						row: 0,
						column: 0,
						height: 35,
						width: 53,
					})
						.bind(
							new go.Binding('source', '', (data) => {
								return (
									window.location.origin + '/svg/' + data.entityCountry + '.svg'
								);
							}),
						)
						.bind(new go.Binding('visible', 'isFlagVisible')),
					new go.TextBlock({
						margin: new go.Margin(8, 8, 4, 8),
						alignment: go.Spot.Left,
						row: 0,
						column: 1,
						width: 300,
						wrap: go.Wrap.None,
						overflow: go.TextOverflow.Ellipsis,
					}).bind(new go.Binding('text', 'entityName')),
					go.GraphObject.make('EntityViewTreeExpanderButton', {
						alignment: go.Spot.Center,
						row: 1,
						scale: 2,
						column: 0,
						columnSpan: 2,
					}),
				),
			),
		);

		diagram.nodeTemplateMap.add('entityOrg', entityOrgNodeTemplate);

		const linkToolTip = go.GraphObject.make(
			'ToolTip',
			go.GraphObject.make(
				go.TextBlock,
				{ margin: 4 }, // the tooltip shows the result of calling linkInfo(data)
				new go.Binding('text', '', (d) => {
					return d.cashFlowMovement
						? d.cashFlowMovement + ' Funding'
						: 'Funding Direction Unknown';
				}),
			),
		);

		const createEntityViewLinkTemplate = (
			routing: go.Routing,
			isLayoutPositioned: boolean,
			toArrow: string,
			fromArrow: string,
		) => {
			return go.GraphObject.make(
				go.Link,
				{
					routing: routing,
					toolTip: linkToolTip,
					isLayoutPositioned: isLayoutPositioned,
					copyable: false,
					deletable: false,
					selectionAdorned: false,
				},
				go.GraphObject.make(
					go.Shape,
					{},
					new go.Binding('stroke', '', (l: go.Link) => {
						return l.data.isSubaccount ? '#F6802C' : 'black';
					}).ofObject(),
					new go.Binding('strokeDashArray', '', (l: go.Link) => {
						if (l.data.category.includes('Manual')) {
							return [4, 2];
						} else if (l.data.isSubaccount) {
							return [5, this._connectorStrokeWidth];
						}
						return null;
					}).ofObject(),
					new go.Binding('strokeWidth', 'isSelected', (h) =>
						h ? 5 : 2,
					).ofObject(),
				),
				go.GraphObject.make(go.Shape, { toArrow: toArrow }),
				go.GraphObject.make(go.Shape, { fromArrow: fromArrow }),
				// For reference: we can use this to show a link label
				// go.GraphObject.make(
				// 	go.Panel,
				// 	'Auto', // this whole Panel is a link label
				// 	{
				// 		segmentIndex: -1,
				// 		segmentOffset: new go.Point(NaN, NaN),
				// 		segmentOrientation: go.Link.OrientUpright,
				// 	},
				// 	new go.Binding('visible', 'isHighlighted', (h) => h).ofObject(),
				// 	go.GraphObject.make(go.Shape, 'Rectangle', { fill: 'white', stroke: 'gray' }),
				// 	go.GraphObject.make(
				// 		go.TextBlock,
				// 		{ margin: 3 },
				// 		new go.Binding('text', 'cashFlowMovement'),
				// 	),
				// ),
			);
		};

		diagram.linkTemplateMap.add(
			'RootToRootAutomaticOneWay',
			createEntityViewLinkTemplate(
				go.Routing.AvoidsNodes,
				false,
				'',
				'Backward',
			),
		);

		diagram.linkTemplateMap.add(
			'RootToRootAutomaticTwoWay',
			createEntityViewLinkTemplate(
				go.Routing.AvoidsNodes,
				false,
				'Standard',
				'Backward',
			),
		);

		diagram.linkTemplateMap.add(
			'RootToRootManualOneWay',
			createEntityViewLinkTemplate(
				go.Routing.AvoidsNodes,
				false,
				'',
				'Backward',
			),
		);

		diagram.linkTemplateMap.add(
			'RootToRootManualTwoWay',
			createEntityViewLinkTemplate(
				go.Routing.AvoidsNodes,
				false,
				'Standard',
				'Backward',
			),
		);
		// Add link templates
		diagram.linkTemplateMap.add(
			'AutomaticOneWay',
			createEntityViewLinkTemplate(go.Routing.Orthogonal, true, '', 'Backward'),
		);

		diagram.linkTemplateMap.add(
			'AutomaticTwoWay',
			createEntityViewLinkTemplate(
				go.Link.Orthogonal,
				true,
				'Standard',
				'Backward',
			),
		);

		diagram.linkTemplateMap.add(
			'ManualOneWay',
			createEntityViewLinkTemplate(go.Routing.Orthogonal, true, '', 'Backward'),
		);

		diagram.linkTemplateMap.add(
			'ManualTwoWay',
			createEntityViewLinkTemplate(
				go.Routing.Orthogonal,
				true,
				'Standard',
				'Backward',
			),
		);

		this.setDiagramEvents(diagram);
		return diagram;
	}

	public render() {
		//for hardcoded testing without going to backend - replace nodeDataArray with testNodes and linkDataArray with testLinks
		// let testNodes = [
		// 	{ key: 0, text: 'Root' },
		// 	{ key: 1, text: 'Subroot' },
		// 	{ key: 2, text: 'Alpha' },
		// 	{ key: 3, text: 'Beta', color: 'orange' },
		// 	{ key: 4, text: 'Gamma', color: 'lightgreen' },
		// 	{ key: 5, text: 'Delta', color: 'pink' },

		// 	{ key: 6, text: 'A Separate root' },
		// 	{ key: 7, text: 'child of seprate root' },
		// 	{ key: 8, text: 'G', color: 'orange' },
		// 	{ key: 9, text: 'H' },

		// 	{ key: 10, text: 'orphan10' },
		// 	{ key: 11, text: 'orphan11' },
		// 	{ key: 12, text: 'orphan12' },
		// 	{ key: 13, text: 'orphan13' },
		// 	{ key: 14, text: 'orphan14' },
		// 	{ key: 15, text: 'orphan15' },

		// 	{ key: 16, text: 'Root 2' },
		// 	{ key: 17, text: 'SubRoot 2' },
		// 	{ key: 18, text: 'A' },
		// 	{ key: 19, text: 'B' },
		// 	{ key: 20, text: 'C', color: 'grey' },
		// ];

		// let testLinks = [
		// 	{ key: -1, from: 0, to: 1 },
		// 	{ key: -2, from: 1, to: 2 },
		// 	{ key: -3, from: 1, to: 3 },
		// 	{ key: -4, from: 1, to: 4 },
		// 	{ key: -5, from: 1, to: 5 },

		// 	{ key: -6, from: 6, to: 7 },
		// 	{ key: -7, from: 7, to: 8 },
		// 	{ key: -8, from: 7, to: 9 },

		// 	{ key: -9, from: 16, to: 17 },
		// 	{ key: -10, from: 17, to: 18 },
		// 	{ key: -11, from: 17, to: 19 },
		// 	{ key: -12, from: 17, to: 20 },

		// 	{ key: -13, from: 1, to: 7, category: 'RootToRoot' },
		// 	{ key: -14, from: 1, to: 10, category: 'RootToRoot' },
		// ];

		return (
			<ReactDiagram
				ref={this.diagramRef}
				divClassName="entityview-gojs-diagram"
				style={this.diagramStyle}
				initDiagram={this.initDiagram}
				nodeDataArray={this.props.nodeDataArray}
				linkDataArray={this.props.linkDataArray}
				modelData={this.props.modelData}
				onModelChange={this.props.onModelChange}
			/>
		);
	}
}
