import { Button, ButtonProps, styled } from '@mui/material';

export const T4TryAgainButton = styled(Button)<ButtonProps>(({ theme }) => ({
	appearance: 'none',
	padding: 0,
	margin: 0,
	marginLeft: theme.spacing(0.5),
	background: 'transparent',
	border: 0,
	fontSize: 'inherit',
	color: theme.palette.error.main,
	textDecoration: 'underline',
	cursor: 'pointer',

	'&:hover': {
		textDecoration: 'none',
	},
}));

export const T4RejectButton = styled(Button)<ButtonProps>(({ theme }) => ({
	backgroundColor: theme.palette.error.main,
	'&:hover': { backgroundColor: theme.palette.error.dark },
}));
