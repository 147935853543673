/* eslint-disable mobx/missing-observer */
import { ApprovalRule } from 'modules/clients/apiGateway/payments4/approvalRules';
import * as yup from 'yup';
import { AddApprovalTierForm } from './addApprovalTierDrawer';

export const getAddApprovalTierFormValidator = (
	rule: ApprovalRule | null,
): yup.ObjectSchema<AddApprovalTierForm> =>
	yup.object({
		amount: yup
			.number()
			.typeError('Amount must be a number')
			.label('Amount')
			.nullable()
			.defined()
			.when(
				'isUnlimitedAmount',
				([isUnilimitedAmount, isAutoApprove], schema) => {
					if (!isUnilimitedAmount)
						return schema
							.required()
							.nonNullable('Amount is required')
							.positive()
							.test(
								'non-unique',
								'Up to Amount must be unique across all tiers',
								function (value) {
									if (rule)
										return !rule.approvalTiers.some(
											(tier) => tier.amount === value,
										);
									return true;
								},
							)
							.when([], {
								is: () => !isAutoApprove,
								then: (schema) =>
									schema.test(
										'must-be-larger-than-autoapprove-tier',
										"You cannot create a tier with a lower amount than an existing tier set to 'No Approvals Required'",
										function (value) {
											if (rule)
												return !rule.approvalTiers.some(
													(tier) =>
														tier.isAutoApprove === true && value < tier.amount,
												);
											return true;
										},
									),
							});
					return schema;
				},
			),
		isUnlimitedAmount: yup
			.boolean()
			.required()
			.defined()
			.when('isAutoApprove', ([isAutoApprove], schema) => {
				return isAutoApprove ? schema.isFalse() : schema;
			}),
		isAutoApprove: yup.boolean().required().defined(),
		approvalLevels: yup.array().required().defined(),
	});

export const getApprovalTierAmountValidator = (
	isUnlimitedAmount: boolean,
	isAutoApprove: boolean,
	tierId: string | null,
	rule: ApprovalRule | null,
) =>
	yup
		.number()
		.typeError('Amount must be a number')
		.label('Amount')
		.nullable()
		.defined()
		.when([], {
			is: () => !isUnlimitedAmount,
			then: (schema) =>
				schema
					.required()
					.nonNullable('Amount is required')
					.positive()
					.test(
						'non-unique',
						'Up to Amount must be unique across all tiers',
						function (value) {
							if (rule)
								return !rule.approvalTiers.some(
									(tier) => tier.amount === value && tierId !== tier.id,
								);
							return true;
						},
					)
					.test(
						'must-be-larger-than-autoapprove-tier',
						"Up to Amount must be larger than an existing tier set to 'No Approvals Required'",
						function (value) {
							if (rule)
								return !rule.approvalTiers.some(
									(tier) =>
										tier.isAutoApprove &&
										value < tier.amount &&
										tierId !== tier.id,
								);
							return true;
						},
					)
					.when([], {
						is: () => isAutoApprove,
						then: (schema) =>
							schema.test(
								'autoapprove-tier-must-be-lowest-amount',
								'Only the tier with the lowest amount can be set to have no approvals required',
								function (value) {
									if (rule)
										return !rule.approvalTiers.some(
											(tier) => tier.amount < value && tierId !== tier.id,
										);
									return true;
								},
							),
					}),
			otherwise: (schema) => schema,
		});
