import { ChevronLeft, ChevronRight, Share } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Grid, IconButton, Typography } from '@mui/material';
import deepEqual from 'deep-eql';
import { T4CopyButtonWrapper } from 'features/cash4/shared/components/T4SideDrawer/T4CopyButtonWrapper';
import { ProjectedItemDrawer } from 'features/cash4/transactions/components/ProjectedItems/projectedItemDrawer';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { paths } from 'shared/constants/paths';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';
import { ModifyReconciliationView } from '../_views/modifyReconciliationView';
import { ReconciliationView } from '../_views/reconciliationView';
import { useCreateReconciliation } from '../services';
import { C4CancelDialog } from './c4CancelDialog';
import { C4Drawer, C4DrawerProps } from './c4Drawer';
import { C4TransactionsDrawer } from './c4TransactionsDrawer';

export enum ReconciliationMode {
	Create,
	View,
	Edit,
}

export enum ReconciliationTab {
	Selection,
	Detail,
}

export type ReconciliationDrawerProps = {
	onClose?: () => void;
};

export const ReconciliationDrawer: FC<ReconciliationDrawerProps> = observer(
	({ onClose }) => {
		const {
			open,
			mode,
			tab,
			reconciliation,
			selectedProjectedItem,
			projectedItemDrawerOpen,
			selectedTransaction,
			projectedItemsQueryContext: { refetch: refetchProjectedItems },
			reconciliationQueryContext: { refetch: refetchReconciliations },
			setSelectedTransaction,
			setProjectedItemDrawerOpen,
			setSelectedProjectedItem,
			setTab,
			updateReconciliation,
			onDrawerClose,
			notes,
		} = useReconciliationsContext();

		const [cancelOpen, setCancelOpen] = useState(false);
		const [selectedProjectedIds, setSelectedProjectedIds] = useState<string[]>(
			[],
		);
		const [selectedReportedIds, setSelectedReportedIds] = useState<string[]>(
			[],
		);

		const isSubmitDisabled = useMemo(
			() =>
				(selectedProjectedIds?.length ?? 0) <= 0 ||
				(selectedReportedIds?.length ?? 0) <= 0,
			[selectedProjectedIds?.length, selectedReportedIds?.length],
		);

		const onCancelHandler = useCallback(() => {
			setCancelOpen(true);
		}, []);

		const onCloseHandler = useCallback(() => {
			setCancelOpen(false);
			onClose?.();
			onDrawerClose();
		}, [onClose, onDrawerClose]);

		const isCreateDirty = useMemo(
			() =>
				mode === ReconciliationMode.Create &&
				(selectedProjectedIds.length > 0 ||
					selectedReportedIds.length > 0 ||
					(notes?.length ?? 0) > 0),
			[
				mode,
				notes?.length,
				selectedProjectedIds.length,
				selectedReportedIds.length,
			],
		);

		const isEditDirty = useMemo(
			() =>
				mode === ReconciliationMode.Edit &&
				reconciliation &&
				(!deepEqual(
					selectedProjectedIds,
					reconciliation.projectedItems.map((item) => item.id),
				) ||
					!deepEqual(
						selectedReportedIds,
						reconciliation.reportedItems.map((item) => item.id),
					) ||
					!deepEqual(notes, reconciliation.notes)),
			[mode, notes, reconciliation, selectedProjectedIds, selectedReportedIds],
		);

		const onClickAwayHandler = useCallback(() => {
			if (mode === ReconciliationMode.View) {
				onCloseHandler();
			} else {
				if (isCreateDirty || isEditDirty) {
					onCancelHandler();
				} else {
					onCloseHandler();
				}
			}
		}, [isCreateDirty, isEditDirty, mode, onCancelHandler, onCloseHandler]);

		const { mutate: createReconciliation } =
			useCreateReconciliation(onDrawerClose);

		const title = useMemo(() => {
			let text = 'Reconciliation';
			switch (mode) {
				case ReconciliationMode.Create:
					text = 'Create reconciliation';
					break;

				case ReconciliationMode.Edit:
					text = 'Edit reconciliation';
					break;
			}

			return (
				<Grid
					container
					sx={{ alignItems: 'center', justifyContent: 'space-between' }}
				>
					<Grid item xs="auto">
						<Typography variant="h3">{text}</Typography>
					</Grid>
					{mode !== ReconciliationMode.Create && (
						<Grid item>
							<T4CopyButtonWrapper
								copyText={`${window.location.origin}${paths.cash4.reconciliations.href}/${reconciliation?.id}`}
							>
								<IconButton>
									<Share />
								</IconButton>
							</T4CopyButtonWrapper>
						</Grid>
					)}
				</Grid>
			);
		}, [mode, reconciliation?.id]);

		const actions = useMemo<C4DrawerProps['actions']>(() => {
			let actions: C4DrawerProps['actions'] = {};

			const cancelButton = (
				<T4Button color="secondary" onClick={onClickAwayHandler}>
					Cancel
				</T4Button>
			);

			switch (mode) {
				case ReconciliationMode.View:
					actions = {
						end: [cancelButton],
					};
					break;

				default:
					actions = {
						start:
							tab === ReconciliationTab.Detail
								? [
										<T4Button
											color="secondary"
											variant="outlined"
											startIcon={<ChevronLeft />}
											onClick={() => setTab(ReconciliationTab.Selection)}
										>
											Select Records
										</T4Button>,
								  ]
								: undefined,
						end:
							tab === ReconciliationTab.Selection
								? [
										cancelButton,
										<T4Button
											variant="contained"
											endIcon={<ChevronRight />}
											onClick={() => setTab(ReconciliationTab.Detail)}
											disabled={
												selectedReportedIds.length === 0
											}
										>
											{`${
												mode === ReconciliationMode.Create ? 'Add' : 'Edit'
											} Details`}
										</T4Button>,
								  ]
								: [
										cancelButton,
										<T4Button
											variant="contained"
											disabled={isSubmitDisabled}
											onClick={() => {
												if (mode === ReconciliationMode.Create) {
													createReconciliation({
														projectedTransactions: selectedProjectedIds,
														reportedTransactions: selectedReportedIds,
														note: notes,
													});
												} else {
													updateReconciliation(
														selectedProjectedIds,
														selectedReportedIds,
													);
												}
											}}
										>
											{mode === ReconciliationMode.Create ? 'Create' : 'Save'}
										</T4Button>,
								  ],
					};
					break;
			}

			return actions;
		}, [
			createReconciliation,
			isSubmitDisabled,
			mode,
			notes,
			onClickAwayHandler,
			selectedProjectedIds,
			selectedReportedIds,
			setTab,
			tab,
			updateReconciliation,
		]);

		const cancellationModalDetails = useMemo(() => {
			let details = {
				title: 'Cancel reconciliation creation?',
				description:
					'Canceling reconciliation creation will discard any data you have entered, and a reconciliation will not be created.',
			};

			switch (mode) {
				case ReconciliationMode.Edit:
					details.title = 'Discard edits?';
					details.description =
						'Discarding will remove all changes made to the reconciliation.';
					break;
			}

			return details;
		}, [mode]);

		const createEditReconciliation = useMemo(() => {
			return (
				<ModifyReconciliationView
					selectedProjectedIds={selectedProjectedIds}
					setSelectedProjectedIds={setSelectedProjectedIds}
					selectedReportedIds={selectedReportedIds}
					setSelectedReportedIds={setSelectedReportedIds}
				/>
			);
		}, [selectedProjectedIds, selectedReportedIds]);

		useEffect(() => {
			if (
				reconciliation &&
				selectedProjectedIds.length === 0 &&
				selectedReportedIds.length === 0
			) {
				setSelectedProjectedIds(
					reconciliation.projectedItems.map((item) => item.id),
				);
				setSelectedReportedIds(
					reconciliation.reportedItems.map((item) => item.id),
				);
			} else if (!open && reconciliation === undefined) {
				setSelectedProjectedIds([]);
				setSelectedReportedIds([]);
			}
		}, [
			open,
			reconciliation,
			selectedProjectedIds.length,
			selectedReportedIds.length,
		]);

		useEffect(() => {
			if (!open) {
				setSelectedProjectedIds([]);
				setSelectedReportedIds([]);
			}
		}, [open]);

		return (
			<C4Drawer
				open={open}
				title={title}
				actions={actions}
				onClickAway={onClickAwayHandler}
				sx={{
					'& .MuiDrawer-paper': {
						width: '80%',
					},
				}}
			>
				<TabContext value={mode.toString()}>
					<TabPanel
						value={ReconciliationMode.View.toString()}
						sx={{ padding: 0, width: '100%' }}
					>
						<ReconciliationView reconciliation={reconciliation} />
					</TabPanel>
					<TabPanel
						value={ReconciliationMode.Create.toString()}
						sx={{ padding: 0, width: '100%' }}
					>
						{createEditReconciliation}
					</TabPanel>
					<TabPanel
						value={ReconciliationMode.Edit.toString()}
						sx={{ padding: 0, width: '100%' }}
					>
						{createEditReconciliation}
					</TabPanel>
				</TabContext>
				<C4CancelDialog
					open={cancelOpen}
					title={cancellationModalDetails.title}
					description={cancellationModalDetails.description}
					onCancel={() => setCancelOpen(false)}
					onConfirm={onCloseHandler}
					color={mode === ReconciliationMode.Create ? 'error' : 'warning'}
				/>
				<ProjectedItemDrawer
					isOpen={projectedItemDrawerOpen}
					projectedItem={selectedProjectedItem}
					onClose={() => {
						setProjectedItemDrawerOpen(false);
						setSelectedProjectedItem(undefined);
					}}
					onSubmit={(id) => {
						refetchProjectedItems();
						refetchReconciliations();

						if (id) {
							setSelectedProjectedIds((prev) => [...prev, id]);
						}
					}}
				/>
				<C4TransactionsDrawer
					isOpen={Boolean(selectedTransaction)}
					transaction={selectedTransaction?.id}
					onClose={() => {
						setSelectedTransaction(undefined);
					}}
				/>
			</C4Drawer>
		);
	},
);
