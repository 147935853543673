import { CheckCircle, DoNotDisturbOn } from '@mui/icons-material';
import { Grid, Switch, Typography } from '@mui/material';
import { useCash4Configurations } from 'features/_shared/_providers/cash4ConfigurationsProvider';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import { FxRateSet } from 'modules/clients/customer-api/src/api/administration';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { useMoneyMarketsQuery } from '../_shared/_hooks/useMoneyMarketsQuery';

type ProviderType = 'Customer' | 'Treasury4';

export const Cash4ConfigurationsPage: FC = observer(() => {
	const { isAdmin } = useUser();
	const {
		loading,
		data: configurations,
		update,
		updateEnabledMoneyMarkets,
		refetch,
	} = useCash4Configurations();
	const moneyMarketsQueryContext = useMoneyMarketsQuery();

	const [isSaved, setIsSaved] = useState<boolean>(true);
	const [provider, setProvider] = useState<ProviderType>(() =>
		configurations?.isCustomerRateSet ? 'Customer' : 'Treasury4',
	);
	const [currencyCodes, setCurrencyCodes] = useState<string[]>([]);
	const [fxRateSet, setFxRateSet] = useState<FxRateSet>();
	const [reportingCurrency, setReportingCurrency] = useState<string>();
	const [fxRateSets, setFxRateSets] = useState<FxRateSet[]>([]);

	const handleProviderChange = useCallback((value: ProviderType | null) => {
		if (value) {
			setIsSaved(false);
			setReportingCurrency(undefined);
			setFxRateSet(undefined);
			setProvider(value);
			if (value === 'Customer') {
				setCurrencyCodes(['USD']);
			} else {
				setCurrencyCodes([]);
			}
		}
	}, []);

	const handleFxRateSetChange = useCallback(
		(value: FxRateSet | null) => {
			setIsSaved(false);
			setReportingCurrency(undefined);
			setFxRateSet(value ?? undefined);
			if (provider === 'Customer') {
				setCurrencyCodes(value?.baseCurrencies ?? []);
			}
		},
		[provider],
	);

	const handleReportingCurrencyChange = useCallback(
		async (value: string | null) => {
			setIsSaved(false);
			setReportingCurrency(value ?? undefined);

			if (provider && fxRateSet && value) {
				const isCustomerRateSet = provider === 'Customer';
				const fxRateSetId = fxRateSet.id;
				const reportingCurrencyCode = value;

				if (
					isCustomerRateSet !== configurations?.isCustomerRateSet ||
					fxRateSetId !== configurations?.fxRateSetId ||
					reportingCurrencyCode !== configurations?.reportingCurrencyCode
				) {
					await update({
						isCustomerRateSet: isCustomerRateSet,
						fxRateSetId: fxRateSetId,
						reportingCurrencyCode: reportingCurrencyCode,
					});
				}
				setIsSaved(true);
			}
		},
		[
			configurations?.fxRateSetId,
			configurations?.isCustomerRateSet,
			configurations?.reportingCurrencyCode,
			fxRateSet,
			provider,
			update,
		],
	);

	useEffect(() => {
		if (provider === 'Customer') {
			setFxRateSets(configurations?.customerFxRateSets ?? []);
			setCurrencyCodes(
				configurations?.customerFxRateSets.find(
					(x) => x.id === configurations.fxRateSetId,
				)?.baseCurrencies ?? [],
			);
		} else {
			setFxRateSets(configurations?.t4FxRateSets ?? []);
			setCurrencyCodes(['USD']);
		}
	}, [
		configurations?.customerFxRateSets,
		configurations?.fxRateSetId,
		configurations?.t4FxRateSets,
		provider,
	]);

	useEffect(() => {
		if (!loading && configurations) {
			setProvider(configurations?.isCustomerRateSet ? 'Customer' : 'Treasury4');
			setFxRateSet(
				configurations.isCustomerRateSet
					? configurations?.customerFxRateSets?.find(
							(fxRateSet) => fxRateSet.id === configurations?.fxRateSetId,
					  )
					: configurations?.t4FxRateSets?.find(
							(fxRateSet) => fxRateSet.id === configurations?.fxRateSetId,
					  ),
			);
			setReportingCurrency(configurations?.reportingCurrencyCode);
			setIsSaved(true);
		}
	}, [
		configurations,
		configurations?.customerFxRateSets,
		configurations?.fxRateSetId,
		configurations?.isCustomerRateSet,
		configurations?.reportingCurrencyCode,
		configurations?.t4FxRateSets,
		loading,
	]);

	useEffect(() => {
		refetch();

		// We only want this to run once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.cash4ConfigurationsPage}
					title="Configurations"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
			hasAccess={isAdmin}
		>
			<Grid container sx={{ gap: 3 }}>
				<Grid container sx={{ gap: 2 }}>
					<Grid container item xs={12} sx={{ gap: 2 }}>
						<Grid container item xs="auto" sx={{ paddingRight: '2rem' }}>
							<Typography variant="h3" sx={{ alignSelf: 'center' }}>
								FX Rate Settings
							</Typography>
						</Grid>
						<Grid container item xs="auto">
							{isSaved ? (
								<CheckCircle color="success" sx={{ alignSelf: 'center' }} />
							) : (
								<DoNotDisturbOn color="warning" sx={{ alignSelf: 'center' }} />
							)}
						</Grid>
						<Grid item xs="auto">
							<Typography
								sx={{ fontSize: '0.9rem' }}
							>{`Last autosave`}</Typography>
							<Typography sx={{ fontSize: '0.9rem' }}>
								{moment(configurations?.lastUpdatedOn).format('h:mm a, ')}
								{moment(configurations?.lastUpdatedOn)
									.format('DD-MMM-YYYY')
									.toUpperCase()}
							</Typography>
						</Grid>
					</Grid>
					<Grid container item xs={12}>
						<T4Autocomplete<ProviderType>
							loading={loading}
							label="Provider"
							required
							disableClearable
							options={['Treasury4', 'Customer']}
							value={provider}
							onChange={(_, value) => handleProviderChange(value)}
							sx={{
								width: '400px',
							}}
						/>
					</Grid>
					<Grid container item xs={12}>
						<T4Autocomplete<FxRateSet>
							loading={loading}
							disabled={!provider}
							label="FX Rate Set"
							required
							options={fxRateSets}
							value={fxRateSet ?? null}
							isOptionEqualToValue={(a, b) => a?.id === b?.id}
							getOptionLabel={(option) => option?.name ?? ''}
							onChange={(_, value) => handleFxRateSetChange(value)}
							sx={{
								width: '400px',
							}}
						/>
					</Grid>
					<Grid container item xs={12}>
						<T4Autocomplete
							loading={loading}
							disabled={!fxRateSet}
							label="Reporting Currency Code"
							required
							options={currencyCodes}
							value={reportingCurrency ?? null}
							onChange={(_, value) => handleReportingCurrencyChange(value)}
							sx={{
								width: '400px',
							}}
						/>
					</Grid>
				</Grid>
				<Grid container sx={{ gap: 2 }}>
					<Grid item xs={12}>
						<Typography variant="h3">External Portals</Typography>
					</Grid>
					{(moneyMarketsQueryContext.data?.length ?? 0) > 0 && (
						<Grid container item xs={4}>
							{moneyMarketsQueryContext.data
								?.sort((a, b) => a.name.localeCompare(b.name))
								.map((moneyMarket) => (
									<Grid
										key={moneyMarket.id}
										container
										item
										xs={12}
										sx={{
											alignItems: 'center',
										}}
									>
										<Grid item xs="auto">
											<Switch
												disabled={loading}
												checked={
													configurations?.enabledMoneyMarkets?.some(
														(x) => x.id === moneyMarket.id,
													) ?? false
												}
												onChange={(event) => {
													const isChecked = event.target.checked;
													if (isChecked) {
														setIsSaved(false);
														updateEnabledMoneyMarkets([
															...(configurations?.enabledMoneyMarkets?.map(
																(x) => x.id,
															) ?? []),
															moneyMarket.id,
														]);
													} else {
														setIsSaved(false);
														updateEnabledMoneyMarkets(
															configurations?.enabledMoneyMarkets
																?.filter((x) => x.id !== moneyMarket.id)
																.map((x) => x.id) ?? [],
														);
													}
												}}
											/>
										</Grid>
										<Grid item xs="auto">
											<Typography>{moneyMarket.name}</Typography>
										</Grid>
									</Grid>
								))}
						</Grid>
					)}
				</Grid>
			</Grid>
		</T4View>
	);
});
