import { Loop, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { VisualizationButton } from 'features/entity4/visualizations/_shared/_components/visualizationButton';
import { useVisualizationContext } from 'features/entity4/visualizations/_shared/_providers/visualizationProvider';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { stonlyData } from 'stonly/functions';

export type VisualizationsFooterProps = {
	stonlyId: string;
	diagram: go.Diagram | undefined;
	onResetView: (diagram: go.Diagram) => void;
};

export const VisualizationsFooter: FC<VisualizationsFooterProps> = observer(
	({ stonlyId, diagram, onResetView }) => {
		const { zoom } = useVisualizationContext();

		const onZoomIn = useCallback(() => {
			if (diagram) {
				diagram.commandHandler.increaseZoom();
			}
		}, [diagram]);

		const onZoomOut = useCallback(() => {
			if (diagram) {
				diagram.commandHandler.decreaseZoom();
			}
		}, [diagram]);

		const onRestView = useCallback(() => {
			if (diagram) {
				diagram.commit((d) => {
					d.model.commit((m) => {
						d.nodes.each((node) => {
							m.set(node, 'visible', true);
						});
						d.links.each((link) => {
							m.set(link, 'visible', true);
						});
					});
					d.clearSelection();
					onResetView(diagram);
				});
				diagram.commit((d) => {
					d.zoomToFit();
				});
			}
		}, [diagram, onResetView]);

		const onZoomToFit = useCallback(() => {
			if (diagram) {
				diagram.commandHandler.zoomToFit();
			}
		}, [diagram]);

		return (
			<Grid
				aria-label="Platform"
				container
				item
				flexWrap="nowrap"
				sx={{
					gap: 2,
					justifyContent: 'center',
					paddingLeft: '2rem',
				}}
			>
				<Grid item xs="auto">
					<VisualizationButton
						id="zoomInBtn"
						title="Zoom In"
						onClick={onZoomIn}
						startIcon={<ZoomIn />}
						{...stonlyData({
							id: `${stonlyId}-zoom-in-button`,
						})}
					>
						Zoom In
					</VisualizationButton>
				</Grid>
				<Grid item xs="auto">
					<VisualizationButton
						id="zoomOutBtn"
						title="Zoom Out"
						onClick={onZoomOut}
						startIcon={<ZoomOut />}
						{...stonlyData({
							id: `${stonlyId}-zoom-out-button`,
						})}
					>
						Zoom Out
					</VisualizationButton>
				</Grid>
				<Grid item xs="auto">
					<VisualizationButton
						id="zoomResetBtn"
						title="Reset View"
						onClick={onRestView}
						startIcon={<Loop />}
						{...stonlyData({
							id: `${stonlyId}-reset-view-button`,
						})}
					>
						Reset View
					</VisualizationButton>
				</Grid>
				<Grid item xs="auto">
					<VisualizationButton
						id="zoomResetBtn"
						title="Zoom To Fit"
						onClick={onZoomToFit}
						startIcon={<Loop />}
						{...stonlyData({
							id: `${stonlyId}-zoom-fit-button`,
						})}
					>
						Zoom To Fit
					</VisualizationButton>
				</Grid>
				<Grid item xs="auto">
					<Typography variant="caption" sx={{ backgroundColor: 'white' }}>
						Current Zoom: {zoom ? (zoom * 100).toFixed(2) : 0}%
					</Typography>{' '}
				</Grid>
			</Grid>
		);
	},
);
