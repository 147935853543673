//#region Context

import { observer } from 'mobx-react-lite';
import { GetConnectionsResponse } from 'modules/clients/customer-api/src/cash4/connections';
import { useSnackbar } from 'notistack';
import { createContext, FC, ReactNode, useCallback, useContext } from 'react';
import { useClients } from 'shared/hooks/useClients';

type AccountIntegrationsContextProps = {
	updateCurrentDay: () => void;
	getCurrentDayRefreshStatus: () => Promise<boolean>;
};

const AccountIntegrationsContext =
	createContext<AccountIntegrationsContextProps>(
		{} as AccountIntegrationsContextProps,
	);

//#endregion

//#region Provider

export type AccountIntegrationsProviderProps = {
	children: ReactNode;
};

export const AccountIntegrationsProvider: FC<AccountIntegrationsProviderProps> =
	observer(({ children }) => {
		const { customerApiClient } = useClients();
		const { enqueueSnackbar } = useSnackbar();

		const updateCurrentDay = useCallback(async () => {
			const response = await customerApiClient.api.cash4.updateCurrentDay();

			if (response.data.data) {
				var count = response.data.data.count;
				enqueueSnackbar(
					`Update request received! 
                    Current-day records for ${count} account(s) will be available shortly, 
                    and rules will run automatically. 
                    Please refresh the page if records do not display.`,
					{
						variant: 'info',
					},
				);
			} else {
				enqueueSnackbar(
					'An error occurred and the update request could not be sent.',
					{
						variant: 'error',
					},
				);
			}
		}, [customerApiClient.api.cash4, enqueueSnackbar]);

		const getCurrentDayRefreshStatus = useCallback(async () => {
			const response: GetConnectionsResponse =
				await customerApiClient.cash4.connections.getConnections();
			var currentDayEnabled = response?.data?.data?.some((c) => {
				return c.connector.code.indexOf('current-day') > -1;
			});
			return currentDayEnabled;
		}, [customerApiClient.cash4.connections]);

		return (
			<AccountIntegrationsContext.Provider
				value={{
					updateCurrentDay: updateCurrentDay,
					getCurrentDayRefreshStatus: getCurrentDayRefreshStatus,
				}}
			>
				{children}
			</AccountIntegrationsContext.Provider>
		);
	});

//#endregion

//#region Hook

export type UseAccountIntegrationsContextProps =
	AccountIntegrationsContextProps;

export function useAccountIntegrationsContext(): UseAccountIntegrationsContextProps {
	return useContext(AccountIntegrationsContext);
}

//#endregion
