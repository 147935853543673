import { observer } from 'mobx-react-lite';
import { MoneyMarket } from 'modules/clients/customer-api/src/api/administration';
import { FC } from 'react';

export type MoneyPortalProps = {
	moneyMarket: MoneyMarket;
};

export const MoneyPortal: FC<MoneyPortalProps> = observer(({ moneyMarket }) => (
	<iframe
		title={moneyMarket.name}
		src={moneyMarket.url}
		style={{ height: '100%', width: '100%', outline: 'none', border: 'none' }}
	/>
));
