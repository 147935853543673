/* eslint-disable mobx/missing-observer */
import { Tooltip, TooltipProps } from '@mui/material';
import React, { FC, useCallback, useMemo, useState } from 'react';

export type T4CopyButtonWrapperProps = {
	children: React.ReactElement;
	copyText: string;
	placement?: TooltipProps['placement'];
	initialTooltipText?: string;
};

export const T4CopyButtonWrapper: FC<T4CopyButtonWrapperProps> = ({
	children,
	copyText,
	placement,
	initialTooltipText = 'Copy to Clipboard',
}) => {
	const [tooltipTitle, setTooltipTitle] = useState<string>(initialTooltipText);

	const copyToClipboard = useCallback(async () => {
		try {
			await navigator.clipboard.writeText(copyText);
			setTooltipTitle('Copied!');
		} catch (err) {
			setTooltipTitle('Failed to copy!');
		} finally {
			setTimeout(() => setTooltipTitle('Copy to Clipboard'), 2000);
		}
	}, [copyText]);

	const handleChildClick = useCallback(
		(event: React.MouseEvent) => {
			// Call the original onClick if it exists
			if (children.props.onClick) {
				children.props.onClick(event);
			}
			// Then perform the copy action
			copyToClipboard();
		},
		[children.props, copyToClipboard],
	);

	const childWithProps = useMemo(
		() =>
			React.cloneElement(children, {
				onClick: handleChildClick,
			}),
		[children, handleChildClick],
	);

	return (
		<Tooltip
			title={tooltipTitle}
			placement={placement}
			enterDelay={300}
			leaveDelay={200}
		>
			{childWithProps}
		</Tooltip>
	);
};
