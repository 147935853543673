import { Reconciliation } from 'modules/clients/customer-api/src/api/cash4';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useT4Parameter } from '../_shared/_utilities/useT4Parameter';
import { useT4Query, UseT4QueryProps } from '../_shared/_utilities/useT4Query';

export type UseReconciliationQueryProps = UseT4QueryProps<Reconciliation>;

export function useReconciliationQuery(
	reconciliation: string | Reconciliation | undefined,
): UseReconciliationQueryProps {
	const { customerApiClient } = useClients();
	const { property: reconciliationActual } = useT4Parameter(reconciliation);

	const query = useCallback(async () => {
		if (typeof reconciliationActual == 'string') {
			const response = await customerApiClient.api.cash4.reconciliation(
				reconciliationActual,
			);

			return response?.data?.data;
		} else {
			return reconciliationActual;
		}
	}, [customerApiClient.api.cash4, reconciliationActual]);

	return useT4Query(query);
}
