import { useTransactionQuery } from 'features/cash4/_queries/useTransactionQuery';
import T4Drawer from 'features/cash4/shared/components/T4SideDrawer/T4DrawerShell';
import { TransactionDrawer } from 'features/cash4/transactions/components/TransactionDrawer/TransactionDrawer';
import { TransactionListItem } from 'features/cash4/transactions/models';
import { EditRuleProvider } from 'features/cash4/transactions/providers/useEditRule';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type C4TransactionsDrawerProps = {
	isOpen: boolean;
	transaction: TransactionListItem | string | undefined;
	disableLink?: boolean;
	onClose: () => void;
};

export const C4TransactionsDrawer: FC<C4TransactionsDrawerProps> = observer(
	({ isOpen, transaction, onClose }) => {
		const { loading, transaction: transactionActual } =
			useTransactionQuery(transaction);

		return (
			<EditRuleProvider>
				<T4Drawer
					open={isOpen}
					onClose={onClose}
					stonlyPrefix="transaction-details"
				>
					<TransactionDrawer
						loading={loading}
						transaction={transactionActual}
						disableLink
					/>
				</T4Drawer>
			</EditRuleProvider>
		);
	},
);
