import { Box, Grid, Typography, useTheme } from '@mui/material';
import { PaymentParty } from 'modules/clients/apiGateway/payments4/payments';

export const PaymentPartyInformation: React.FC<{
	party: PaymentParty | null;
	partyType: 'Initiator' | 'Payee';
}> = ({ party, partyType }) => {
	const theme = useTheme();
	const rightColumnSx = {
		textAlign: 'right',
	};

	return (
		<>
			<Grid container item xs={12}>
				<Grid item xs={6}>
					<Typography variant="body1" sx={{ fontWeight: 500 }}>
						{partyType} {partyType === 'Initiator' ? 'Entity' : ''} Name
					</Typography>
				</Grid>
				<Grid item xs={6} sx={rightColumnSx}>
					<Typography variant="body1">{party?.displayName}</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs={6}>
					<Typography variant="body1" sx={{ fontWeight: 500 }}>
						{partyType} Counterparty
					</Typography>
				</Grid>
				<Grid item xs={6} sx={rightColumnSx}>
					<Typography variant="body1">{party?.bank.displayName}</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs={6}>
					<Typography variant="body1" sx={{ fontWeight: 500 }}>
						{partyType} Account
					</Typography>
				</Grid>
				<Grid item xs={6} sx={rightColumnSx}>
					<Typography variant="body1">{party?.accountDisplayName}</Typography>
				</Grid>
			</Grid>

			<Grid container item xs={12}>
				<Box
					sx={{
						backgroundColor: theme.palette.cornflower[50],
						padding: '.5rem',
						borderRadius: '4px',
						fontSize: '.75rem',
						color: theme.palette.charcoal[500],
						marginTop: '0px !important',
					}}
				>
					<Grid item container xs={12} spacing={1}>
						<Grid item xs={12}>
							<Typography sx={{ fontSize: 'inherit' }}>
								Bank Code: {party?.bank.bankCode}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography sx={{ fontSize: 'inherit' }}>
								Bank Country Code: {party?.bank.countryCode}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography sx={{ fontSize: 'inherit' }}>
								Routing Code: {party?.bank.aba}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography sx={{ fontSize: 'inherit' }}>
								Payment Account Number: {party?.accountNumber}
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</>
	);
};
