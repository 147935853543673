import { Grid } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { AdministrationRoutes } from 'features/administration/administrationRoutes';
import { Cash4Layout } from 'features/cash4/cash4Layout';
import Cash4DisabledPage from 'features/cash4/disabledPage/cash4DisabledPage';
import { Entity4Routes } from 'features/entity4/entity4Routes';
import Payments4DisabledPage from 'features/payments4/payments4DisabledPage';
import { Payments4Layout } from 'features/payments4/payments4Layout';
import { observer } from 'mobx-react-lite';
import { Error } from 'pages/error';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import SolutionsPage from './features/solutions/solutionsPage';
import { CannotDisplay } from './shared/components/cannotDisplay';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from './shared/constants/cannotDisplayMessaging';
import { paths } from './shared/constants/paths';

LicenseInfo.setLicenseKey(
	'3ac8b035825e0898f4f780f4f3fc84dbTz05ODA2MCxFPTE3NTc3OTQ1MjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
);

export const App: FC = observer(() => {
	const { payments4Module, cash4Enabled } = useT4FeatureFlags();

	return (
		<Switch>
			<Route exact path={paths.root.href}>
				<SolutionsPage />
			</Route>
			<Route exact path={paths.error.href}>
				<Error />
			</Route>
			<Route exact path={paths.accessDenied.href}>
				<Grid container sx={{ height: '100vh' }}>
					<CannotDisplay
						headingText={ACCESS_DENIED_MESSAGING.HEADING}
						bodyText={ACCESS_DENIED_MESSAGING.BODY}
						imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
					/>
				</Grid>
			</Route>
			<Route path={paths.administration.href}>
				<AdministrationRoutes />
			</Route>
			<Route path={paths.cash4.href}>
				{cash4Enabled ? <Cash4Layout /> : <Cash4DisabledPage />}
			</Route>
			<Route path={paths.entity4.href}>
				<Entity4Routes />
			</Route>
			<Route path={paths.payments4.href}>
				{payments4Module ? <Payments4Layout /> : <Payments4DisabledPage />}
			</Route>
			<Route>
				<Grid container sx={{ height: '100vh' }}>
					<CannotDisplay
						headingText={NOT_FOUND_MESSAGING.HEADING}
						bodyText={NOT_FOUND_MESSAGING.BODY}
						imageSrc={NOT_FOUND_MESSAGING.IMAGE}
						buttonText={RETURN_TO_HOME}
						buttonHref={paths.root.href}
					/>
				</Grid>
			</Route>
		</Switch>
	);
});
