import { Delete } from '@mui/icons-material';
import { Card, IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { SigmaElement } from 'modules/clients/customer-api/src/sigmaEmbed';
import { FC } from 'react';
import { useSigmaDashboard } from '../_providers/dashboardProvider';
import { SigmaEmbed } from './sigmaEmbed';

export type DashboardCardProps = {
	isEditMode: boolean;
	sigmaElement: SigmaElement;
	onDelete: (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		item: SigmaElement,
	) => void;
};

export const DashboardCard: FC<DashboardCardProps> = observer(
	({ isEditMode, sigmaElement, onDelete }) => {
		const { hardcodedComponents } = useSigmaDashboard();
		return (
			<>
				{isEditMode && (
					<IconButton
						size="small"
						onMouseDown={(event) => {
							onDelete(event, sigmaElement);
						}}
						sx={(theme) => ({
							zIndex: 5,
							position: 'absolute',
							right: 2,
							top: 2,
							color: 'white',
							backgroundColor: theme.palette.error.main,
							'&:hover': {
								backgroundColor: theme.palette.error.light,
							},
						})}
					>
						<Delete />
					</IconButton>
				)}

				{sigmaElement.workbookId !== 'hardcoded' ? (
					<SigmaEmbed sigmaVisual={sigmaElement} />
				) : (
					<Card
						key={sigmaElement.elementId}
						elevation={0}
						sx={{
							height: '90%',
							justifyContent: 'center',
							alignItems: 'center',
							margin: 2,
							objectFit: 'contain',
						}}
					>
						{
							hardcodedComponents[
								sigmaElement.elementId as keyof typeof hardcodedComponents
							]
						}
					</Card>
				)}
			</>
		);
	},
);
