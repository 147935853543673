import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { SigmaEmbed } from '../_components/sigmaEmbed';
import { SigmaProvider } from '../_providers/sigmaProvider';

export const SigmaPage: FC = observer(() => (
	<SigmaProvider>
		<SigmaEmbed />
	</SigmaProvider>
));
