import { Party } from 'modules/clients/customer-api/src/api/cash4';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useT4Query, UseT4QueryProps } from '../_shared/_utilities/useT4Query';

export type UsePartiesQueryProps = Pick<
	UseT4QueryProps<Party[]>,
	'loading' | 'refetch'
> & {
	data: Party[];
};

export function usePartiesQuery(): UsePartiesQueryProps {
	const { customerApiClient } = useClients();

	const query = useCallback(async () => {
		const response = await customerApiClient.api.cash4.parties();

		return response?.data?.data || [];
	}, [customerApiClient.api.cash4]);

	const { loading, data, refetch } = useT4Query(query);

	return {
		loading: loading,
		data: data ?? [],
		refetch: refetch,
	};
}
