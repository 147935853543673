export const AccountMapVisualizationKey = 'accountMap';

export const DisplayStandaloneAccountsKey = 'displayStandaloneAccounts';
export const DisplaySubaccountsKey = 'displaySubaccounts';
export const DisplayClosedAccountsKey = 'displayClosedAccounts';
export const DisplayFundingStructureLabelsKey = 'displayFundingStructureLabels';
export const DisplayLegendKey = 'displayLegend';
export const DisplayOverviewMapKey = 'displayOverviewMap';

export const CardOptionsDisplayKey = 'cardOptionsColor';
export const CardOptionUserDefinedColorKey = 'userDefinedColor';

export const AccountTypeKey = 'accountType';
export const AccountPurposeKey = 'purpose';
export const AccountStatusKey = 'accountStatus';
export const AccountRegionKey = 'entityRegion';
export const AccountOpenDateKey = 'openDate';
export const AccountCloseDateKey = 'closeDate';
export const AccountGLNumberKey = 'generalLedgerAccountNumber';
export const CounterpartyBranchKey = 'counterpartyBranch';
export const AccountErpCodeKey = 'entityErpCode';

export const DisplayAccountTypeKey = 'displayAccountType';
export const DisplayForeignAccountKey = 'displayForeignAccount';
export const DisplayFlagKey = 'displayFlag';

export const AllChartOptions = [
	DisplayStandaloneAccountsKey,
	DisplaySubaccountsKey,
	DisplayClosedAccountsKey,
	DisplayLegendKey,
	DisplayOverviewMapKey,
];

export const ChartOptionsMap = new Map([
	[DisplayStandaloneAccountsKey, 'Standalone Accounts'],
	[DisplaySubaccountsKey, 'Subaccounts'],
	[DisplayClosedAccountsKey, 'Closed Accounts'],
	[DisplayLegendKey, 'Display Legend'],
	[DisplayOverviewMapKey, 'Display Overview Map'],
]);

export const Icons: Map<string, string> = new Map([
	[
		'corporate credit card program',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z"/></svg>',
	],
	[
		'crypto wallet',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M360-120v-80H240v-80h80v-400h-80v-80h120v-80h80v80h80v-80h80v85q52 14 86 56.5t34 98.5q0 29-10 55.5T682-497q35 21 56.5 57t21.5 80q0 66-47 113t-113 47v80h-80v-80h-80v80h-80Zm40-400h160q33 0 56.5-23.5T640-600q0-33-23.5-56.5T560-680H400v160Zm0 240h200q33 0 56.5-23.5T680-360q0-33-23.5-56.5T600-440H400v160Z"/></svg>',
	],
	[
		'custody',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-80 120-280v-400l360-200 360 200v400L480-80ZM364-590q23-24 53-37t63-13q33 0 63 13t53 37l120-67-236-131-236 131 120 67Zm76 396v-131q-54-14-87-57t-33-98q0-11 1-20.5t4-19.5l-125-70v263l240 133Zm40-206q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400Zm40 206 240-133v-263l-125 70q3 10 4 19.5t1 20.5q0 55-33 98t-87 57v131Z"/></svg>',
	],
	[
		'demand deposit account',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M120-120v-200h80v120h120v80H120Zm520 0v-80h120v-120h80v200H640Zm-160-80q-116 0-198-82t-82-198q0-116 82-198t198-82q116 0 198 82t82 198q0 116-82 198t-198 82ZM120-640v-200h200v80H200v120h-80Zm640 0v-120H640v-80h200v200h-80Z"/></svg>',
	],
	[
		'derivative trading',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M240-120 80-600l400-280 400 280-160 480H240Z"/></svg>',
	],
	[
		'escrow',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m606-286-33-144 111-96-146-13-58-136v312l126 77ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"/></svg>',
	],
	[
		'fdic insured cash deposit',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-280q83 0 141.5-58.5T680-480q0-83-58.5-141.5T480-680q-83 0-141.5 58.5T280-480q0 83 58.5 141.5T480-280Zm0 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>',
	],
	[
		'interest bearing account',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm280-80h280v-560H480v560Z"/></svg>',
	],
	[
		'intrafi - cdars',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m80-160 400-640 400 640H80Zm144-80h512L480-650 224-240Zm256-205Z"/></svg>',
	],
	[
		'intrafi - ics',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m80-160 400-640 400 640H80Z"/></svg>',
	],
	[
		'investment',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>',
	],
	[
		'investment - money market fund',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-540q-33 0-56.5-23.5T120-620v-140q0-33 23.5-56.5T200-840h140q33 0 56.5 23.5T420-760v140q0 33-23.5 56.5T340-540H200Zm0 420q-33 0-56.5-23.5T120-200v-140q0-33 23.5-56.5T200-420h140q33 0 56.5 23.5T420-340v140q0 33-23.5 56.5T340-120H200Zm420-420q-33 0-56.5-23.5T540-620v-140q0-33 23.5-56.5T620-840h140q33 0 56.5 23.5T840-760v140q0 33-23.5 56.5T760-540H620Zm0 420q-33 0-56.5-23.5T540-200v-140q0-33 23.5-56.5T620-420h140q33 0 56.5 23.5T840-340v140q0 33-23.5 56.5T760-120H620Z"/></svg>',
	],
	[
		'investment - other',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-360h560v-280H200v280Z"/></svg>',
	],
	[
		'line of credit',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-120v-640q0-33 23.5-56.5T280-840h400q33 0 56.5 23.5T760-760v640L480-240 200-120Z"/></svg>',
	],
	[
		'loan',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-520q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM280-120q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400 0q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Z"/></svg>',
	],
	[
		'lockbox account',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-280v-400q-83 0-141.5 58.5T280-480q0 83 58.5 141.5T480-280Zm0 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>',
	],
	[
		'merchant services',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M320-320h320v-320H320v320ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>',
	],
	[
		'public',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm80-80h480v-320H240v320Zm-80 80v-480 480Z"/></svg>',
	],
	[
		'purchasing card',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m480-193 85-85 57 56L480-80 338-222l57-56 85 85ZM193-480l85 85-56 57L80-480l142-142 56 57-85 85Zm574 0-85-85 56-57 142 142-142 142-56-57 85-85ZM480-767l-85 85-57-56 142-142 142 142-57 56-85-85Z"/></svg>',
	],
	[
		'restricted cash',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm240-200q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80Z"/></svg>',
	],
	[
		'savings',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M840-480 666-234q-11 16-28.5 25t-37.5 9H200q-33 0-56.5-23.5T120-280v-400q0-33 23.5-56.5T200-760h400q20 0 37.5 9t28.5 25l174 246Zm-98 0L600-680H200v400h400l142-200Zm-542 0v200-400 200Z"/></svg>',
	],
	[
		'special purpose account',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 280q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>',
	],
	[
		't&e card',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M280-280h160v-160H280v160Zm240 0h160v-160H520v160ZM280-520h160v-160H280v160Zm240 0h160v-160H520v160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Z"/></svg>',
	],
	[
		'trust',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M640-280q83 0 141.5-58.5T840-480q0-83-58.5-141.5T640-680q-27 0-52.5 7T540-653q29 36 44.5 80t15.5 93q0 49-15.5 93T540-307q22 13 47.5 20t52.5 7Zm-160-80q19-25 29.5-55.5T520-480q0-34-10.5-64.5T480-600q-19 25-29.5 55.5T440-480q0 34 10.5 64.5T480-360Zm-160 80q27 0 52.5-7t47.5-20q-29-36-44.5-80T360-480q0-49 15.5-93t44.5-80q-22-13-47.5-20t-52.5-7q-83 0-141.5 58.5T120-480q0 83 58.5 141.5T320-280Zm0 80q-117 0-198.5-81.5T40-480q0-117 81.5-198.5T320-760q45 0 85.5 13t74.5 37q34-24 74.5-37t85.5-13q117 0 198.5 81.5T920-480q0 117-81.5 198.5T640-200q-45 0-85.5-13T480-250q-34 24-74.5 37T320-200Z"/></svg>',
	],
	[
		'virtual account',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-120q-33 0-56.5-23.5T120-200v-360h720v360q0 33-23.5 56.5T760-120H200Zm-80-520v-120q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v120H120Z"/></svg>',
	],
	[
		'virtual card',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h80v-480h-80v480Zm560 0h80v-480h-80v480Z"/></svg>',
	],
	[
		'virtual summary account',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q64 0 123-24t104-69L480-480v-320q-134 0-227 93t-93 227q0 134 93 227t227 93Z"/></svg>',
	],
	[
		'virtual transaction account',
		'<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M320-240h320v-200H320v200Zm0-280h320v-200H320v200Zm480 360q0 33-23.5 56.5T720-80H240q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h480q33 0 56.5 23.5T800-800v640Zm-80 0v-640H240v640h480Zm0-640H240h480Z"/></svg>',
	],
]);

export const AccountPurposeColors: Map<string, string> = new Map([
	['Accounts Receivable', '#a6c09a'],
	['Collections', '#91c4a7'],
	['Concentration', '#a7c7d2'],
	['Custody', '#c5a8c3'],
	['Holding Deposit', '#8aa4b8'],
	['Escrow', '#fbc59c'],
	['Investments Brokerage', '#e3d69d'],
	['Merchant Services A/R', '#f6a3a2'],
	['Operating', '#d1ebfd'],
	['Payables', '#fbcee5'],
	['Payables - Bill.com', '#fbcea9'],
	['Payables - Billtrust', '#e5fac6'],
	['Payables - Expensify', '#e3dddd'],
	['Payroll', '#fbfbb8'],
	['Savings', '#a3d7a1'],
	['Special Purpose - Capital Contributions', '#d7add4'],
	['Special Purpose - ESPP Contributions', '#b3c4d0'],
	['Special Purpose - Intercompany Funding', '#bad2f2'],
	['Special Purpose - Intracompany Funding', '#d5c3c4'],
	['Special Purpose - Reconciliation', '#e6b2bd'],
	['Special Purpose - Sweep Activity', '#c9f7f4'],
	['Special Purpose - Tax Payments', '#c8e4ce'],
	['No Purpose Defined', 'white'],
]);

export const AllAccountDetailOptions = [
	AccountStatusKey,
	AccountOpenDateKey,
	AccountCloseDateKey,
	AccountTypeKey,
	AccountPurposeKey,
];

export const AccountDetailOptionsMap = new Map([
	[AccountStatusKey, 'Account Status'],
	[AccountOpenDateKey, 'Open Date'],
	[AccountCloseDateKey, 'Close Date'],
	[AccountTypeKey, 'Account Type'],
	[AccountPurposeKey, 'Account Purpose'],
]);

export const AllCounterpartyDetailOptions = [CounterpartyBranchKey];

export const CounterpartyDetailOptionsMap = new Map([
	[CounterpartyBranchKey, 'Counterparty Branch'],
]);

export const AllEntityDetailOptions = [
	AccountRegionKey,
	AccountErpCodeKey,
	AccountGLNumberKey,
];

export const EntityDetailOptionsMap = new Map([
	[AccountRegionKey, 'Entity Region'],
	[AccountErpCodeKey, 'ERP Code'],
	[AccountGLNumberKey, 'GL Number'],
]);

export const RegionColors: Map<string, string> = new Map([
	['Africa', '#a6c09a'],
	['Americas', '#a7c7d2'],
	['Antarctica', '#c5a8c3'],
	['Asia', '#8aa4b8'],
	['Asia Pacific', '#fbc59c'],
	['Europe', '#e3d69d'],
	['Europe, Middle East & Africa', '#f6a3a2'],
	['Latin America', '#d1ebfd'],
	['North America', '#fbcee5'],
	['Oceania', '#fbcea9'],
	['South America', '#e5fac6'],
	['United Kingom', '#91c4a7'],
]);

export const AccountStatusColors: Map<string, string> = new Map([
	['Active', '#a6c09a'],
	['Closed', '#a7c7d2'],
	['Pending Open', '#c5a8c3'],
	['Pending Close', '#8aa4b8'],
]);
