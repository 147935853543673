import { useSigma } from 'features/_shared/_providers/sigmaProvider';
import { SigmaFolder } from 'modules/clients/customer-api/src/sigmaEmbed';
import { useCallback, useEffect, useState } from 'react';
import { useClients } from './useClients';

export type UseEditReportDetailsButtonProps = {
	isLoading: boolean;
	error: string;
	selectedFolder: SigmaFolder | undefined;
	name: string;
	description: string;
	setFolder: (value: SigmaFolder | undefined) => void;
	setName: (value: string) => void;
	setDescription: (value: string) => void;
	resetForm: () => void;
	onSubmit: (onSuccess: () => void) => Promise<void>;
};

export function useEditReportDetailsButton(): UseEditReportDetailsButtonProps {
	const { customerApiClient } = useClients();
	const { folders, workbooks, workbook, updateWorkbook } = useSigma();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [selectedFolder, setSelectedFolder] = useState<SigmaFolder>();

	const resetForm = useCallback(() => {
		setIsLoading(false);
		setName('');
		setError('');
		setDescription('');
		setSelectedFolder(undefined);
	}, []);

	const onSubmit = useCallback(
		async (onSuccess: () => void) => {
			if (workbook) {
				const nameActual = name.trim() !== '' ? name : workbook.name;
				const folderActual =
					selectedFolder ?? folders.find((x) => x.id === workbook.folderId);
				const isNameUnique = !workbooks.some(
					(x) => x.name === nameActual && x.folderId === selectedFolder?.id,
				);
				if (folderActual) {
					if (isNameUnique) {
						setIsLoading(true);
						try {
							await customerApiClient.sigmaEmbed.editFile({
								fileId: workbook.id,
								name: nameActual,
								description:
									description.trim() !== ''
										? description
										: workbook.description,
								parentId: folderActual.id,
							});
							updateWorkbook({
								id: workbook.id,
								name: nameActual,
								description: description ?? workbook.description,
								folderId: folderActual.id,
								urlId: workbook.urlId,
								permission: workbook.permission,
								updatedAt: workbook.updatedAt,
							});
							onSuccess();
						} catch {
							setError('Unable to edit report details.');
						} finally {
							setIsLoading(false);
						}
					} else {
						setError('Report with this name already exists in this folder.');
					}
				} else {
					setError('No folder selected.');
				}
			} else {
				setError('No report selected.');
			}
		},
		[
			customerApiClient.sigmaEmbed,
			description,
			folders,
			name,
			selectedFolder,
			updateWorkbook,
			workbook,
			workbooks,
		],
	);

	useEffect(() => {
		return () => resetForm();
	}, [resetForm]);

	return {
		isLoading: isLoading,
		error: error,
		selectedFolder: selectedFolder,
		name: name,
		description: description,
		setFolder: setSelectedFolder,
		setName,
		setDescription,
		resetForm: resetForm,
		onSubmit: onSubmit,
	};
}
