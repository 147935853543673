import {
	useT4Query,
	UseT4QueryProps,
} from 'features/cash4/_shared/_utilities/useT4Query';
import { Cash4Configurations } from 'modules/clients/customer-api/src/api/administration';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseCash4ConfigurationsQueryProps =
	UseT4QueryProps<Cash4Configurations>;

export function useCash4ConfigurationsQuery(): UseCash4ConfigurationsQueryProps {
	const { customerApiClient } = useClients();

	const getCash4Configurations = useCallback(async () => {
		const response =
			await customerApiClient.api.administration.cash4Configurations();

		if (response && response.data && response.data.data) {
			return response.data.data;
		} else {
			throw new Error('Failed to load Cash4 configurations.');
		}
	}, [customerApiClient.api.administration]);
	const queryContext = useT4Query<Cash4Configurations>(getCash4Configurations, {
		errorMessage: 'Failed to load Cash4 configurations.',
	});

	return queryContext;
}
