import { Box, CircularProgress } from '@mui/material';
import { useMoneyMarketsQuery } from 'features/administration/_shared/_hooks/useMoneyMarketsQuery';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { paths } from 'shared/constants/paths';
import { MoneyPortal } from './_components/moneyPortal';

export type PortalsRouteProps = {};

export const PortalsRoute: FC<PortalsRouteProps> = observer(() => {
	const moneyMarketsQuery = useMoneyMarketsQuery();

	if (moneyMarketsQuery.loading) {
		return (
			<Box sx={{ width: '100%', display: 'flex', alignContent: 'center' }}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Switch>
			{moneyMarketsQuery.data?.map((moneyMarket) => (
				<Route
					key={moneyMarket.id}
					path={paths.cash4.portals.href.concat(
						`/${moneyMarket.name.toLowerCase().replaceAll(' ', '-')}`,
					)}
					exact
				>
					<MoneyPortal moneyMarket={moneyMarket} />
				</Route>
			))}
		</Switch>
	);
});
