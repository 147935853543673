import {
	ProjectedItem,
	Reconciliation,
	ReportedItem,
} from 'modules/clients/customer-api/src/api/cash4';
import { useMemo } from 'react';

enum ReconciliationStatus {
	Unreconciled = 'Unreconciled',
	PartiallyReconciled = 'PartiallyReconciled',
	Reconciled = 'Reconciled',
	Posted = 'Posted',
}

enum FlowDirection {
	Inflow = 'Inflow',
	Outflow = 'Outflow',
}

export function getCurrencyCode(
	reconciliation:
		| Reconciliation
		| [projectedItems: ProjectedItem[], reportedItems: ReportedItem[]]
		| undefined,
) {
	let projectedItems: ProjectedItem[] = [];
	let reportedItems: ReportedItem[] = [];
	if (Array.isArray(reconciliation)) {
		const [_projectedItems, _reportedItems] = reconciliation;
		projectedItems = _projectedItems;
		reportedItems = _reportedItems;
	} else {
		projectedItems = reconciliation?.projectedItems ?? [];
		reportedItems = reconciliation?.reportedItems ?? [];
	}

	const currencyCodes = new Map<string, number>();
	projectedItems.forEach((item) => {
		currencyCodes.set(
			item.currencyCode,
			(currencyCodes.get(item.currencyCode) ?? 0) + item.amount,
		);
	});
	reportedItems.forEach((item) => {
		if (item.currencyCode) {
			currencyCodes.set(
				item.currencyCode,
				(currencyCodes.get(item.currencyCode) ?? 0) + item.amount,
			);
		}
	});

	let count = 0;
	let currencyCode = 'USD';
	currencyCodes.forEach((value, key) => {
		if (value > count) {
			count = value;
			currencyCode = key;
		}
	});

	return currencyCode;
}

export function calculateVariance(reconciliation: Reconciliation) {
	const projectedAmount = reconciliation.projectedItems.reduce(
		(acc, item) => acc + item.amount,
		0,
	);
	const reportedAmount = reconciliation.reportedItems.reduce(
		(acc, item) => acc + item.amount,
		0,
	);

	const max = Math.max(projectedAmount, reportedAmount);
	const min = Math.min(projectedAmount, reportedAmount);

	if (max === 0 && min === 0) {
		return 0;
	} else if (max === 0) {
		return 1;
	}

	return (max - min) / max;
}

export function calculateDifference(reconciliation: Reconciliation) {
	const projectedAmount = reconciliation.projectedItems.reduce(
		(acc, item) => acc + item.amount,
		0,
	);
	const reportedAmount = reconciliation.reportedItems.reduce(
		(acc, item) => acc + item.amount,
		0,
	);

	return Math.abs(projectedAmount - reportedAmount);
}

export function calculateAmount(reconciliation: Reconciliation): number {
	return Math.max(
		...[
			reconciliation.projectedItems.reduce<number>(
				(acc, item) =>
					acc +
					(item.reconciliationStatus === ReconciliationStatus.Reconciled
						? item.amount *
						  (item.flowDirection === FlowDirection.Outflow ? -1 : 1)
						: 0),
				0,
			),
			reconciliation.reportedItems.reduce<number>(
				(acc, item) =>
					acc +
					(item.reconciliationStatus === ReconciliationStatus.Reconciled
						? item.amount
						: 0),
				0,
			),
		],
	);
}

export type UseReconciliationCalculationsProps = {
	projectedAmount: number;
	reportedAmount: number;
	records: number;
	difference: number;
	variance: number;
};

export function useReconciliationCalculations(
	reconciliation: Partial<Reconciliation> | undefined,
): UseReconciliationCalculationsProps {
	const projectedAmount = useMemo<
		UseReconciliationCalculationsProps['projectedAmount']
	>(() => {
		return (
			reconciliation?.projectedItems?.reduce(
				(acc, item) => acc + item.amount,
				0,
			) ?? 0
		);
	}, [reconciliation?.projectedItems]);

	const reportedAmount = useMemo<
		UseReconciliationCalculationsProps['reportedAmount']
	>(() => {
		return (
			reconciliation?.reportedItems?.reduce(
				(acc, item) => acc + item.amount,
				0,
			) ?? 0
		);
	}, [reconciliation?.reportedItems]);

	const records = useMemo<UseReconciliationCalculationsProps['records']>(() => {
		return (
			(reconciliation?.projectedItems?.length ?? 0) +
			(reconciliation?.reportedItems?.length ?? 0)
		);
	}, [
		reconciliation?.projectedItems?.length,
		reconciliation?.reportedItems?.length,
	]);

	const difference = useMemo<
		UseReconciliationCalculationsProps['difference']
	>(() => {
		const max = Math.max(projectedAmount, reportedAmount);
		const min = Math.min(projectedAmount, reportedAmount);

		return max - min;
	}, [projectedAmount, reportedAmount]);

	const variance = useMemo<
		UseReconciliationCalculationsProps['variance']
	>(() => {
		const getPercentage = (value: number) => {
			return Math.round(value * 10000) / 100;
		};

		const max = Math.max(projectedAmount, reportedAmount);
		const min = Math.min(projectedAmount, reportedAmount);
		if (max === 0 && min === 0) {
			return getPercentage(0);
		} else if (max === 0) {
			return getPercentage(1);
		}

		return getPercentage((max - min) / max);
	}, [projectedAmount, reportedAmount]);

	return {
		projectedAmount: projectedAmount,
		reportedAmount: reportedAmount,
		records: records,
		difference: difference,
		variance: variance,
	};
}
