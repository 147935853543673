import {
	Payment,
	PaymentApprovalLevelStatusTypes,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';

export const getApprovalStatusText = (payment: Payment | undefined): string => {
	if (!payment?.approvalState || payment.approvalState === null) return '-';

	if (
		payment.approvalState.currentStatus !==
		PaymentApprovalLevelStatusTypes[PaymentApprovalLevelStatusTypes.Pending]
	)
		return (
			payment.approvalState.currentStatus ??
			'Approval status is currently unavailable.'
		); // null coalesce string handles the edge case where approval state exists but no status is set

	const completedLevelCount =
		payment?.approvalState?.paymentApprovalLevelStates.filter(
			(levelState) =>
				levelState.status ===
				PaymentApprovalLevelStatusTypes[
					PaymentApprovalLevelStatusTypes.Approved
				],
		).length ?? 0;
	return `${completedLevelCount} of ${payment?.approvalState?.paymentApprovalLevelStates.length} approvals`;
};

export const getPaymentStatusDisplayName = (
	value: PaymentStatusTypes | string | undefined,
) => {
	if (value === undefined) return;

	if (
		value === PaymentStatusTypes.Created ||
		value === PaymentStatusTypes[PaymentStatusTypes.Created]
	)
		return 'No Matching Tier';
	else if (
		value === PaymentStatusTypes.Submitted ||
		value === PaymentStatusTypes[PaymentStatusTypes.Submitted]
	)
		return 'Pending Approval';
	else {
		if (typeof value !== 'string') return PaymentStatusTypes[value];

		const stringKeys = Object.keys(PaymentStatusTypes).filter((x) =>
			isNaN(Number(x)),
		);
		if (stringKeys.find((x) => x === value) !== undefined) return value;
	}

	return undefined;
};
