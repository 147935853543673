import axios from 'axios';
import { Cash4ConfigurationsProvider } from 'features/_shared/_providers/cash4ConfigurationsProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { responseInterceptor } from 'shared/functions/responseInterceptor';
import { ApplicationGate } from 'shared/providers/applicationGate';
import { HeaderContextProvider } from 'shared/providers/contexts/headerContext';
import { LegalEntityGroupsProvider } from 'shared/providers/legalEntityGroupsProvider';
import { T4ApplicationConfigurationProvider } from 'shared/providers/t4ApplicationConfigurationProvider';
import { T4ApplicationInsightsProvider } from 'shared/providers/t4ApplicationInsightsProvider';
import { T4ClientsProvider } from 'shared/providers/t4ClientsProvider';
import { T4ConfigurationsProvider } from 'shared/providers/t4ConfigurationsProvider';
import { T4LanchDarklyProvider } from 'shared/providers/t4LaunchDarklyProvider';
import { T4LayoutProvider } from 'shared/providers/t4LayoutProvider';
import { T4SnackbarProvider } from 'shared/providers/t4SnackbarProvider';
import { T4ThemeProvider } from 'shared/providers/t4ThemeProvider';
import { TagsProvider } from 'shared/providers/tagsProvider';
import { App } from './app';
import { DarkModeContextProvider } from './shared/providers/contexts/darkModeContext';
import { T4Auth0Provider } from './shared/providers/t4Auth0Provider';

axios.interceptors.response.use(responseInterceptor);
axios.interceptors.request.use((value) => {
	value.validateStatus = (_status) => true;

	return value;
});

// eslint-disable-next-line no-console
console.log(`${process.env.REACT_APP_BUILD_NUMBER}`);

declare global {
	interface Window {
		STONLY_WID: string;
		StonlyWidget: any;
		stonlyTrack: any;
	}
}

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<T4ConfigurationsProvider>
				<T4ApplicationInsightsProvider>
					<T4Auth0Provider>
						<T4LanchDarklyProvider>
							<T4ClientsProvider>
								<T4ApplicationConfigurationProvider>
									<T4LayoutProvider>
										<DarkModeContextProvider>
											<T4ThemeProvider>
												<ApplicationGate>
													<T4SnackbarProvider>
														<LegalEntityGroupsProvider>
															<TagsProvider>
																<HeaderContextProvider>
																	<Cash4ConfigurationsProvider>
																		<App />
																	</Cash4ConfigurationsProvider>
																</HeaderContextProvider>
															</TagsProvider>
														</LegalEntityGroupsProvider>
													</T4SnackbarProvider>
												</ApplicationGate>
											</T4ThemeProvider>
										</DarkModeContextProvider>
									</T4LayoutProvider>
								</T4ApplicationConfigurationProvider>
							</T4ClientsProvider>
						</T4LanchDarklyProvider>
					</T4Auth0Provider>
				</T4ApplicationInsightsProvider>
			</T4ConfigurationsProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root'),
);
