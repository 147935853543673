import { Button, Checkbox, Grid } from '@mui/material';
import { useSigma } from 'features/_shared/_providers/sigmaProvider';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { FormModal } from 'shared/components/formModal';
import { useRecentlyDeleted } from 'shared/hooks/useRecentlyDeletedReports';

export type RecentlyDeletedProps = {
	isOpen: boolean;
	close: () => void;
};

export const RecentlyDeleted: FC<RecentlyDeletedProps> = observer(
	({ isOpen, close }) => {
		const { recentlyDeletedWorkbooks } = useSigma();
		const {
			isLoading,
			loadingMessage,
			resetForm,
			error,
			onSubmit,
			onDelete,
			selectedWorkbooks,
			modifySelectedWorkbooks,
		} = useRecentlyDeleted();

		const handleClose = useCallback(() => {
			resetForm();
			close();
		}, [close, resetForm]);

		const handleSubmit = useCallback(() => {
			onSubmit(handleClose);
		}, [handleClose, onSubmit]);

		const handleDelete = useCallback(() => {
			onDelete(handleClose);
		}, [handleClose, onDelete]);

		return (
			<FormModal
				open={isOpen}
				onClose={handleClose}
				onSubmit={() => handleSubmit()}
				fullWidth
				title={`Recently Deleted Reports (${recentlyDeletedWorkbooks.length})`}
				loading={isLoading}
				submitButtonLabel="Restore"
				cancelButtonOnly
				loadingMessage={loadingMessage}
			>
				<Grid container direction="column">
					{recentlyDeletedWorkbooks.map((workbook) => (
						<Grid item key={workbook.id} container alignItems="center">
							<Checkbox
								checked={selectedWorkbooks.includes(workbook)}
								onChange={(_, checked) =>
									modifySelectedWorkbooks(workbook, checked)
								}
							/>
							<div>{workbook.name}</div>
						</Grid>
					))}
				</Grid>
				<Grid container style={{ marginTop: 16 }} justifyContent="center">
					<Grid item xs={4}>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => handleDelete()}
							disabled={selectedWorkbooks.length === 0}
						>
							Delete Selected
						</Button>
					</Grid>
					<Grid item xs={4}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => handleSubmit()}
							disabled={selectedWorkbooks.length === 0}
						>
							Restore Selected
						</Button>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<T4AlertStack error={error} />
				</Grid>
			</FormModal>
		);
	},
);
