import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useT4Parameter } from '../_shared/_utilities/useT4Parameter';
import { useT4Query, UseT4QueryProps } from '../_shared/_utilities/useT4Query';
import { TransactionListItem } from '../transactions/models';
import { getTransactionListItem } from '../transactions/utilities';

export type UseTransactionQueryProps = Pick<
	UseT4QueryProps<TransactionListItem | undefined>,
	'loading' | 'refetch'
> & {
	transaction: TransactionListItem | undefined;
};

export function useTransactionQuery(
	transaction: TransactionListItem | string | undefined,
): UseTransactionQueryProps {
	const { customerApiClient } = useClients();
	const { property: transactionActual } = useT4Parameter(transaction);

	const query = useCallback(async () => {
		if (typeof transactionActual === 'string') {
			const response = await customerApiClient.transactions.getTransaction({
				transactionId: transactionActual,
			});

			if (response?.data?.value) {
				return getTransactionListItem(response.data.value);
			}
		} else {
			return transactionActual;
		}
	}, [customerApiClient.transactions, transactionActual]);
	const { loading, data: transactionResult, refetch } = useT4Query(query);

	return {
		loading: loading,
		transaction: transactionResult,
		refetch: refetch,
	};
}
