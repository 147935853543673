import { Breakpoint } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, Fragment, useEffect, useState } from 'react';
import { FormModal } from 'shared/components/formModal';

export type ConfirmFormModalProps = {
	loading: boolean;
	isOpen: boolean;
	isDirty: boolean;
	disableSubmit: boolean;
	modalTitle: string;
	modalDescription?: string;
	cancelModalTitle: string;
	cancelModalDescription: string;
	cancelButtonColor?: 'error' | 'warning';
	onOpen?: () => void;
	onSubmit: () => void;
	onClose: () => void;
	submitButtonLabel?: string;
	backButtonDisabled?: boolean;
	showBackButton?: boolean;
	onBackButtonClick?: () => void;
	discardEdits?: () => void;
	error?: string;
	maxWidth?: Breakpoint | false;
};

export const ConfirmFormModal: FC<ConfirmFormModalProps> = observer(
	({
		loading,
		isOpen,
		isDirty,
		disableSubmit,
		modalTitle,
		modalDescription,
		cancelModalTitle,
		cancelModalDescription,
		cancelButtonColor,
		onOpen,
		onSubmit,
		onClose,
		submitButtonLabel,
		error,
		showBackButton,
		backButtonDisabled,
		onBackButtonClick,
		discardEdits,
		maxWidth,
		children,
	}) => {
		const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

		useEffect(() => {
			if (isOpen && onOpen) {
				onOpen();
			}
		}, [isOpen]);

		return (
			<Fragment>
				<FormModal
					loading={loading}
					open={isOpen}
					onClose={() => {
						if (loading) return;

						if (!isDirty) {
							setIsCancelModalOpen(false);

							if (discardEdits) discardEdits();

							onClose();
						}

						if (!isCancelModalOpen && isDirty) {
							setIsCancelModalOpen(true);
							return;
						}

						if (isCancelModalOpen && isDirty) setIsCancelModalOpen(false);
					}}
					onSubmit={async () => onSubmit()}
					cancelDisabled={loading}
					submitDisabled={disableSubmit}
					submitButtonLabel={submitButtonLabel}
					title={modalTitle}
					description={modalDescription}
					error={error}
					maxWidth={maxWidth ?? 'sm'}
					showBackButton={showBackButton}
					onBackButtonClick={() => {
						if (onBackButtonClick) onBackButtonClick();
					}}
					backButtonDisabled={backButtonDisabled}
				>
					{children}
				</FormModal>
				<FormModal
					loading={false}
					open={isCancelModalOpen}
					onClose={() => {
						setIsCancelModalOpen(false);
					}}
					onSubmit={() => {
						if (discardEdits) discardEdits();

						setIsCancelModalOpen(false);
						onClose();
					}}
					cancelButtonLabel="Keep Editing"
					submitButtonLabel="Discard Edits"
					submitButtonColor={cancelButtonColor}
					title={cancelModalTitle}
					description={cancelModalDescription}
				/>
			</Fragment>
		);
	},
);
