import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

type IconMessageProps = {
	icon: JSX.Element;
	heading?: JSX.Element;
	body?: JSX.Element;
	sx?: SxProps;
};

export const IconMessage: FC<IconMessageProps> = observer(
	({ icon, heading, body, sx }) => {
		return (
			<Box
				sx={{
					margin: 'auto',
					textAlign: 'center',
					maxWidth: '32rem',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: 2,
					...sx,
				}}
			>
				{icon}
				{heading}
				{body}
			</Box>
		);
	},
);
