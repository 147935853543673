import { Check } from '@mui/icons-material';
import {
	AppBar,
	Box,
	Button,
	ButtonProps,
	Divider,
	Grid,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Skeleton,
	Tab,
	Tabs,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Tag } from 'modules/clients/customer-api/src/entity4/tags';
import { Moment } from 'moment';
import React, { FC, ReactNode, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TagsHeaderComponent } from '../../features/entity4/shared/components/tagsHeaderComponent';
import { stonlyData } from '../../stonly/functions';
import { OverflowMenu } from './overflowMenu';

export const pageHeaderStonlyIds = {
	analyticsStudioPage: 'cash4analyticsstudio',
	balancesPage: 'balances',
	categoriesPage: 'categories',
	dataImportsPage: 'dataimports',
	rulesPage: 'rules',
	transactionsPage: 'transactions',
	approvalsPage: 'approvals',
	auditPage: 'auditlog',
	ungroupedEntitiesList: 'ungroupedentities',
	cash4ConfigurationsPage: 'cash4configurations',
	configurationPage: 'configurations',
	groupList: 'securitygroups',
	userEntitlementsList: 'userentitlements',
	administrationView: 'administration',
	dashboardPage: 'entity4analyticsstudio',
	globalDocumentListView: 'documents',
	notificationsListView: 'notifications',
	accountIntegrationsPage: 'accountintegrations',
	holdingsPage: 'holdings',
	investmentTransactionsPage: 'investment-transactions',
	sigmaStudioPage: 'sigmaStudio',

	// #region Administration

	administration: {
		payments4: {
			paymentApprovalsManagementPage: 'payment-approvals-management',
		},
	},

	// #endregion

	//#region Payments4

	payments4: {
		paymentStatusPage: 'payment-status',
		paymentApprovalsPage: 'payment-approvals',
		templatesPage: 'payment-templates',
	},

	//#endregion
};

export type PageHeaderProps = {
	id: string;
	title?: string | React.ReactNode;
	isEditMode?: boolean;
	showDoneButton?: boolean;
	showOverflowMenu?: boolean;
	lastAutoSave?: Moment;
	breadcrumbs?: ReactNode;
	actions?: {
		label: string;
		action: () => void | Promise<void>;
		startIcon?: ReactNode;
		color?: ButtonProps['color'];
		stonlyId?: string;
		testId?: string;
	}[];
	aspects?: { label: string; link: string }[];
	addAspectAction?: ReactNode;
	isAuthor?: boolean;
	entityObjectTags?: Tag[];
	addTag?: (tagName: string) => Promise<void>;
	removeTag?: (tagId: string) => void;
	shouldIncludeTagsComponent?: boolean;
	overrideCurrentTab?: any;
};

export const PageHeader: FC<PageHeaderProps> = observer(
	({
		id,
		title,
		isEditMode,
		showDoneButton = false,
		showOverflowMenu = true,
		lastAutoSave,
		aspects,
		addAspectAction,
		breadcrumbs,
		actions,
		isAuthor,
		entityObjectTags,
		addTag,
		removeTag,
		shouldIncludeTagsComponent = false,
		overrideCurrentTab,
	}) => {
		const theme = useTheme();
		const location = useLocation();

		const currentTab = useMemo<string>(
			() =>
				overrideCurrentTab ??
				(location?.pathname?.split('/')?.[4] as string) ??
				'',
			[location?.pathname, overrideCurrentTab],
		);

		return (
			<AppBar position="sticky" color="secondary">
				<Grid
					container
					sx={{
						paddingY: '1rem',
						paddingX: '2rem',
					}}
				>
					{breadcrumbs && (
						<Grid item xs={12}>
							{breadcrumbs}
						</Grid>
					)}
					<Grid
						item
						container
						direction="row"
						wrap="nowrap"
						xs={12}
						sx={{
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Grid container item xs={10}>
							<Tooltip title={title ?? ''} placement="bottom-start">
								<Typography
									variant="h2"
									color="white"
									data-testid="page-header-title"
									noWrap
									{...stonlyData({
										id: `page-header-${id.toLowerCase().replaceAll(' ', '')}`,
									})}
								>
									{title ?? <Skeleton variant="rectangular" />}
								</Typography>
							</Tooltip>
						</Grid>
						{((actions && (actions?.length ?? 0) > 0) || isEditMode) && (
							<Grid
								container
								item
								direction="row"
								wrap="nowrap"
								xs="auto"
								sx={{ gap: 2, alignItems: 'center', height: '2.5rem' }}
							>
								{isEditMode && lastAutoSave && (
									<Grid item xs="auto">
										<Typography
											sx={{
												color: 'white',
												fontSize: '0.9rem',
												textAlign: 'end',
											}}
										>
											Last autosave
										</Typography>
										<Typography
											sx={{ color: 'whitesmoke', fontSize: '0.9rem' }}
										>
											{lastAutoSave.format('h:mm a, ')}
											{lastAutoSave.format('DD-MMM-YYYY').toUpperCase()}
										</Typography>
									</Grid>
								)}
								{showDoneButton && showOverflowMenu && (
									<Grid item xs="auto">
										<Button
											variant="outlined"
											startIcon={<Check />}
											onClick={() => actions?.[0]?.action?.()}
											sx={{
												background: 'white',
												verticalAlign: 'center',
												'&:hover': {
													background: 'white',
												},
											}}
										>
											Done
										</Button>
									</Grid>
								)}
								{actions && (actions?.length ?? 0) > 0 && showOverflowMenu && (
									<Grid item xs="auto">
										<OverflowMenu
											id={'header-menu'}
											iconButtonProps={{
												size: 'small',
												sx: {
													'&.MuiIconButton-root': {
														background: 'rgba(0,0,0,.1)',
													},
												},
											}}
											iconProps={{
												sx: {
													color: 'white',
												},
											}}
										>
											{actions
												.filter((x) => x.color !== 'error')
												.map((x) => (
													<MenuItem
														key={x.label}
														data-testid={x.testId}
														{...(x.stonlyId
															? stonlyData({ id: x.stonlyId })
															: {})}
														color={x.color ?? 'primary'}
														onClick={x.action}
													>
														{x.label}
													</MenuItem>
												))}
											{actions.some((x) => x.color === 'error') &&
												actions.length > 1 && <Divider />}
											{actions.filter((x) => x.color === 'error').length > 0 &&
												actions
													.filter((x) => x.color === 'error')
													.map((x) => (
														<MenuItem
															key={x.label}
															data-testid={x.testId}
															{...(x.stonlyId
																? stonlyData({ id: x.stonlyId })
																: {})}
															color={x.color ?? 'primary'}
															onClick={x.action}
														>
															{x.startIcon && (
																<ListItemIcon
																	sx={{ color: theme.palette.error.main }}
																>
																	{x.startIcon}
																</ListItemIcon>
															)}
															<ListItemText
																sx={{
																	'& .MuiTypography-root': {
																		color: theme.palette.error.main,
																	},
																}}
															>
																{x.label}
															</ListItemText>
														</MenuItem>
													))}
										</OverflowMenu>
									</Grid>
								)}
							</Grid>
						)}
					</Grid>
				</Grid>
				{shouldIncludeTagsComponent &&
					entityObjectTags &&
					addTag &&
					removeTag && (
						<TagsHeaderComponent
							isEditMode={isEditMode}
							isAuthor={isAuthor}
							entityObjectTags={entityObjectTags}
							addTag={addTag}
							removeTag={removeTag}
						/>
					)}
				{aspects && (
					<Box
						sx={{
							background: 'rgba(0,0,0,.1)',
							paddingLeft: '2em',
						}}
					>
						<Tabs
							value={currentTab}
							sx={{
								minHeight: 40,
								'& .MuiTabs-indicator': {
									display: 'flex',
									justifyContent: 'center',
									backgroundColor: 'white',
								},
							}}
						>
							{aspects.map((item) => (
								<Tab
									key={item.label + item.link}
									data-testid={`${item.label.toLowerCase()}-aspect-tab`}
									{...stonlyData({
										id: `${item.label.toLowerCase()}-aspect-tab`,
									})}
									component={Link}
									to={item.link}
									label={item.label}
									value={item.link.split('/')[4]}
									sx={{
										textTransform: 'none',
										minWidth: 72,
										minHeight: 40,
										fontWeight: theme.typography.fontWeightRegular,
										opacity: 0.7,
										color: 'white !important',
										'& .MuiTab-root, &.Mui-selected': {
											opacity: 1,
										},
									}}
								/>
							))}
							{addAspectAction}
						</Tabs>
					</Box>
				)}
			</AppBar>
		);
	},
);
